<script setup>

import OccupancyChart from "@/components/charts/OccupancyChart.vue";

import {useStore} from "vuex";
import {computed, onMounted} from "vue";
import {errorNotify} from "@/mixins";
import {useLoading} from "vue-loading-overlay";
import TableCard from "@/components/panels/TableCard.vue";

const $loading = useLoading({})
const store = useStore()
const occupancyStatistic = computed(() => store.getters['statistic/occupancyStatistic'])
const lockersStatistic = computed(() => store.getters['statistic/lockersStatistic'])

const getOccupancyStatisticError = computed(() => store.getters['statistic/occupancyStatisticError'])
const getLockersStatisticError = computed(() => store.getters['statistic/lockersStatisticError'])


const getOccupancyStatistic = (val) => store.dispatch('statistic/getOccupancyStatistic', val)
const getLockersStatistic = (val) => store.dispatch('statistic/getLockersStatistic', val)


function prepareLockersStatistic(instance) {
  return {
    'Total lockers': instance.lockers,
    'Online': instance.online_lockers,
    'Offline': instance.offline_lockers,
    'Maintenance': instance.maintenance_lockers,
    'Error': instance.error_lockers,
  }
}

function loadStatistic() {
  getOccupancyStatistic()
    .then(() => {if (getOccupancyStatisticError.value) {errorNotify(getOccupancyStatisticError.value)}})
  getLockersStatistic()
    .then(() => {if (getLockersStatisticError.value) {errorNotify(getLockersStatisticError.value)}})
}

onMounted(() => {
  const loader = $loading.show({})
  loadStatistic()
  loader.hide()
})

</script>

<template>
  <div class="dashboard-page pt-5">
    <h3>Dashboard page</h3>
    <div class="row">
      <div class="col-3">
        <TableCard v-if="lockersStatistic"
            :item="prepareLockersStatistic(lockersStatistic)"
            :icon-folder="'main_menu'"
            :icon-name="'devices_icon'"
            :name="'Your lockers'"/>
      </div>
      <div class="col-9">
        <OccupancyChart v-if="occupancyStatistic" :data="occupancyStatistic" :title="'Network 30 day occupancy'"/>
      </div>
    </div>
  </div>
</template>


<style scoped>
  .row {
    min-height: 300px;
  }
</style>