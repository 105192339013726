<template>
  <vue-final-modal
    v-slot="{ params }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content create-update-modal"
  >

    <div class="modal__title pb-3">
      <slot name="title"></slot>
    </div>

    <div class="modal__content">
      <slot :params="params"></slot>
    </div>

    <div class="modal__action pt-3">
      <div class="row">
        <div class="col">
          <button class="btn w-100" @click="$emit('cancel')">Cancel</button>
        </div>
        <div class="col">
          <button class="button w-100" @click="$emit('confirm')">Save</button>
        </div>
      </div>
    </div>

  </vue-final-modal>
</template>

<script>
export default {
  name: "MainModal",
  inheritAttrs: false,
  emits: ['confirm', 'cancel']
}
</script>
