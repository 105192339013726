<script setup>
import {useStore} from "vuex";
import {computed, defineEmits, defineProps, ref} from "vue";
import {errorNotify, successNotify} from "@/mixins";
import TableCard from "@/components/panels/TableCard.vue";
import ControlUnitCreateUpdateModal from "@/components/modals/device/ControlUnitCreateUpdateModal.vue";

const emit = defineEmits(['update'])
defineProps({controlUnitsList: Object})

const store = useStore()
const showModal = ref(false)
const instanceDetail = computed(() => store.getters['control_unit/controlUnitDetail'])
const deleteError = computed(() => store.getters['control_unit/deleteControlUnitError'])
const getError = computed(() => store.getters['control_unit/getControlUnitError'])
const deleteInstance = (val) => store.dispatch('control_unit/deleteControlUnit', val)
const getInstance = (val) => store.dispatch('control_unit/getControlUnit', val)

function openModal(id) {
  getInstance(id)
      .then(() => {if (getError.value) {errorNotify(getError.value)}})
      .finally(() => { showModal.value = true })
}

function closeModal() {
  showModal.value = false
  emit('update')
}

function prepareItem(item) {
  return {
    'Size': item.size,
    'Screen': item.screen,
    'QR-reader': item.nft_reader,
    'NFC-reader': item.qr_reader,
    'Created at': item.created_at,
  }
}

function del(id) {
  if(confirm("Do you really want to delete this control unit?")) {
    deleteInstance(id)
      .then(() => {
        if (!deleteError.value) { successNotify("Control unit deleted successfully!")}
        else {errorNotify(deleteError.value)}
      })
      .finally(() => { emit('update'); })
  }
}
</script>


<template>
  <ControlUnitCreateUpdateModal v-if="showModal" v-model="showModal" @close="closeModal()" :instance="instanceDetail"/>

  <div class="filters-bar row py-3">
    <div class="col-2"><h4>Control units list</h4></div>
  </div>

  <div class="row">
    <div class="col-4" v-for="item in controlUnitsList.results" :key="item.sn">
      <TableCard @open-modal="openModal(item.sn)" @del="del(item.sn)" :item=prepareItem(item) :name="item.sn"/>
    </div>

  </div>

</template>

<style scoped>
  .row {
    --bs-gutter-x: 0;
  }
</style>
