<script setup>
import {useStore, } from "vuex";
import {computed, onMounted} from "vue";
import MainMenu from "@/components/layouts/MainMenu.vue";
import {useLoading} from "vue-loading-overlay";

const $loading = useLoading({});
const store = useStore()
const currentUserData = computed(() => store.getters['user/currentUserData'])

const getCurrentUser = (val) => store.dispatch('user/getCurrentUser', val)

onMounted(() => {
  const loader = $loading.show({})
  getCurrentUser()
      .finally(loader.hide)
})
</script>

<template>
  <div class="main-wrapper">
    <div class="login-page" v-if="$route.path === '/login'">
      <router-view></router-view>
    </div>
    <div class="h-100 d-flex" v-else>
      <MainMenu
          :user-role="currentUserData.role"
          :user-name="currentUserData.first_name"
          :user-email="currentUserData.email"/>
      <div class="main-content h-100 w-100">
        <router-view></router-view>
      </div>
    </div>
  </div>

</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>