<script setup>
import {useStore} from "vuex";
import {useLoading} from 'vue-loading-overlay'
import {computed, onMounted, ref} from "vue";
import PagePaginator from "@/components/layouts/PagePaginator.vue";
import Multiselect from "vue-multiselect";
import AppCustomersTable from "@/components/tables/account/AppCustomersTable.vue";
import AppCustomerInfoPanel from "@/components/panels/AppCustomerInfoPanel.vue";

const store = useStore()
const appCustomerList = computed(() => store.getters['app_customer/appCustomerList'])
const appCustomerStatistic = computed(() => store.getters['statistic/appCustomerStatistic'])
const organizationList = computed(() => store.getters['organization/organizationList'])
const lockerList = computed(() => store.getters['locker/lockerList'])
const getAppCustomersList = (val) => store.dispatch('app_customer/getAppCustomerList', val)
const getAppCustomersStatistic = (val) => store.dispatch('statistic/getAppCustomerStatistic', val)
const getOrganizationList = (val) => store.dispatch('organization/getOrganizationList', val)
const getLockerList = (val) => store.dispatch('locker/getLockerList', val)

const fullFilter = ref('')
const organization = ref(null)
const device = ref(null)
const customerSn = ref(null)
const $loading = useLoading({});
function getFilteredAppCustomerList() {
  fullFilter.value = `?page=${page.value}`
  if (organization.value) {
    fullFilter.value += `&organization=${organization.value.id}`
  }
  if (device.value) {
    fullFilter.value += `&device=${device.value.sn}`
  }
  if (customerSn.value) {
    fullFilter.value = `?sn=${customerSn.value}`
    organization.value = null
    device.value = null
  }
  const loader = $loading.show({});
  getAppCustomersStatistic()
  getAppCustomersList(fullFilter.value)
    .finally(() => {loader.hide()});
  }

function orgFilter() {
  if (organization.value) {
    getLockerList(`?organization=${organization.value.id}`)
    device.value = null
  }
  getFilteredAppCustomerList()
}

const page = ref(1)
function clickCallback(pageNum) {
  page.value = pageNum;
  getFilteredAppCustomerList()
}
onMounted(() => {
  getOrganizationList('')
  getLockerList('')
  getFilteredAppCustomerList()
})
</script>

<template>


  <section class="cells-page">
    <div class="row pb-3">
      <div class="col"><h1>App Customers</h1></div>
      <div class="col text-end">
        <button type="button" class="btn btn-primary">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8337 6.83166H6.83366V11.8317H5.16699V6.83166H0.166992V5.16499H5.16699V0.164993H6.83366V5.16499H11.8337V6.83166Z" fill="white"/>
          </svg>
          Add customer
        </button>
      </div>
    </div>

    <AppCustomerInfoPanel :data="appCustomerStatistic"/>

    <div class="row pt-5">
      <div class="col-6">
        <div class="row">
          <div class="col">
            <h4>Mobile app customers list</h4>
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Search by phone or uid"
                   v-model="customerSn"  @input="getFilteredAppCustomerList()">
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="row">
          <div class="col-6">
            <div class="pb-3 form-floating select-wrapper" v-if="organizationList">
              <multiselect
                  placeholder="Organization"
                  v-model="organization"
                  :options="organizationList.results"
                  label="name"
                  @close="orgFilter()"
              >
              </multiselect>
          </div>
          </div>
          <div class="col-6">
            <div class="pb-3 form-floating select-wrapper" v-if="lockerList">
              <multiselect
                  placeholder="Device"
                  v-model="device"
                  :options="lockerList.results"
                  label="name"
                  @close="getFilteredAppCustomerList()"
              >
              </multiselect>
          </div>
          </div>
        </div>

      </div>
    </div>

    <AppCustomersTable @update="getFilteredAppCustomerList" :app-customer-list="appCustomerList" />
    <PagePaginator @change="clickCallback" :page-count="appCustomerList.total_pages" />
  </section>
</template>
