import organization from '@/api/organization';

import {
    GET_ORGANIZATION_LIST_BEGIN,
    GET_ORGANIZATION_LIST_SUCCESS,
    GET_ORGANIZATION_LIST_FAILURE,
    GET_ORGANIZATION_BEGIN,
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_FAILURE,
    UPDATE_ORGANIZATION_DATA_BEGIN,
    UPDATE_ORGANIZATION_DATA_SUCCESS,
    UPDATE_ORGANIZATION_DATA_FAILURE,
    CREATE_ORGANIZATION_BEGIN,
    CREATE_ORGANIZATION_SUCCESS,
    CREATE_ORGANIZATION_FAILURE,
    DELETE_ORGANIZATION_BEGIN,
    DELETE_ORGANIZATION_SUCCESS,
    DELETE_ORGANIZATION_FAILURE,
} from './types';

const initialState = {
    listOrganizationError: false,
    getOrganizationError: false,
    createOrganizationError: false,
    updateOrganizationError: false,
    deleteOrganizationError: false,
    organizationDetail: false,
    organizationList: false,
};

const getters = {
    organizationList:(state) => state.organizationList,
    listOrganizationError:(state) => state.listOrganizationError,
    getOrganizationError:(state) => state.getOrganizationError,
    createOrganizationError:(state) => state.createOrganizationError,
    updateOrganizationError:(state) => state.updateOrganizationError,
    deleteOrganizationError:(state) => state.deleteOrganizationError,
    organizationDetail:(state) => state.organizationDetail,
};

const actions = {
    getOrganization({ commit }, id) {
        commit(GET_ORGANIZATION_BEGIN);
        return organization.getOrganization(id)
            .then((response) => commit(GET_ORGANIZATION_SUCCESS, response.data))
            .catch((error) => commit(GET_ORGANIZATION_FAILURE, error.response.data));
    },
    getOrganizationList({ commit }, filter) {
        commit(GET_ORGANIZATION_LIST_BEGIN);
        return organization.getOrganizationList(filter)
            .then((response) => commit(GET_ORGANIZATION_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_ORGANIZATION_LIST_FAILURE, error.response.data));
    },
    createOrganization({ commit }, data) {
        commit(CREATE_ORGANIZATION_BEGIN);
        return organization.createOrganization(data)
            .then(() => commit(CREATE_ORGANIZATION_SUCCESS))
            .catch((error) => commit(CREATE_ORGANIZATION_FAILURE, error.response.data));
    },
    updateOrganization({ commit }, { id, data }) {
        commit(UPDATE_ORGANIZATION_DATA_BEGIN);
        return organization.updateOrganization(id, data)
            .then(() => commit(UPDATE_ORGANIZATION_DATA_SUCCESS))
            .catch((error) => commit(UPDATE_ORGANIZATION_DATA_FAILURE, error.response.data));
    },
    deleteOrganization({ commit }, id) {
        commit(DELETE_ORGANIZATION_BEGIN);
        return organization.deleteOrganization(id)
            .then(() => commit(DELETE_ORGANIZATION_SUCCESS))
            .catch((error) => commit(DELETE_ORGANIZATION_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_ORGANIZATION_LIST_BEGIN](state) {
        state.listOrganizationError = false;
    },
    [GET_ORGANIZATION_LIST_SUCCESS](state, data) {
        state.organizationList = data;
        state.listOrganizationError = false;
    },
    [GET_ORGANIZATION_LIST_FAILURE](state, error) {
        state.listOrganizationError = error;
    },
    [GET_ORGANIZATION_BEGIN](state) {
        state.getOrganizationError = false;
    },
    [GET_ORGANIZATION_SUCCESS](state, data) {
        state.organizationDetail = data;
        state.getOrganizationError = false;
    },
    [GET_ORGANIZATION_FAILURE](state, error) {
        state.getOrganizationError = error;
    },
    [CREATE_ORGANIZATION_BEGIN](state) {
        state.createOrganizationError = false;
    },
    [CREATE_ORGANIZATION_SUCCESS](state) {
        state.createOrganizationError = false;
    },
    [CREATE_ORGANIZATION_FAILURE](state, error) {
        state.createOrganizationError = error;
    },
    [UPDATE_ORGANIZATION_DATA_BEGIN](state) {
        state.updateOrganizationError = false;
    },
    [UPDATE_ORGANIZATION_DATA_SUCCESS](state) {
        state.updateOrganizationError = false;
    },
    [UPDATE_ORGANIZATION_DATA_FAILURE](state, error) {
        state.updateOrganizationError = error;
    },
    [DELETE_ORGANIZATION_BEGIN](state) {
        state.deleteOrganizationError = false;
    },
    [DELETE_ORGANIZATION_SUCCESS](state) {
        state.deleteOrganizationError = false;
    },
    [DELETE_ORGANIZATION_FAILURE](state, error) {
        state.deleteOrganizationError = error;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
