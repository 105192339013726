import account from '@/api/prepaid_account';

import {
    GET_PREPAID_ACCOUNT_BEGIN,
    GET_PREPAID_ACCOUNT_FAILURE,
    GET_PREPAID_ACCOUNT_SUCCESS,
    GET_PREPAID_ACCOUNT_LIST_BEGIN,
    GET_PREPAID_ACCOUNT_LIST_FAILURE,
    GET_PREPAID_ACCOUNT_LIST_SUCCESS,
    UPDATE_PREPAID_ACCOUNT_BEGIN,
    UPDATE_PREPAID_ACCOUNT_SUCCESS,
    UPDATE_PREPAID_ACCOUNT_FAILURE,
    DELETE_PREPAID_ACCOUNT_BEGIN,
    DELETE_PREPAID_ACCOUNT_FAILURE,
    DELETE_PREPAID_ACCOUNT_SUCCESS,
    CREATE_PREPAID_ACCOUNT_BEGIN,
    CREATE_PREPAID_ACCOUNT_SUCCESS,
    CREATE_PREPAID_ACCOUNT_FAILURE,
    CREATE_PREPAID_ACCOUNT_CODES_BEGIN,
    CREATE_PREPAID_ACCOUNT_CODES_SUCCESS,
    CREATE_PREPAID_ACCOUNT_CODES_FAILURE
} from './types';


const initialState = {
    prepaidAccountList: false,
    prepaidAccountListError: false,
    prepaidAccountData: false,
    prepaidAccountDataError: false,
    prepaidAccountCreateError: false,
    prepaidAccountCodesCreateError: false,
    prepaidAccountUpdateError: false,
    prepaidAccountDeleteError: false,
};

const getters = {
    prepaidAccountList:(state) => state.prepaidAccountList,
    prepaidAccountData:(state) => state.prepaidAccountData,
    prepaidAccountListError:(state) => state.prepaidAccountListError,
    prepaidAccountDataError:(state) => state.prepaidAccountDataError,
    prepaidAccountCreateError:(state) => state.prepaidAccountCreateError,
    prepaidAccountCodesCreateError:(state) => state.prepaidAccountCodesCreateError,
    prepaidAccountUpdateError:(state) => state.prepaidAccountUpdateError,
    prepaidAccountDeleteError:(state) => state.prepaidAccountDeleteError,
};

const actions = {
    getPrepaidAccount({ commit }, prepaidAccountId) {
        commit(GET_PREPAID_ACCOUNT_BEGIN);
        return account.getPrepaidAccount(prepaidAccountId)
            .then((response) => commit(GET_PREPAID_ACCOUNT_SUCCESS, response.data))
            .catch((error) => commit(GET_PREPAID_ACCOUNT_FAILURE, error.response.data));
    },
    getPrepaidAccountList({ commit }, filter) {
        commit(GET_PREPAID_ACCOUNT_LIST_BEGIN);
        return account.getPrepaidAccountList(filter)
            .then((response) => commit(GET_PREPAID_ACCOUNT_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_PREPAID_ACCOUNT_LIST_FAILURE, error.response.data));
    },
    createPrepaidAccount({ commit }, data) {
        commit(CREATE_PREPAID_ACCOUNT_BEGIN);
        return account.createPrepaidAccount(data)
            .then(() => commit(CREATE_PREPAID_ACCOUNT_SUCCESS))
            .catch((error) => commit(CREATE_PREPAID_ACCOUNT_FAILURE, error.response.data));
    },
    createPrepaidAccountCodes({ commit }, data) {
        commit(CREATE_PREPAID_ACCOUNT_CODES_BEGIN);
        return account.createPrepaidAccountCodes(data)
            .then(() => commit(CREATE_PREPAID_ACCOUNT_CODES_SUCCESS))
            .catch((error) => commit(CREATE_PREPAID_ACCOUNT_CODES_FAILURE, error.response.data));
    },
    updatePrepaidAccount({ commit }, { id, data }) {
        commit(UPDATE_PREPAID_ACCOUNT_BEGIN);
        return account.updatePrepaidAccount(id, data)
            .then(() => commit(UPDATE_PREPAID_ACCOUNT_SUCCESS))
            .catch((error) => commit(UPDATE_PREPAID_ACCOUNT_FAILURE, error.response.data));
    },
    deletePrepaidAccount({ commit }, logId) {
        commit(DELETE_PREPAID_ACCOUNT_BEGIN);
        return account.deletePrepaidAccount(logId)
            .then(() => commit(DELETE_PREPAID_ACCOUNT_SUCCESS))
            .catch((error) => commit(DELETE_PREPAID_ACCOUNT_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_PREPAID_ACCOUNT_BEGIN](state) {
        state.prepaidAccountDataError = false;
    },
    [GET_PREPAID_ACCOUNT_FAILURE](state, error) {
        state.prepaidAccountData = false;
        state.prepaidAccountDataError = error;
    },
    [GET_PREPAID_ACCOUNT_SUCCESS](state, data) {
        state.prepaidAccountData = data;
        state.prepaidAccountDataError = false;
    },
    [GET_PREPAID_ACCOUNT_LIST_BEGIN](state) {
        state.prepaidAccountListError = false;
    },
    [GET_PREPAID_ACCOUNT_LIST_FAILURE](state, error) {
        state.prepaidAccountList = false;
        state.prepaidAccountListError = error;
    },
    [GET_PREPAID_ACCOUNT_LIST_SUCCESS](state, data) {
        state.prepaidAccountList = data;
        state.prepaidAccountListError = false;
    },
    [CREATE_PREPAID_ACCOUNT_BEGIN](state) {
        state.prepaidAccountCreateError = false;
    },
    [CREATE_PREPAID_ACCOUNT_SUCCESS](state) {
        state.prepaidAccountCreateError = false;
    },
    [CREATE_PREPAID_ACCOUNT_FAILURE](state, error) {
        state.prepaidAccountCreateError = error;
    },
    [CREATE_PREPAID_ACCOUNT_CODES_BEGIN](state) {
        state.prepaidAccountCodesCreateError = false;
    },
    [CREATE_PREPAID_ACCOUNT_CODES_SUCCESS](state) {
        state.prepaidAccountCodesCreateError = false;
    },
    [CREATE_PREPAID_ACCOUNT_CODES_FAILURE](state, error) {
        state.prepaidAccountCodesCreateError = error;
    },
    [UPDATE_PREPAID_ACCOUNT_BEGIN](state) {
        state.prepaidAccountUpdateError = false;
    },
    [UPDATE_PREPAID_ACCOUNT_FAILURE](state, error) {
        state.prepaidAccountUpdateError = error;
    },
    [UPDATE_PREPAID_ACCOUNT_SUCCESS](state) {
        state.prepaidAccountUpdateError = false;
    },
    [DELETE_PREPAID_ACCOUNT_BEGIN](state) {
        state.prepaidAccountDataError = false;
    },
    [DELETE_PREPAID_ACCOUNT_FAILURE](state, error) {
        state.prepaidAccountDataError = error;
    },
    [DELETE_PREPAID_ACCOUNT_SUCCESS](state) {
        state.prepaidAccountDataError = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
