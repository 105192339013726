import axios from 'axios';

const CSRF_COOKIE_NAME = 'csrftoken';
const CSRF_HEADER_NAME = 'X-CSRFToken';

const session = axios.create({
    xsrfCookieName: CSRF_COOKIE_NAME,
    xsrfHeaderName: CSRF_HEADER_NAME,
});

// Check and set Authorization token
if (localStorage.getItem('token')) {
    session.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
} else {
    session.defaults.headers.common.Authorization = null;
}

// Check token and remove when 401
session.interceptors.response.use((response) => {
    return response;
}, (err) => {
    if (err.response.status === 401 && localStorage.getItem('token')) {
        localStorage.removeItem('token');
    }
    throw err;
});

if (process.env.VUE_APP_API_BASE_URL) {
    session.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
} else if (window.origin.includes('dev')) {
    session.defaults.baseURL = 'https://dev.api-lockerbot.com';
} else {
    session.defaults.baseURL = 'https://api-lockerbot.com';
}

export default session;
