<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted} from "vue"
import { successNotify, errorNotify } from '@/mixins.js'
import MainModal from "@/components/layouts/MainModal";

const store = useStore()
const createInstanceError = computed(() => store.getters['country/createCountryError'])
const updateInstanceError = computed(() => store.getters['country/updateCountryError'])
const createInstance = (val) => store.dispatch('country/createCountry', val)
const updateInstance = (val) => store.dispatch('country/updateCountry', val)

const emit = defineEmits(['update', 'close'])
const props = defineProps({instance: Object})

const instanceData = {
  name: '',
  phone_code: ''
}

function instanceCreate() {
  createInstance(instanceData)
      .then(() => {
        if (!createInstanceError.value) { successNotify("Country added successfully!") }
        else { errorNotify(createInstanceError.value) }
      })
      .finally(() => { emit('close') })
}
function instanceUpdate() {
  let newData = {data: instanceData, id: props.instance.id }
  updateInstance(newData)
      .then(() => {
        if (!updateInstanceError.value) { successNotify("Country updated successfully!") }
        else {errorNotify(updateInstanceError.value)}
      })
      .finally(() => { emit('close') })
}
function confirm() {
  if (props.instance) { instanceUpdate() } else { instanceCreate() }
}
onMounted(() => {
  if (props.instance) {
    for (const [key, value] of Object.entries(props.instance)) {
        instanceData[`${key}`] = value;
      }
  }
})
</script>

<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')" >
    <template v-if="instance" v-slot:title>Edit Country</template>
    <template v-else v-slot:title>Add Country</template>
    <div class="modal__content">
      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="Country name" v-model="instanceData.name">
        <label>Country name</label>
      </div>
      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="Phone code" v-model="instanceData.phone_code">
        <label>Phone code</label>
      </div>
    </div>
  </main-modal>
</template>

