<template>
  <section class="paginator">
    <nav class="txt pt-3" aria-label="navigation">
      <paginate
          :page-count="pageCount"
          :click-handler="pageCallback"
          :prev-text="'Previous'"
          :next-text="'Next'"
          :container-class="'pagination justify-content-end'"
          :page-class="'page-item'"
          :page-link-class="'page-link'"
          :prev-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-class="'page-item'"
          :next-link-class="'page-link'">
      </paginate>
    </nav>
  </section>
</template>

<script>
import Paginate from 'vuejs-paginate-next';

export default {
  name: 'PagePaginator',
  components: { Paginate },
  props: ['pageCount'],
  methods: {
    pageCallback(pageNum) {
      this.$emit('change', pageNum);
    },
  },
};
</script>
