import {
    CREATE_COUNTRY_BEGIN,
    CREATE_COUNTRY_FAILURE,
    CREATE_COUNTRY_SUCCESS,
    DELETE_COUNTRY_BEGIN,
    DELETE_COUNTRY_FAILURE,
    DELETE_COUNTRY_SUCCESS,
    GET_COUNTRY_BEGIN,
    GET_COUNTRY_FAILURE,
    GET_COUNTRY_LIST_BEGIN,
    GET_COUNTRY_LIST_FAILURE,
    GET_COUNTRY_LIST_SUCCESS,
    GET_COUNTRY_SUCCESS,
    UPDATE_COUNTRY_DATA_BEGIN,
    UPDATE_COUNTRY_DATA_FAILURE,
    UPDATE_COUNTRY_DATA_SUCCESS
} from "@/store/types";
import country from "@/api/country";

const initialState = {
    listCountryError: false,
    getCountryError: false,
    createCountryError: false,
    updateCountryError: false,
    deleteCountryError: false,
    countryDetail: false,
    countryList: false,
};

const getters = {
    countryList:(state) => state.countryList,
    countryDetail:(state) => state.countryDetail,
    createCountryError:(state) => state.createCountryError,
    updateCountryError:(state) => state.updateCountryError,
    deleteCountryError:(state) => state.deleteCountryError,
    getCountryError:(state) => state.getCountryError,
};

const actions = {
    getCountry({ commit }, id) {
        commit(GET_COUNTRY_BEGIN);
        return country.getCountry(id)
            .then((response) => commit(GET_COUNTRY_SUCCESS, response.data))
            .catch((error) => commit(GET_COUNTRY_FAILURE, error.response.data));
    },
    getCountryList({ commit }, filter) {
        commit(GET_COUNTRY_LIST_BEGIN);
        return country.getCountryList(filter)
            .then((response) => commit(GET_COUNTRY_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_COUNTRY_LIST_FAILURE, error.response.data));
    },
    createCountry({ commit }, data) {
        commit(CREATE_COUNTRY_BEGIN);
        return country.createCountry(data)
            .then(() => commit(CREATE_COUNTRY_SUCCESS))
            .catch((error) => commit(CREATE_COUNTRY_FAILURE, error.response.data));
    },
    updateCountry({ commit }, { id, data }) {
        commit(UPDATE_COUNTRY_DATA_BEGIN);
        return country.updateCountry(id, data)
            .then(() => commit(UPDATE_COUNTRY_DATA_SUCCESS))
            .catch((error) => commit(UPDATE_COUNTRY_DATA_FAILURE, error.response.data));
    },
    deleteCountry({ commit }, id) {
        commit(DELETE_COUNTRY_BEGIN);
        return country.deleteCountry(id)
            .then(() => commit(DELETE_COUNTRY_SUCCESS))
            .catch((error) => commit(DELETE_COUNTRY_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_COUNTRY_LIST_BEGIN](state) {
        state.listCountryError = false;
    },
    [GET_COUNTRY_LIST_SUCCESS](state, data) {
        state.countryList = data;
        state.listCountryError = false;
    },
    [GET_COUNTRY_LIST_FAILURE](state, error) {
        state.listCountryError = error;
    },
    [GET_COUNTRY_BEGIN](state) {
        state.getCountryError = false;
    },
    [GET_COUNTRY_SUCCESS](state, data) {
        state.countryDetail = data;
        state.getCountryError = false;
    },
    [GET_COUNTRY_FAILURE](state, error) {
        state.getCountryError = error;
    },
    [CREATE_COUNTRY_BEGIN](state) {
        state.createCountryError = false;
    },
    [CREATE_COUNTRY_SUCCESS](state) {
        state.createCountryError = false;
    },
    [CREATE_COUNTRY_FAILURE](state, error) {
        state.createCountryError = error;
    },
    [UPDATE_COUNTRY_DATA_BEGIN](state) {
        state.updateCountryError = false;
    },
    [UPDATE_COUNTRY_DATA_SUCCESS](state) {
        state.updateCountryError = false;
    },
    [UPDATE_COUNTRY_DATA_FAILURE](state, error) {
        state.updateCountryError = error;
    },
    [DELETE_COUNTRY_BEGIN](state) {
        state.deleteCountryError = false;
    },
    [DELETE_COUNTRY_SUCCESS](state) {
        state.deleteCountryError = false;
    },
    [DELETE_COUNTRY_FAILURE](state, error) {
        state.deleteCountryError = error;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};