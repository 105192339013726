<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted, ref} from "vue"
import { successNotify, errorNotify } from '@/mixins.js'
import MainModal from "@/components/layouts/MainModal";
import Multiselect from "vue-multiselect";

const store = useStore()
const createInstanceError = computed(() => store.getters['control_unit/createControlUnitError'])
const updateInstanceError = computed(() => store.getters['control_unit/updateControlUnitError'])
const cellSizeList = computed(() => store.getters['cell_size/cellSizeList'])
const createInstance = (val) => store.dispatch('control_unit/createControlUnit', val)
const updateInstance = (val) => store.dispatch('control_unit/updateControlUnit', val)
const getCellSizesList = (val) => store.dispatch('cell_size/getCellSizeList', val)

const emit = defineEmits(['update', 'close'])
const props = defineProps({instance: Object})

const displaySizes = ['640x480', '1280x720', '1920x1080', '2560x1440']
const instanceData = ref({
  sn: '',
  size: '',
  screen: '',
  qr_reader: false,
  nft_reader: false
})

function instanceCreate() {
  instanceData.value.size = instanceData.value.size.size
  createInstance(instanceData.value)
      .then(() => {
        if (!createInstanceError.value) { successNotify("Control unit added successfully!") }
        else { errorNotify(createInstanceError.value) }
      })
      .finally(() => { emit('close') })
}
function instanceUpdate() {
  let newData = {data: instanceData.value, id: props.instance.sn}
  updateInstance(newData)
      .then(() => {
        if (!updateInstanceError.value) { successNotify("Control unit updated successfully!") }
        else {errorNotify(updateInstanceError.value)}
      })
      .finally(() => { emit('close') })
}
function confirm() {
  if (props.instance) { instanceUpdate() } else { instanceCreate() }
}
onMounted(() => {
  getCellSizesList('')
  if (props.instance) {
    for (const [key, value] of Object.entries(props.instance)) {
        instanceData.value[`${key}`] = value;
      }
  }
})
</script>

<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')" >
    <template v-if="instance" v-slot:title>Edit Control unit</template>
    <template v-else v-slot:title>Add Control unit</template>
    <div class="modal__content">

<!--  info-->
      <div class="pb-3 form-floating">
        <input :disabled="instance" class="form-control" type="text" placeholder="Name" v-model="instanceData.sn">
        <label>Serial number</label>
      </div>

      <div class="row">
        <div class="col">
          <div class="pb-3 form-floating select-wrapper" v-if="cellSizeList">
            <multiselect
                selectLabel=""
                deselectLabel=""
                placeholder="Change unit size"
                v-model="instanceData.size"
                :options="cellSizeList.results"
                label="size"
            >
            </multiselect>
          </div>
        </div>
        <div class="col">
          <div class="pb-3 form-floating select-wrapper">
            <multiselect
                selectLabel=""
                deselectLabel=""
                placeholder="Change display size"
                v-model="instanceData.screen"
                :options="displaySizes"
            >
            </multiselect>
          </div>
        </div>
      </div>

<!--    Presence of readers-->
    <div class="border-top border-bottom py-3 my-3">
      <div class="fw-bold pb-3">Presence of readers</div>
      <div class="row">
        <div class="col-6">
          <input v-model="instanceData.nft_reader" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            <img :src="require(`@/assets/icon/nfc_reader.png`)" alt="">
            NFC reader
          </label>
        </div>
        <div class="col-6">
          <input v-model="instanceData.qr_reader" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            <img :src="require(`@/assets/icon/qr_reader.png`)" alt="">
            QR code reader
          </label>
        </div>
        </div>
      </div>
    </div>


  </main-modal>
</template>

<style scoped>
  img {
    height: 24px;
    padding: 0 8px 0 8px;
  }
  button, button:hover {
    color: #004BE0;
    background: #DBE7FF;
  }
</style>
