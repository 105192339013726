<template>
  <notifications position="bottom right"/>
  <MainWrapper/>
</template>

<script>
import MainWrapper from "@/components/MainWrapper";

export default {
  name: 'App',
  components: {
    MainWrapper
  }
}
</script>

