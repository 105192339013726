<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted, ref} from "vue"
import { successNotify, errorNotify } from '@/mixins.js'
import MainModal from "@/components/layouts/MainModal"
import Multiselect from "vue-multiselect"

const store = useStore()
const createInstanceError = computed(() => store.getters['organization/createOrganizationError'])
const updateInstanceError = computed(() => store.getters['organization/updateOrganizationError'])
const cityList = computed(() => store.getters['city/cityList'])
const addressList = computed(() => store.getters['address/addressList'])
const createInstance = (val) => store.dispatch('organization/createOrganization', val)
const updateInstance = (val) => store.dispatch('organization/updateOrganization', val)
const getCityList = (val) => store.dispatch('city/getCityList', val)
const getAddressList = (val) => store.dispatch('address/getAddressList', val)

const emit = defineEmits(['update', 'close'])
const props = defineProps({instance: Object})

const city = ref('')
const address = ref('')
const instanceData = {
  name: '',
  email: '',
}

function instanceCreate() {
  instanceData.address = address.value.id
  createInstance(instanceData)
      .then(() => {
        if (!createInstanceError.value) { successNotify("Country added successfully!") }
        else { errorNotify(createInstanceError.value) }
      })
      .finally(() => { emit('close') })
}
function instanceUpdate() {
  let newData = {data: instanceData, id: props.instance.id }
  instanceData.address = address.value.id
  updateInstance(newData)
      .then(() => {
        if (!updateInstanceError.value) { successNotify("Country updated successfully!") }
        else {errorNotify(updateInstanceError.value)}
      })
      .finally(() => { emit('close') })
}

function updateAddressList() {
  getAddressList(`?city=${city.value.id}`)
}

function confirm() {
  if (props.instance) { instanceUpdate() } else { instanceCreate() }
}
onMounted(() => {
  getCityList('')
  getAddressList('')
  if (props.instance) {
    city.value = props.instance.address.city
    address.value = props.instance.address
    for (const [key, value] of Object.entries(props.instance)) {
        instanceData[`${key}`] = value;
      }
  }
})
</script>

<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')" >
    <template v-if="instance" v-slot:title>Edit organization</template>
    <template v-else v-slot:title>Add organization</template>
    <div class="modal__content">

      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="Organization name" v-model="instanceData.name">
        <label>Organization name</label>
      </div>
      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="Email" v-model="instanceData.email">
        <label>Email</label>
      </div>
      <div class="pb-3 form-floating select-wrapper" v-if="cityList">
        <multiselect
            selectLabel=""
            deselectLabel=""
            placeholder="Change the city"
            v-model="city"
            :options="cityList.results"
            label="name"
            @close="updateAddressList()"
        >
        </multiselect>
      </div>

      <div class="pb-3 form-floating select-wrapper" v-if="addressList">
        <multiselect
            :disabled="!city"
            selectLabel=""
            deselectLabel=""
            placeholder="Change the address"
            v-model="address"
            :options="addressList.results"
            label="address"
        >
        </multiselect>
      </div>
    </div>
  </main-modal>
</template>