<script setup>
import {useStore} from "vuex";
import {useLoading} from 'vue-loading-overlay'
import {computed, onMounted, ref} from "vue";
import OrganizationsTable from "@/components/tables/OrganizationsTable.vue";
import OrganizationCreateUpdateModal from "@/components/modals/OrganizationCreateUpdateModal.vue";

const fullFilter = ref('')
const showModal = ref(false)

const store = useStore()
const organizationList = computed(() => store.getters['organization/organizationList'])
const getOrganizationList = (val) => store.dispatch('organization/getOrganizationList', val)

const $loading = useLoading({});
function getFilteredOrganizationList() {
  const loader = $loading.show({});
  getOrganizationList(fullFilter.value)
    .finally(() => {loader.hide()});
  }
function closeModal() {
  showModal.value = false
  getFilteredOrganizationList()
}

onMounted(() => {
  getFilteredOrganizationList()
})
</script>


<template>
  <OrganizationCreateUpdateModal @close="closeModal" v-model="showModal" v-if="showModal" />
  <section class="organizations-page">
    <div class="row pb-3">
      <div class="col"><h1>Organizations</h1></div>
      <div class="col text-end">
        <button @click="showModal=true" type="button" class="btn btn-primary">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8337 6.83166H6.83366V11.8317H5.16699V6.83166H0.166992V5.16499H5.16699V0.164993H6.83366V5.16499H11.8337V6.83166Z" fill="white"/>
          </svg>
          Add organization
        </button>
      </div>
    </div>
    <OrganizationsTable :organization-list="organizationList" @update="getFilteredOrganizationList"/>
  </section>
</template>
