import statistic from '@/api/statistic';

import {
    GET_LOCATIONS_STATISTIC_BEGIN,
    GET_LOCATIONS_STATISTIC_SUCCESS,
    GET_LOCATIONS_STATISTIC_FAILURE,
    GET_EMPLOYEES_STATISTIC_BEGIN,
    GET_EMPLOYEES_STATISTIC_SUCCESS,
    GET_EMPLOYEES_STATISTIC_FAILURE,
    GET_PREPAID_ACCOUNTS_STATISTIC_BEGIN,
    GET_PREPAID_ACCOUNTS_STATISTIC_SUCCESS,
    GET_PREPAID_ACCOUNTS_STATISTIC_FAILURE,
    GET_LOCKERS_STATISTIC_BEGIN,
    GET_LOCKERS_STATISTIC_SUCCESS,
    GET_LOCKERS_STATISTIC_FAILURE,
    GET_STORAGE_PARCELS_STATISTIC_BEGIN,
    GET_STORAGE_PARCELS_STATISTIC_SUCCESS,
    GET_STORAGE_PARCELS_STATISTIC_FAILURE,
    GET_EXPECTED_PARCELS_STATISTIC_BEGIN,
    GET_EXPECTED_PARCELS_STATISTIC_SUCCESS,
    GET_EXPECTED_PARCELS_STATISTIC_FAILURE,
    GET_APP_CUSTOMER_STATISTIC_BEGIN,
    GET_APP_CUSTOMER_STATISTIC_SUCCESS,
    GET_APP_CUSTOMER_STATISTIC_FAILURE,
    GET_OCCUPANCY_STATISTIC_BEGIN,
    GET_OCCUPANCY_STATISTIC_SUCCESS,
    GET_OCCUPANCY_STATISTIC_FAILURE
} from './types';

const initialState = {
    locationsStatisticError: false,
    employeesStatisticError: false,
    prepaidAccountStatisticError: false,
    appCustomerStatisticError: false,
    lockersStatisticError: false,
    storageParcelsStatisticError: false,
    expectedParcelsStatisticError: false,
    occupancyStatisticError: false,
    locationsStatistic: false,
    employeesStatistic: false,
    prepaidAccountStatistic: false,
    appCustomerStatistic: false,
    lockersStatistic: false,
    storageParcelsStatistic: false,
    expectedParcelsStatistic: false,
    occupancyStatistic: false,

};

const getters = {
    locationsStatistic:(state) => state.locationsStatistic,
    locationsStatisticError:(state) => state.locationsStatisticError,
    employeesStatistic:(state) => state.employeesStatistic,
    employeesStatisticError:(state) => state.employeesStatisticError,
    prepaidAccountStatistic:(state) => state.prepaidAccountStatistic,
    prepaidAccountStatisticError:(state) => state.prepaidAccountStatisticError,
    appCustomerStatistic:(state) => state.appCustomerStatistic,
    appCustomerStatisticError:(state) => state.appCustomerStatisticError,
    lockersStatistic:(state) => state.lockersStatistic,
    lockersStatisticError:(state) => state.lockersStatisticError,
    storageParcelsStatistic:(state) => state.storageParcelsStatistic,
    storageParcelsStatisticError:(state) => state.storageParcelsStatisticError,
    expectedParcelsStatistic:(state) => state.expectedParcelsStatistic,
    expectedParcelsStatisticError:(state) => state.expectedParcelsStatisticError,
    occupancyStatistic:(state) => state.occupancyStatistic,
    occupancyStatisticError:(state) => state.occupancyStatisticError,
};

const actions = {
    getLocationsStatistic({ commit }) {
        commit(GET_LOCATIONS_STATISTIC_BEGIN);
        return statistic.getLocationsStatistic()
            .then((response) => commit(GET_LOCATIONS_STATISTIC_SUCCESS, response.data))
            .catch((error) => commit(GET_LOCATIONS_STATISTIC_FAILURE, error.response.data));
    },
    getEmployeesStatistic({ commit }) {
        commit(GET_EMPLOYEES_STATISTIC_BEGIN);
        return statistic.getEmployeesStatistic()
            .then((response) => commit(GET_EMPLOYEES_STATISTIC_SUCCESS, response.data))
            .catch((error) => commit(GET_EMPLOYEES_STATISTIC_FAILURE, error.response.data));
    },
    getPrepaidAccountsStatistic({ commit }) {
        commit(GET_PREPAID_ACCOUNTS_STATISTIC_BEGIN);
        return statistic.getPrepaidAccountsStatistic()
            .then((response) => commit(GET_PREPAID_ACCOUNTS_STATISTIC_SUCCESS, response.data))
            .catch((error) => commit(GET_PREPAID_ACCOUNTS_STATISTIC_FAILURE, error.response.data));
    },
    getAppCustomerStatistic({ commit }) {
        commit(GET_APP_CUSTOMER_STATISTIC_BEGIN);
        return statistic.getAppCustomerStatistic()
            .then((response) => commit(GET_APP_CUSTOMER_STATISTIC_SUCCESS, response.data))
            .catch((error) => commit(GET_APP_CUSTOMER_STATISTIC_FAILURE, error.response.data));
    },
    getLockersStatistic({ commit }) {
        commit(GET_LOCKERS_STATISTIC_BEGIN);
        return statistic.getLockersStatistic()
            .then((response) => commit(GET_LOCKERS_STATISTIC_SUCCESS, response.data))
            .catch((error) => commit(GET_LOCKERS_STATISTIC_FAILURE, error.response.data));
    },
    getStorageParcelsStatistic({ commit }) {
        commit(GET_STORAGE_PARCELS_STATISTIC_BEGIN);
        return statistic.getStorageParcelsStatistic()
            .then((response) => commit(GET_STORAGE_PARCELS_STATISTIC_SUCCESS, response.data))
            .catch((error) => commit(GET_STORAGE_PARCELS_STATISTIC_FAILURE, error.response.data));
    },
    getExpectedParcelsStatistic({ commit }) {
        commit(GET_EXPECTED_PARCELS_STATISTIC_BEGIN);
        return statistic.getExpectedParcelsStatistic()
            .then((response) => commit(GET_EXPECTED_PARCELS_STATISTIC_SUCCESS, response.data))
            .catch((error) => commit(GET_EXPECTED_PARCELS_STATISTIC_FAILURE, error.response.data));
    },
    getOccupancyStatistic({ commit }) {
        commit(GET_OCCUPANCY_STATISTIC_BEGIN);
        return statistic.getOccupancyStatistic()
            .then((response) => commit(GET_OCCUPANCY_STATISTIC_SUCCESS, response.data))
            .catch((error) => commit(GET_OCCUPANCY_STATISTIC_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_LOCATIONS_STATISTIC_BEGIN](state) {
        state.locationsStatisticError = false;
    },
    [GET_LOCATIONS_STATISTIC_SUCCESS](state, data) {
        state.locationsStatistic = data;
        state.locationsStatisticError = false;
    },
    [GET_LOCATIONS_STATISTIC_FAILURE](state, error) {
        state.locationsStatisticError = error;
    },
    [GET_EMPLOYEES_STATISTIC_BEGIN](state) {
        state.employeesStatisticError = false;
    },
    [GET_EMPLOYEES_STATISTIC_SUCCESS](state, data) {
        state.employeesStatistic = data;
        state.employeesStatisticError = false;
    },
    [GET_EMPLOYEES_STATISTIC_FAILURE](state, error) {
        state.employeesStatisticError = error;
    },
    [GET_PREPAID_ACCOUNTS_STATISTIC_BEGIN](state) {
        state.prepaidAccountStatisticError = false;
    },
    [GET_PREPAID_ACCOUNTS_STATISTIC_SUCCESS](state, data) {
        state.prepaidAccountStatistic = data;
        state.prepaidAccountStatisticError = false;
    },
    [GET_PREPAID_ACCOUNTS_STATISTIC_FAILURE](state, error) {
        state.prepaidAccountStatisticError = error;
    },
    [GET_APP_CUSTOMER_STATISTIC_BEGIN](state) {
        state.appCustomerStatisticError = false;
    },
    [GET_APP_CUSTOMER_STATISTIC_SUCCESS](state, data) {
        state.appCustomerStatistic = data;
        state.appCustomerStatisticError = false;
    },
    [GET_APP_CUSTOMER_STATISTIC_FAILURE](state, error) {
        state.appCustomerStatisticError = error;
    },
    [GET_LOCKERS_STATISTIC_BEGIN](state) {
        state.lockersStatisticError = false;
    },
    [GET_LOCKERS_STATISTIC_SUCCESS](state, data) {
        state.lockersStatistic = data;
        state.lockersStatisticError = false;
    },
    [GET_LOCKERS_STATISTIC_FAILURE](state, error) {
        state.lockersStatisticError = error;
    },
    [GET_STORAGE_PARCELS_STATISTIC_BEGIN](state) {
        state.storageParcelsStatisticError = false;
    },
    [GET_STORAGE_PARCELS_STATISTIC_SUCCESS](state, data) {
        state.storageParcelsStatistic = data;
        state.storageParcelsStatisticError = false;
    },
    [GET_STORAGE_PARCELS_STATISTIC_FAILURE](state, error) {
        state.storageParcelsStatisticError = error;
    },
    [GET_EXPECTED_PARCELS_STATISTIC_BEGIN](state) {
        state.expectedParcelsStatisticError = false;
    },
    [GET_EXPECTED_PARCELS_STATISTIC_SUCCESS](state, data) {
        state.expectedParcelsStatistic = data;
        state.expectedParcelsStatisticError = false;
    },
    [GET_EXPECTED_PARCELS_STATISTIC_FAILURE](state, error) {
        state.expectedParcelsStatisticError = error;
    },
    [GET_OCCUPANCY_STATISTIC_BEGIN](state) {
        state.occupancyStatisticError = false;
    },
    [GET_OCCUPANCY_STATISTIC_SUCCESS](state, data) {
        state.occupancyStatistic = data;
        state.occupancyStatisticError = false;
    },
    [GET_OCCUPANCY_STATISTIC_FAILURE](state, error) {
        state.occupancyStatisticError = error;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};