import cellSize from '@/api/cell_size';

import {
    GET_CELL_SIZE_BEGIN,
    GET_CELL_SIZE_FAILURE,
    GET_CELL_SIZE_SUCCESS,
    GET_CELL_SIZE_LIST_BEGIN,
    GET_CELL_SIZE_LIST_FAILURE,
    GET_CELL_SIZE_LIST_SUCCESS,
    UPDATE_CELL_SIZE_BEGIN,
    UPDATE_CELL_SIZE_SUCCESS,
    UPDATE_CELL_SIZE_FAILURE,
    DELETE_CELL_SIZE_BEGIN,
    DELETE_CELL_SIZE_FAILURE,
    DELETE_CELL_SIZE_SUCCESS,
    CREATE_CELL_SIZE_BEGIN,
    CREATE_CELL_SIZE_SUCCESS,
    CREATE_CELL_SIZE_FAILURE
} from './types';


const initialState = {
    cellSizeList: false,
    cellSizeListError: false,
    cellSizeData: false,
    cellSizeDataError: false,
    cellSizeCreateError: false,
    cellSizeUpdateError: false,
    cellSizeDeleteError: false,
};

const getters = {
    cellSizeList:(state) => state.cellSizeList,
    cellSizeListError:(state) => state.cellSizeListError,
    cellSizeData:(state) => state.cellSizeData,
    cellSizeDataError:(state) => state.cellSizeDataError,
    cellSizeCreateError:(state) => state.cellSizeCreateError,
    cellSizeUpdateError:(state) => state.cellSizeUpdateError,
    cellSizeDeleteError:(state) => state.cellSizeDeleteError
};

const actions = {
    getCellSize({ commit }, id) {
        commit(GET_CELL_SIZE_BEGIN);
        return cellSize.getCellSize(id)
            .then((response) => commit(GET_CELL_SIZE_SUCCESS, response.data))
            .catch((error) => commit(GET_CELL_SIZE_FAILURE, error.response.data));
    },
    getCellSizeList({ commit }, filter) {
        commit(GET_CELL_SIZE_LIST_BEGIN);
        return cellSize.getCellSizeList(filter)
            .then((response) => commit(GET_CELL_SIZE_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_CELL_SIZE_LIST_FAILURE, error.response.data));
    },
    createCellSize({ commit }, data) {
        commit(CREATE_CELL_SIZE_BEGIN);
        return cellSize.createCellSize(data)
            .then(() => commit(CREATE_CELL_SIZE_SUCCESS))
            .catch((error) => commit(CREATE_CELL_SIZE_FAILURE, error.response.data));
    },
    updateCellSize({ commit }, { id, data }) {
        commit(UPDATE_CELL_SIZE_BEGIN);
        return cellSize.updateCellSize(id, data)
            .then(() => commit(UPDATE_CELL_SIZE_SUCCESS))
            .catch((error) => commit(UPDATE_CELL_SIZE_FAILURE, error.response.data));
    },
    deleteCellSize({ commit }, id) {
        commit(DELETE_CELL_SIZE_BEGIN);
        return cellSize.deleteCellSize(id)
            .then(() => commit(DELETE_CELL_SIZE_SUCCESS))
            .catch((error) => commit(DELETE_CELL_SIZE_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_CELL_SIZE_BEGIN](state) {
        state.cellSizeDataError = false;
    },
    [GET_CELL_SIZE_FAILURE](state, error) {
        state.cellSizeData = false;
        state.cellSizeDataError = error;
    },
    [GET_CELL_SIZE_SUCCESS](state, data) {
        state.cellSizeData = data;
        state.cellSizeDataError = false;
    },
    [GET_CELL_SIZE_LIST_BEGIN](state) {
        state.cellSizeListError = false;
    },
    [GET_CELL_SIZE_LIST_FAILURE](state, error) {
        state.cellSizeList = false;
        state.cellSizeListError = error;
    },
    [GET_CELL_SIZE_LIST_SUCCESS](state, data) {
        state.cellSizeList = data;
        state.cellSizeListError = false;
    },
    [CREATE_CELL_SIZE_BEGIN](state) {
        state.cellSizeCreateError = false;
    },
    [CREATE_CELL_SIZE_SUCCESS](state) {
        state.cellSizeCreateError = false;
    },
    [CREATE_CELL_SIZE_FAILURE](state, error) {
        state.cellSizeCreateError = error;
    },
    [UPDATE_CELL_SIZE_BEGIN](state) {
        state.cellSizeUpdateError = false;
    },
    [UPDATE_CELL_SIZE_FAILURE](state, error) {
        state.cellSizeUpdateError = error;
    },
    [UPDATE_CELL_SIZE_SUCCESS](state) {
        state.cellSizeUpdateError = false;
    },
    [DELETE_CELL_SIZE_BEGIN](state) {
        state.cellSizeDataError = false;
    },
    [DELETE_CELL_SIZE_FAILURE](state, error) {
        state.cellSizeDataError = error;
    },
    [DELETE_CELL_SIZE_SUCCESS](state) {
        state.cellSizeDataError = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
