<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted, ref} from "vue"
import MainModal from "@/components/layouts/MainModal"
import Multiselect from 'vue-multiselect'

const store = useStore()
const cityList = computed(() => store.getters['city/cityList'])
const addressList = computed(() => store.getters['address/addressList'])

const getCityList = (val) => store.dispatch('city/getCityList', val)
const getAddressList = (val) => store.dispatch('address/getAddressList', val)

const emit = defineEmits(['setAddress', 'close'])
const props = defineProps({instance: Object})

const city = ref('')
const instanceData = ref({})

function confirm() {
  emit('setAddress', instanceData.value)
  emit('close')
}

function updateAddressList() {
  getAddressList(`?city=${city.value.id}`)
}

onMounted(() => {
  getCityList('')
  getAddressList('')
  if (props.instance) {instanceData.value = props.instance; city.value = props.instance.city}
})
</script>


<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')">
    <template v-slot:title>Address</template>
    <div class="modal__content">

      <div class="pb-3 form-floating select-wrapper" v-if="cityList">
        <multiselect
            selectLabel=""
            deselectLabel=""
            placeholder="Change the city"
            v-model="city"
            :options="cityList.results"
            label="name"
            @close="updateAddressList()"
        >
        </multiselect>
      </div>

      <div class="pb-3 form-floating select-wrapper" v-if="addressList">
        <multiselect
            :disabled="!city"
            selectLabel=""
            deselectLabel=""
            placeholder="Change the address"
            v-model="instanceData"
            :options="addressList.results"
            label="address"
        >
        </multiselect>
      </div>

    </div>
  </main-modal>
</template>
