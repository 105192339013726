<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import { useRouter } from "vue-router"
import { errorNotify } from "@/mixins";


const store = useStore()
const router = useRouter()
const error = computed(() => store.getters['auth/authError'])
const login = (val) => store.dispatch('auth/login', val)
const getCurrentUser = (val) => store.dispatch('user/getCurrentUser', val)

const loginData = {
  email: '',
  password: ''
}

function doLogin() {
  login(loginData)
      .then(() => { if (error.value) { errorNotify(error.value) } else {getCurrentUser()}})
      .finally(() => router.push({ name: 'dashboard' }))
}
</script>

<template>
  <div class="login-form-wrapper text-center">
    <div class="login-form">
      <img src="@/assets/img/lockerbot_logo.png" alt="lockerbot logo">
      <h3>Login to Admin Dashboard</h3>
      <form class="form" @submit.prevent="doLogin()">
        <div class="input-wrapper">
          <input type="text" placeholder="Email address" required id="email" v-model="loginData.email">
        </div>
        <div class="input-wrapper">
          <input type="password" placeholder="Password" required id="psw" v-model="loginData.password">
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  </div>
</template>
