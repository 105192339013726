<script setup>
import {useStore} from "vuex";
import {useLoading} from 'vue-loading-overlay'
import {computed, onMounted} from "vue";
import {errorNotify, getFullUrl, prettifyDateTimeShort, successNotify} from "@/mixins";
import {useRoute, useRouter} from "vue-router";
import TableCard from "@/components/panels/TableCard.vue";

const route = useRoute()
const router = useRouter()
const store = useStore()
const instance = computed(() => store.getters['parcel/storageParcelData'])
const getInstanceError = computed(() => store.getters['parcel/storageParcelDataError'])
const storageParcelFinishError = computed(() => store.getters['parcel/storageParcelFinishError'])

const getInstance = (val) => store.dispatch('parcel/getStorageParcel', val)
const finishStorageParcel = (val) => store.dispatch('parcel/finishStorageParcel', val)


const $loading = useLoading({})


function prepareUserInfo(instance) {
  let data = {
    'Organization': instance.prepaid_account.organization.name,
    'Prepaid account s/n': instance.prepaid_account.sn}
  if (instance.prepaid_account.prepaid_hours) {data['Prepaid hours'] = instance.prepaid_account.prepaid_hours}
  else {data['Prepaid hours'] = '1 day'}
  if (instance.prepaid_account.expiration_date) {
    data['Expiration'] = prettifyDateTimeShort(instance.prepaid_account.expiration_date)}
  return data
}

function prepareDeviceInfo(instance) {
  return {
    'Organization': instance.organization,
    'Name': instance.device.name,
    'Address': instance.device.address,
    'Status': instance.device.status,
    'Is working time now': instance.device.working_now
  }
}

function prepareParcelInfo(instance) {
  return {
    'Tracking Number': instance.id,
    'Status': instance.status,
    'Created': prettifyDateTimeShort(instance.created_at),
    'Expiration':prettifyDateTimeShort(instance.expiration_date)
  }
}

function finish(id) {
  if(confirm("Do you really want to finish this storage parcel?")) {
    finishStorageParcel(id)
      .then(() => {
        if (!storageParcelFinishError.value) { successNotify("Storage parcel was successfully finished!")}
        else {errorNotify({'Error': 'finish parcel error'})}
      })
      .finally(() => { loadInstance() })
  }
}

function loadInstance() {
  const loader = $loading.show({})
  getInstance(route.params.slug)
    .then(() => {if (getInstanceError.value) {errorNotify(getInstanceError.value)}})
    .finally(() => {loader.hide()})
}


onMounted(() => {
  loadInstance()
})

</script>


<template>
  <section class="parcel-storage-page-detail">
    <div class="row pb-3">
      <div class="col">
        <h1>Storage parcel details</h1>
      </div>
      <div class="col text-end">
        <div v-if="instance.status === 'expired'" class="d-inline pe-5" @click="finish(instance.id)">
          <button>Finish parcel now</button>
        </div>
        <div class="d-inline" @click="router.push({ name: 'parcels-storage' })"><button>Close</button></div>
      </div>
    </div>

    <div class="row">
      <div class="col-5">
        <h3>Main info</h3>
        <TableCard v-if="instance"
            :item="prepareParcelInfo(instance)"
            :icon-folder="'main_menu'"
            :icon-name="'parcels_icon'"
            :name="'Parcel'"/>

        <TableCard v-if="instance"
            :item="prepareDeviceInfo(instance)"
            :icon-folder="'main_menu'"
            :icon-name="'devices_icon'"
            :name="'Locker'"/>

        <TableCard v-if="instance"
            :item="prepareUserInfo(instance)"
            :icon-folder="'main_menu'"
            :icon-name="'employees_icon'"
            :name="'User'"/>

      </div>
      <div class="col-2"></div>
      <div class="col-5">
        <h3>Events log</h3>
        <div v-if="instance.events_log" class="pt-2">
          <div v-for="log in instance.events_log.events" :key="log.datetime">
            <div v-if="log.type === 'status_changed'" class="log-card">
              <div class="log-title">Status was changed || {{ prettifyDateTimeShort(log.datetime) }}</div>
              <div class="text-center pt-3" v-if="log.new_status && log.old_status">
                <span class="fw-bold">{{ log.old_status}}</span> -> <span class="fw-bold">{{ log.new_status}}</span>
              </div>
            </div>
            <div v-if="log.type === 'cell_was_opened'" class="log-card">
              <div class="log-title">Cell was opened || {{ prettifyDateTimeShort(log.datetime) }}</div>
            </div>
            <div v-if="log.type === 'cell_was_closed'" class="log-card">
              <div class="log-title">Cell was closed || {{ prettifyDateTimeShort(log.datetime) }}</div>
            </div>
            <div v-if="log.type === 'cell_was_errored'" class="log-card">
              <div class="log-title">Cell error was received || {{ prettifyDateTimeShort(log.datetime) }}</div>
              <div class="pt-3">Error: {{ log.error }}</div>
            </div>
            <div v-if="log.type === 'cell_photo_was_received'" class="log-card">
              <div class="log-title">Cell photo was received || {{ prettifyDateTimeShort(log.datetime) }}</div>
              <div class="pt-3 text-center">
                <a target="_blank" :href="getFullUrl(log.photo_url)">
                  <img alt="cell photo" :src="getFullUrl(log.photo_url)"/>
                </a>
              </div>
            </div>
            <div class="py-2"></div>
          </div>
        </div>
        <div v-else>
          Logs is empty
        </div>
      </div>
    </div>

  </section>
</template>


<style scoped>
  img {
    max-height: 300px;
  }
  .log-title {
    background-color: #F4F3FF;
  }
  .log-card {
    border: 1px solid #2114ad;;
    border-radius: 20px;
    padding: 16px;
  }
</style>