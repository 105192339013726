<script setup>
import {useStore} from "vuex";
import {computed, defineEmits, defineProps, ref} from "vue";
import AddressCreateUpdateModal from "@/components/modals/location/AddressCreateUpdateModal";
import {successNotify, errorNotify, prettifyDateTimeShort} from "@/mixins";
import TableMenu from "@/components/layouts/TableMenu.vue";

const emit = defineEmits(['update'])
defineProps({
  addressList: Object
})

const store = useStore()
const instanceDetail = computed(() => store.getters['address/addressDetail'])
const error = computed(() => store.getters['address/deleteAddressError'])
const getError = computed(() => store.getters['address/getAddressError'])
const deleteInstance = (val) => store.dispatch('address/deleteAddress', val)
const getInstance = (val) => store.dispatch('address/getAddress', val)

const showModal = ref(false)
function openModal(id) {
  getInstance(id)
      .then(() => {if (getError.value) {errorNotify(error.value)}})
      .finally(() => { showModal.value = true })
}

function closeModal() {
  showModal.value = false
  emit('update')
}

function del(id) {
  if(confirm("Do you really want to delete this address?")) {
    deleteInstance(id)
      .then(() => {
        if (!error.value) { successNotify("Address deleted successfully!")}
        else {errorNotify(error.value)}
      })
      .finally(() => { emit('update'); })
  }
}

</script>

<template>
  <AddressCreateUpdateModal v-if="showModal" v-model="showModal" @close="closeModal()" :instance="instanceDetail"/>

  <div class="py-3 row">
    <div class="col">
      <h4>Address list</h4>
    </div>
  </div>
  <table class="table">
    <thead>
      <th class="first-el">City</th>
      <th>Street</th>
      <th>Building</th>
      <th>Apartment</th>
      <th>Created at</th>
<!--      <th>Organization</th>-->
      <th></th>
      </thead>
    <tbody>
      <tr v-for="item in addressList.results" :key="item.id">
        <th class="first-el">{{ item.city.name }}</th>
        <th>{{ item.street }}</th>
        <th>{{ item.building }}</th>
        <th>{{ item.apartment }}</th>
        <th>{{ prettifyDateTimeShort(item.created_at) }}</th>
<!--        <th>{{ item.organization }}</th>-->
        <th>
          <TableMenu @open-modal="openModal(item.id)" @del="del(item.id)"/>
        </th>
      </tr>
    </tbody>
  </table>
</template>