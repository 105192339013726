<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted, ref} from "vue"
import MainModal from "@/components/layouts/MainModal"
import Multiselect from 'vue-multiselect'

const store = useStore()
const organizationList = computed(() => store.getters['organization/organizationList'])

const getOrganizationList = (val) => store.dispatch('organization/getOrganizationList', val)

const emit = defineEmits(['setOrganization', 'close'])
const props = defineProps({instance: Object})

const instanceData = ref({})

function confirm() {
  emit('setOrganization', instanceData.value)
  emit('close')
}

onMounted(() => {
  getOrganizationList('')
  if (props.instance) {instanceData.value = props.instance}
})
</script>


<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')">
    <template v-slot:title>Organization</template>
    <div class="modal__content">

      <div class="pb-3 form-floating select-wrapper" v-if="organizationList">
        <multiselect
            selectLabel=""
            deselectLabel=""
            placeholder="Change the organization"
            v-model="instanceData"
            :options="organizationList.results"
            label="name"
        >
        </multiselect>
      </div>

    </div>
  </main-modal>
</template>
