<script setup>
import {errorNotify, prettifyDate, successNotify} from "@/mixins";
import UserCreateUpdateModal from "@/components/modals/UserCreateUpdateModal.vue";
import {computed, defineEmits, defineProps, ref} from "vue";
import {useStore} from "vuex";
import TableMenu from "@/components/layouts/TableMenu.vue";

const emit = defineEmits(['update'])
defineProps({
  userList: Object
})

const store = useStore()
const instanceDetail = computed(() => store.getters['user/userData'])
const deleteInstanceError = computed(() => store.getters['user/deleteUserError'])
const getInstanceError = computed(() => store.getters['user/getUserError'])
const deleteInstance = (val) => store.dispatch('user/deleteUser', val)
const getInstance = (val) => store.dispatch('user/getUser', val)

const showModal = ref(false)
function openModal(id) {
  getInstance(id)
      .then(() => {if (getInstanceError.value) {errorNotify(getInstanceError.value)}})
      .finally(() => { showModal.value = true })
}
function closeModal() {
  showModal.value = false
  emit('update')
}
function del(id) {
  if(confirm("Do you really want to delete this user?")) {
    deleteInstance(id)
      .then(() => {
        if (!deleteInstanceError.value) { successNotify("User deleted successfully!")}
        else {errorNotify(deleteInstanceError.value)}
      })
      .finally(() => { emit('update'); })
  }
}
</script>

<template>
  <UserCreateUpdateModal v-model="showModal" v-if="showModal" @close="closeModal()" :instance="instanceDetail" />
  <table class="table">
    <thead>
    <th class="first-el">Name</th>
    <th>Role</th>
    <th>Email</th>
    <th>Joined</th>
    <th>Organization</th>
    <th></th>
    </thead>
    <tbody>
    <tr v-for="instance in userList.results" :key="instance.id">
      <th class="first-el">{{ instance.first_name }}</th>

      <th>{{ instance.role }}</th>
      <th>{{ instance.email }}</th>
      <th>{{ prettifyDate(instance.date_joined) }}</th>
      <th><span v-for="org in instance.organizations" :key="org.id" >{{ org.name }}</span></th>
      <th>
        <TableMenu @open-modal="openModal(instance.id)" @del="del(instance.id)"/>
      </th>
    </tr>
    </tbody>
  </table>
</template>