import {
    CREATE_CONTROL_UNIT_BEGIN,
    CREATE_CONTROL_UNIT_FAILURE,
    CREATE_CONTROL_UNIT_SUCCESS,
    DELETE_CONTROL_UNIT_BEGIN,
    DELETE_CONTROL_UNIT_FAILURE,
    DELETE_CONTROL_UNIT_SUCCESS,
    GET_CONTROL_UNIT_BEGIN,
    GET_CONTROL_UNIT_FAILURE,
    GET_CONTROL_UNIT_LIST_BEGIN,
    GET_CONTROL_UNIT_LIST_FAILURE,
    GET_CONTROL_UNIT_LIST_SUCCESS,
    GET_CONTROL_UNIT_SUCCESS,
    UPDATE_CONTROL_UNIT_DATA_BEGIN,
    UPDATE_CONTROL_UNIT_DATA_FAILURE,
    UPDATE_CONTROL_UNIT_DATA_SUCCESS
} from "@/store/types";
import controlUnit from "@/api/control_unit";

const initialState = {
    listControlUnitError: false,
    getControlUnitError: false,
    createControlUnitError: false,
    updateControlUnitError: false,
    deleteControlUnitError: false,
    controlUnitDetail: false,
    controlUnitList: false,
};

const getters = {
    controlUnitList:(state) => state.controlUnitList,
    controlUnitDetail:(state) => state.controlUnitDetail,
    createControlUnitError:(state) => state.createControlUnitError,
    updateControlUnitError:(state) => state.updateControlUnitError,
    deleteControlUnitError:(state) => state.deleteControlUnitError,
    getControlUnitError:(state) => state.getControlUnitError,
};

const actions = {
    getControlUnit({ commit }, id) {
        commit(GET_CONTROL_UNIT_BEGIN);
        return controlUnit.getControlUnit(id)
            .then((response) => commit(GET_CONTROL_UNIT_SUCCESS, response.data))
            .catch((error) => commit(GET_CONTROL_UNIT_FAILURE, error.response.data));
    },
    getControlUnitList({ commit }, filter) {
        commit(GET_CONTROL_UNIT_LIST_BEGIN);
        return controlUnit.getControlUnitList(filter)
            .then((response) => commit(GET_CONTROL_UNIT_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_CONTROL_UNIT_LIST_FAILURE, error.response.data));
    },
    createControlUnit({ commit }, data) {
        commit(CREATE_CONTROL_UNIT_BEGIN);
        return controlUnit.createControlUnit(data)
            .then(() => commit(CREATE_CONTROL_UNIT_SUCCESS))
            .catch((error) => commit(CREATE_CONTROL_UNIT_FAILURE, error.response.data));
    },
    updateControlUnit({ commit }, { id, data }) {
        commit(UPDATE_CONTROL_UNIT_DATA_BEGIN);
        return controlUnit.updateControlUnit(id, data)
            .then(() => commit(UPDATE_CONTROL_UNIT_DATA_SUCCESS))
            .catch((error) => commit(UPDATE_CONTROL_UNIT_DATA_FAILURE, error.response.data));
    },
    deleteControlUnit({ commit }, id) {
        commit(DELETE_CONTROL_UNIT_BEGIN);
        return controlUnit.deleteControlUnit(id)
            .then(() => commit(DELETE_CONTROL_UNIT_SUCCESS))
            .catch((error) => commit(DELETE_CONTROL_UNIT_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_CONTROL_UNIT_LIST_BEGIN](state) {
        state.listControlUnitError = false;
    },
    [GET_CONTROL_UNIT_LIST_SUCCESS](state, data) {
        state.controlUnitList = data;
        state.listControlUnitError = false;
    },
    [GET_CONTROL_UNIT_LIST_FAILURE](state, error) {
        state.listControlUnitError = error;
    },
    [GET_CONTROL_UNIT_BEGIN](state) {
        state.getControlUnitError = false;
    },
    [GET_CONTROL_UNIT_SUCCESS](state, data) {
        state.controlUnitDetail = data;
        state.getControlUnitError = false;
    },
    [GET_CONTROL_UNIT_FAILURE](state, error) {
        state.getControlUnitError = error;
    },
    [CREATE_CONTROL_UNIT_BEGIN](state) {
        state.createControlUnitError = false;
    },
    [CREATE_CONTROL_UNIT_SUCCESS](state) {
        state.createControlUnitError = false;
    },
    [CREATE_CONTROL_UNIT_FAILURE](state, error) {
        state.createControlUnitError = error;
    },
    [UPDATE_CONTROL_UNIT_DATA_BEGIN](state) {
        state.updateControlUnitError = false;
    },
    [UPDATE_CONTROL_UNIT_DATA_SUCCESS](state) {
        state.updateControlUnitError = false;
    },
    [UPDATE_CONTROL_UNIT_DATA_FAILURE](state, error) {
        state.updateControlUnitError = error;
    },
    [DELETE_CONTROL_UNIT_BEGIN](state) {
        state.deleteControlUnitError = false;
    },
    [DELETE_CONTROL_UNIT_SUCCESS](state) {
        state.deleteControlUnitError = false;
    },
    [DELETE_CONTROL_UNIT_FAILURE](state, error) {
        state.deleteControlUnitError = error;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
}