<template>
 <section class="account-settings pt-5">
    <h3>Account settings page</h3>
 </section>
</template>

<script>
export default {
  name: "AccountSettings"
}
</script>
