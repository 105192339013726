import cell from '@/api/cell';

import {
    GET_CELL_BEGIN,
    GET_CELL_FAILURE,
    GET_CELL_SUCCESS,
    GET_CELL_LIST_BEGIN,
    GET_CELL_LIST_FAILURE,
    GET_CELL_LIST_SUCCESS,
    UPDATE_CELL_BEGIN,
    UPDATE_CELL_SUCCESS,
    UPDATE_CELL_FAILURE,
    DELETE_CELL_BEGIN,
    DELETE_CELL_FAILURE,
    DELETE_CELL_SUCCESS,
    CREATE_CELL_BEGIN,
    CREATE_CELL_SUCCESS,
    CREATE_CELL_FAILURE
} from './types';


const initialState = {
    cellList: false,
    cellListError: false,
    cellData: false,
    cellDataError: false,
    cellCreateError: false,
    cellUpdateError: false,
    cellDeleteError: false,
};

const getters = {
    cellList:(state) => state.cellList,
    cellListError:(state) => state.cellListError,
    cellData:(state) => state.cellData,
    cellDataError:(state) => state.cellDataError,
    cellCreateError:(state) => state.cellCreateError,
    cellUpdateError:(state) => state.cellUpdateError,
    cellDeleteError:(state) => state.cellDeleteError
};

const actions = {
    getCell({ commit }, cellId) {
        commit(GET_CELL_BEGIN);
        return cell.getCell(cellId)
            .then((response) => commit(GET_CELL_SUCCESS, response.data))
            .catch((error) => commit(GET_CELL_FAILURE, error.response.data));
    },
    getCellList({ commit }, filter) {
        commit(GET_CELL_LIST_BEGIN);
        return cell.getCellList(filter)
            .then((response) => commit(GET_CELL_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_CELL_LIST_FAILURE, error.response.data));
    },
    createCell({ commit }, data) {
        commit(CREATE_CELL_BEGIN);
        return cell.createCell(data)
            .then(() => commit(CREATE_CELL_SUCCESS))
            .catch((error) => commit(CREATE_CELL_FAILURE, error.response.data));
    },
    updateCell({ commit }, { id, data }) {
        commit(UPDATE_CELL_BEGIN);
        return cell.updateCell(id, data)
            .then(() => commit(UPDATE_CELL_SUCCESS))
            .catch((error) => commit(UPDATE_CELL_FAILURE, error.response.data));
    },
    deleteCell({ commit }, logId) {
        commit(DELETE_CELL_BEGIN);
        return cell.deleteCell(logId)
            .then(() => commit(DELETE_CELL_SUCCESS))
            .catch((error) => commit(DELETE_CELL_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_CELL_BEGIN](state) {
        state.cellDataError = false;
    },
    [GET_CELL_FAILURE](state, error) {
        state.cellData = false;
        state.cellDataError = error;
    },
    [GET_CELL_SUCCESS](state, data) {
        state.cellData = data;
        state.cellDataError = false;
    },
    [GET_CELL_LIST_BEGIN](state) {
        state.cellListError = false;
    },
    [GET_CELL_LIST_FAILURE](state, error) {
        state.cellList = false;
        state.cellListError = error;
    },
    [GET_CELL_LIST_SUCCESS](state, data) {
        state.cellList = data;
        state.cellListError = false;
    },
    [CREATE_CELL_BEGIN](state) {
        state.cellCreateError = false;
    },
    [CREATE_CELL_SUCCESS](state) {
        state.cellCreateError = false;
    },
    [CREATE_CELL_FAILURE](state, error) {
        state.cellCreateError = error;
    },
    [UPDATE_CELL_BEGIN](state) {
        state.cellUpdateError = false;
    },
    [UPDATE_CELL_FAILURE](state, error) {
        state.cellUpdateError = error;
    },
    [UPDATE_CELL_SUCCESS](state) {
        state.cellUpdateError = false;
    },
    [DELETE_CELL_BEGIN](state) {
        state.cellDataError = false;
    },
    [DELETE_CELL_FAILURE](state, error) {
        state.cellDataError = error;
    },
    [DELETE_CELL_SUCCESS](state) {
        state.cellDataError = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
