<script setup>

import {defineProps} from "vue";
import {useStore} from "vuex";
import router from "@/router";

const store = useStore()

defineProps({
  userRole: {required: true},
  userName: {required: true},
  userEmail: {required: true},
})

const logout = (val) => store.dispatch('auth/logout', val)

function doLogout() {
  logout()
      .finally(() => router.push({ name: 'login' }))
}

</script>
<template>
  <section class="main-menu">

    <nav class="">
      <ul class="nav flex-column">

        <router-link to="/">
          <div class="main-logo">
            <img src="@/assets/img/lockerbot_logo.png" alt="lockerbot logo">
          </div>
        </router-link>

        <!--    Dashboard    -->
        <li class="nav-item">
          <router-link class="nav-link" to="/dashboard">
            <img src="@/assets/icon/main_menu/dashboard_icon.png" alt="dashboard icon">
            <div class="d-inline">Dashboard</div>
          </router-link>
        </li>

        <!--    Devices with submenu    -->
        <li v-if="userRole === 'administrator' || userRole === 'manager'" class="nav-item">
          <a href="#" class="nav-link">
            <div class="d-flex">
              <div class="d-inline">
                <img src="@/assets/icon/main_menu/devices_icon.png" alt="devices icon">
              </div>
              <div class="d-inline">Devices</div>
              <div class="d-inline w-100 text-end">
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0L5 5L10 0H0Z" fill="#101828"/>
                </svg>
              </div>
            </div>
          </a>

          <div class="smenu">
            <router-link to="/lockers">Lockers</router-link>
            <router-link to="/devices-logs">Logs</router-link>
          </div>
        </li>

        <!--    Parcels with submenu    -->
        <li v-if="userRole === 'administrator' || userRole === 'manager'" class="nav-item">
          <a href="#" class="nav-link">
            <div class="d-flex">
              <div class="d-inline">
                <img src="@/assets/icon/main_menu/parcels_icon.png" alt="parcels icon">
              </div>
              <div class="d-inline">Parcels</div>
              <div class="d-inline w-100 text-end">
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0L5 5L10 0H0Z" fill="#101828"/>
                </svg>
              </div>
            </div>
          </a>

          <div class="smenu">
            <router-link to="/parcels-storage">Storage</router-link>
          </div>
          <div class="smenu">
            <router-link to="/parcels-expected">Expected</router-link>
          </div>
        </li>

        <!--    Accounts with submenu    -->
        <li v-if="userRole === 'administrator' || userRole === 'manager'" class="nav-item">
          <a href="#" class="nav-link">
            <div class="d-flex">
              <div class="d-inline">
                <img src="@/assets/icon/main_menu/accounts_icon.png" alt="accounts icon">
              </div>
              <div class="d-inline">Accounts</div>
              <div class="d-inline w-100 text-end">
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0L5 5L10 0H0Z" fill="#101828"/>
                </svg>
              </div>
            </div>
          </a>
          <div class="smenu">
            <router-link to="/prepaid-accounts">Prepaid accounts</router-link>
          </div>
          <div class="smenu">
            <router-link to="/app-customers">App customers</router-link>
          </div>
        </li>

        <!--    Employees    -->
        <li v-if="userRole === 'administrator'" class="nav-item">
          <router-link class="nav-link" to="/employees">
            <img src="@/assets/icon/main_menu/employees_icon.png" alt="employees icon">
            Employees
          </router-link>
        </li>

        <!--    Organizations    -->
        <li v-if="userRole === 'administrator'" class="nav-item">
          <router-link class="nav-link" to="/organizations">
            <img src="@/assets/icon/main_menu/organizations_icon.png" alt="organizations icon">
            Organizations
          </router-link>
        </li>

        <!--    Locations   -->
        <li v-if="userRole === 'manager' || userRole === 'administrator'" class="nav-item">
          <router-link class="nav-link" to="/locations">
            <img src="@/assets/icon/main_menu/locations_icon.png" alt="locations icon">
            Locations
          </router-link>
        </li>

        <hr>

        <!--  Main settings with submenu-->
        <li v-if="userRole === 'administrator'" class="nav-item">
          <a href="#" class="nav-link">
            <div class="d-flex">
              <div class="d-inline">
                <img src="@/assets/icon/main_menu/main_settings_icon.png" alt="main settings icon">
              </div>
              <div class="d-inline">Settings</div>
              <div class="d-inline w-100 text-end">
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0L5 5L10 0H0Z" fill="#101828"/>
                </svg>
              </div>
            </div>
          </a>
<!--          <div class="smenu">-->
<!--            <router-link to="/devices-models">Models</router-link>-->
<!--          </div>-->
          <div class="smenu">
            <router-link to="/firmwares">Firmwares</router-link>
            <router-link to="/control-units">Control units</router-link>
          </div>
        </li>
      </ul>
    </nav>

    <div class="user-menu d-flex">
        <img src="@/assets/icon/main_menu/user_icon.png" alt="user icon">
      <div class="row">
        <div class="text-bold col-6" v-if="userName">{{ userName }}</div>
        <div class="dropdown col-6 text-end">
          <button class="btn button dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.99967 10.334C2.4417 10.334 2.86562 10.5096 3.17819 10.8221C3.49075 11.1347 3.66634 11.5586 3.66634 12.0007C3.66634 12.4427 3.49075 12.8666 3.17819 13.1792C2.86562 13.4917 2.4417 13.6673 1.99967 13.6673C1.55765 13.6673 1.13372 13.4917 0.821163 13.1792C0.508603 12.8666 0.333008 12.4427 0.333008 12.0007C0.333008 11.5586 0.508603 11.1347 0.821163 10.8221C1.13372 10.5096 1.55765 10.334 1.99967 10.334ZM1.99967 5.33398C2.4417 5.33398 2.86562 5.50958 3.17819 5.82214C3.49075 6.1347 3.66634 6.55862 3.66634 7.00065C3.66634 7.44268 3.49075 7.8666 3.17819 8.17916C2.86562 8.49172 2.4417 8.66732 1.99967 8.66732C1.55765 8.66732 1.13372 8.49172 0.821163 8.17916C0.508603 7.8666 0.333008 7.44268 0.333008 7.00065C0.333008 6.55862 0.508603 6.1347 0.821163 5.82214C1.13372 5.50958 1.55765 5.33398 1.99967 5.33398ZM1.99967 0.333984C2.4417 0.333984 2.86562 0.509579 3.17819 0.82214C3.49075 1.1347 3.66634 1.55862 3.66634 2.00065C3.66634 2.44268 3.49075 2.8666 3.17819 3.17916C2.86562 3.49172 2.4417 3.66732 1.99967 3.66732C1.55765 3.66732 1.13372 3.49172 0.821163 3.17916C0.508603 2.8666 0.333008 2.44268 0.333008 2.00065C0.333008 1.55862 0.508603 1.1347 0.821163 0.82214C1.13372 0.509579 1.55765 0.333984 1.99967 0.333984Z" fill="#101828"/>
            </svg>
          </button>
          <ul class="dropdown-menu">
            <li><a @click="doLogout" class="dropdown-item" href="#">logout</a></li>
          </ul>
        </div>
      </div>
    </div>

  </section>
</template>

