<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted, ref} from "vue"
import { successNotify, errorNotify } from '@/mixins.js'
import MainModal from "@/components/layouts/MainModal"
import Multiselect from 'vue-multiselect'

const store = useStore()
const createInstanceError = computed(() => store.getters['address/createAddressError'])
const updateInstanceError = computed(() => store.getters['address/createAddressError'])
const cityList = computed(() => store.getters['city/cityList'])
const createInstance = (val) => store.dispatch('address/createAddress', val)
const updateInstance = (val) => store.dispatch('address/updateAddress', val)
const getCityList = (val) => store.dispatch('city/getCityList', val)

const emit = defineEmits(['update', 'close'])
const props = defineProps({instance: Object})


const city = ref('')
const instanceData = {
  street: '',
  building: '',
  apartment: '',
  lat: '',
  lng: '',
}

function instanceCreate() {
  instanceData.city = city.value.id
  createInstance(instanceData)
      .then(() => {
        if (!createInstanceError.value) { successNotify("Address added successfully!") }
        else { errorNotify(createInstanceError.value) }
      })
      .finally(() => { emit('close') })
}

function instanceUpdate() {
  let newData = {data: instanceData, id: props.instance.id }
  newData.data.city = city.value.id
  updateInstance(newData)
      .then(() => {
        if (!updateInstanceError.value) { successNotify("Address updated successfully!") }
        else {errorNotify(updateInstanceError.value)}
      })
      .finally(() => { emit('close') })
}

function confirm() {
  if (props.instance) { instanceUpdate() } else { instanceCreate() }
}

onMounted(() => {
  getCityList('')
  if (props.instance) {
    city.value = props.instance.city
    for (const [key, value] of Object.entries(props.instance)) {
        instanceData[`${key}`] = value;
      }
  }})
</script>


<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')" >
    <template v-if="instance" v-slot:title>Edit Address</template>
    <template v-else v-slot:title>Add Address</template>
    <div class="modal__content">

      <div class="pb-3 form-floating select-wrapper" v-if="cityList">
        <multiselect
            selectLabel=""
            deselectLabel=""
            placeholder="Change the city"
            v-model="city"
            :options="cityList.results"
            label="name"
        >
        </multiselect>
      </div>
      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="City name" v-model="instanceData.street">
        <label>Street</label>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-floating">
            <input class="form-control" type="text" placeholder="City code" v-model="instanceData.building">
            <label>Building</label>
          </div>
        </div>
        <div class="col-6">
          <div class="col form-floating">
            <input class="form-control" type="text" placeholder="City code" v-model="instanceData.apartment">
            <label>Apartment</label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-floating">
            <input class="form-control" type="text" placeholder="12.198370" v-model="instanceData.lat">
            <label>Lat</label>
          </div>
        </div>
        <div class="col-6">
          <div class="col form-floating">
            <input class="form-control" type="text" placeholder="109.212387" v-model="instanceData.lng">
            <label>Lng</label>
          </div>
        </div>
      </div>
    </div>
  </main-modal>
</template>
