import auth from '../api/auth';
import session from '../api/session';

import {
    LOGIN_BEGIN,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    REMOVE_TOKEN,
    SET_TOKEN,
} from './types';


const initialState = {
    authenticating: false,
    authError: false,
    token: localStorage.getItem('token') || '',
};

const getters = {
    isAuthenticated: (state) => !!state.token,
    authError: (state) => state.authError,
};

const actions = {
    login({ commit }, { email, password }) {
        commit(LOGIN_BEGIN);
        return auth.login(email, password)
            .then(({ data }) => commit(SET_TOKEN, data.access))
            .then(() => commit(LOGIN_SUCCESS))
            .catch((error) => commit(LOGIN_FAILURE, error.response.data));
    },
    logout({ commit }) {
        commit(LOGOUT);
        commit(REMOVE_TOKEN);
    },
};

const mutations = {
    [LOGIN_BEGIN](state) {
        state.authenticating = true;
        state.authError = false;
    },
    [LOGIN_FAILURE](state, error) {
        state.authenticating = false;
        state.authError = error;
    },
    [LOGIN_SUCCESS](state) {
        state.authenticating = false;
        state.authError = false;
    },
    [LOGOUT](state) {
        state.authenticating = false;
        state.authError = false;
    },
    [SET_TOKEN](state, token) {
        localStorage.setItem('token', token);
        session.defaults.headers.common.Authorization = `Bearer ${token}`;
        state.token = token;
    },
    [REMOVE_TOKEN](state) {
        localStorage.removeItem('token');
        delete session.defaults.headers.common.Authorization;
        state.token = null;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
