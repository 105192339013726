<script setup>

import { defineProps} from "vue";
import {momentFromNow, prettifyDateTimeShort} from "@/mixins";
import {useRouter} from "vue-router";

const router = useRouter()

defineProps({
  itemsList: Object
})

</script>

<template>
  <table class="table">
    <thead>
      <th class="first-el">Device</th>
      <th>Cell number</th>
      <th>Status</th>
      <th>User</th>
      <th>Created</th>
      <th>Updated</th>
      <th>Expiration</th>
      <th>Organization</th>
      <th></th>
    </thead>
    <tbody>
      <tr @click="router.push({name:'parcel-storage', params:{slug:instance.id}})"
          v-for="instance in itemsList.results" :key="instance.id">

        <th class="first-el">{{ instance.device }}</th>

        <th>{{ instance.device_cell }}</th>

        <th v-if="instance.status">{{ instance.status }}</th>

        <th v-if="instance.prepaid_account">Account SN: {{ instance.prepaid_account }}</th>
        <th v-else-if="instance.recipient">Customer: {{ instance.recipient }}</th>
        <th v-else></th>

        <th>{{ prettifyDateTimeShort(instance.created_at) }}</th>
        <th v-if="instance.updated_at">{{ momentFromNow(instance.updated_at) }}</th><th v-else>N/A</th>

        <th v-if="instance.expiration_date == null"><div class="balance-badge balance-unlimited">Unlimited</div></th>
        <th v-else> {{ prettifyDateTimeShort(instance.expiration_date) }} </th>

        <th>{{ instance.organization }}</th>
      </tr>
    </tbody>
  </table>
</template>

<style scoped>
  tr {
    cursor: pointer;
  }
</style>