import locker from '@/api/locker';

import {
    GET_LOCKER_BEGIN,
    GET_LOCKER_FAILURE,
    GET_LOCKER_SUCCESS,
    GET_LOCKER_LIST_BEGIN,
    GET_LOCKER_LIST_FAILURE,
    GET_LOCKER_LIST_SUCCESS,
    UPDATE_LOCKER_BEGIN,
    UPDATE_LOCKER_SUCCESS,
    UPDATE_LOCKER_FAILURE,
    DELETE_LOCKER_BEGIN,
    DELETE_LOCKER_FAILURE,
    DELETE_LOCKER_SUCCESS,
    CREATE_LOCKER_BEGIN,
    CREATE_LOCKER_SUCCESS,
    CREATE_LOCKER_FAILURE,
    CREATE_NEW_KEY_BEGIN,
    CREATE_NEW_KEY_SUCCESS,
    CREATE_NEW_KEY_FAILURE
} from './types';


const initialState = {
    lockerList: false,
    lockerListError: false,
    lockerData: false,
    lockerKey: false,
    lockerKeyError: false,
    lockerDataError: false,
    lockerCreateError: false,
    lockerUpdateError: false,
    lockerDeleteError: false,
};

const getters = {
    lockerList:(state) => state.lockerList,
    lockerListError:(state) => state.lockerListError,
    lockerData:(state) => state.lockerData,
    lockerKey:(state) => state.lockerKey,
    lockerKeyError:(state) => state.lockerKeyError,
    lockerDataError:(state) => state.lockerDataError,
    lockerCreateError:(state) => state.lockerCreateError,
    lockerUpdateError:(state) => state.lockerUpdateError,
    lockerDeleteError:(state) => state.lockerDeleteError,
};

const actions = {
    getLocker({ commit }, lockerId) {
        commit(GET_LOCKER_BEGIN);
        return locker.getLocker(lockerId)
            .then((response) => commit(GET_LOCKER_SUCCESS, response.data))
            .catch((error) => commit(GET_LOCKER_FAILURE, error.response.data));
    },
    getLockerList({ commit }, filter) {
        commit(GET_LOCKER_LIST_BEGIN);
        return locker.getLockerList(filter)
            .then((response) => commit(GET_LOCKER_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_LOCKER_LIST_FAILURE, error.response.data));
    },
    createNewKey({ commit }, lockerId) {
        commit(CREATE_NEW_KEY_BEGIN);
        return locker.createNewKey(lockerId)
            .then((response) => commit(CREATE_NEW_KEY_SUCCESS, response.data))
            .catch((error) => commit(CREATE_NEW_KEY_FAILURE, error.response.data));
    },
    createLocker({ commit }, data) {
        commit(CREATE_LOCKER_BEGIN);
        return locker.createLocker(data)
            .then((response) => commit(CREATE_LOCKER_SUCCESS, response.data))
            .catch((error) => commit(CREATE_LOCKER_FAILURE, error.response.data));
    },
    updateLocker({ commit }, { id, data }) {
        commit(UPDATE_LOCKER_BEGIN);
        return locker.updateLocker(id, data)
            .then(() => commit(UPDATE_LOCKER_SUCCESS))
            .catch((error) => commit(UPDATE_LOCKER_FAILURE, error.response.data));
    },
    deleteLocker({ commit }, logId) {
        commit(DELETE_LOCKER_BEGIN);
        return locker.deleteLocker(logId)
            .then(() => commit(DELETE_LOCKER_SUCCESS))
            .catch((error) => commit(DELETE_LOCKER_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_LOCKER_BEGIN](state) {
        state.lockerDataError = false;
    },
    [GET_LOCKER_FAILURE](state, error) {
        state.lockerData = false;
        state.lockerDataError = error;
    },
    [GET_LOCKER_SUCCESS](state, data) {
        state.lockerData = data;
        state.lockerDataError = false;
    },
    [GET_LOCKER_LIST_BEGIN](state) {
        state.lockerListError = false;
    },
    [GET_LOCKER_LIST_FAILURE](state, error) {
        state.lockerList = false;
        state.lockerListError = error;
    },
    [GET_LOCKER_LIST_SUCCESS](state, data) {
        state.lockerList = data;
        state.lockerListError = false;
    },
    [CREATE_NEW_KEY_BEGIN](state) {
        state.lockerKeyError = false;
    },
    [CREATE_NEW_KEY_SUCCESS](state, data) {
        state.lockerKey = data.key;
        state.lockerKeyError = false;
    },
    [CREATE_NEW_KEY_FAILURE](state, error) {
        state.lockerKeyError = error;
    },
    [CREATE_LOCKER_BEGIN](state) {
        state.lockerCreateError = false;
    },
    [CREATE_LOCKER_SUCCESS](state, data) {
        state.lockerKey = data.key;
        state.lockerCreateError = false;
    },
    [CREATE_LOCKER_FAILURE](state, error) {
        state.lockerCreateError = error;
    },
    [UPDATE_LOCKER_BEGIN](state) {
        state.lockerUpdateError = false;
    },
    [UPDATE_LOCKER_FAILURE](state, error) {
        state.lockerUpdateError = error;
    },
    [UPDATE_LOCKER_SUCCESS](state) {
        state.lockerUpdateError = false;
    },
    [DELETE_LOCKER_BEGIN](state) {
        state.lockerDataError = false;
    },
    [DELETE_LOCKER_FAILURE](state, error) {
        state.lockerDataError = error;
    },
    [DELETE_LOCKER_SUCCESS](state) {
        state.lockerDataError = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
