/* eslint-disable */
import session from './session';

export default {
    getCountryList(filter) {
        return session.get(`/api/v1/country/${filter}`);
    },
    getCountry(id) {
        return session.get(`/api/v1/country/${id}/`);
    },
    deleteCountry(id) {
        return session.delete(`/api/v1/country/${id}/`);
    },
    createCountry(data) {
        return session.post(`/api/v1/country/`, data);
    },
    updateCountry(id, data) {
        return session.patch(`/api/v1/country/${id}/`, data );
    },
};