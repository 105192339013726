<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted, ref} from "vue"
import MainModal from "@/components/layouts/MainModal"
import Multiselect from 'vue-multiselect'

const store = useStore()
const modelList = computed(() => store.getters['model/modelList'])
const cellFirmwareList = computed(() => store.getters['firmware/cellFirmwareList'])

const getModelListList = (val) => store.dispatch('model/getModelList', val)
const getCellFirmwareList = (val) => store.dispatch('firmware/getCellFirmwareList', val)

const emit = defineEmits(['setInformation', 'close'])
const props = defineProps({instance: Object})

const instanceData = ref({name: '', model: ''})

function confirm() {
  emit('setInformation', instanceData.value)
  emit('close')
}

onMounted(() => {
  getModelListList('')
  getCellFirmwareList('')
  if (props.instance) {instanceData.value = props.instance}
})
</script>


<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')">
    <template v-slot:title>Information</template>
    <div class="modal__content">

      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="Serial Number" v-model="instanceData.sn">
        <label>s/n</label>
      </div>

      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="10.252.1.1" v-model="instanceData.ip_address">
        <label>IP address</label>
      </div>

      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="City name" v-model="instanceData.name">
        <label>Name</label>
      </div>

      <div class="pb-3 form-floating select-wrapper" v-if="modelList">
        <multiselect
            selectLabel=""
            deselectLabel=""
            placeholder="Change the model"
            v-model="instanceData.model"
            :options="modelList.results"
            label="name"
        >
        </multiselect>
      </div>

      <div class="pb-3 form-floating select-wrapper" v-if="cellFirmwareList">
        <multiselect
            selectLabel=""
            deselectLabel=""
            placeholder="Change the cells firmware version"
            v-model="instanceData.cell_firmware_version"
            :options="cellFirmwareList.results"
            label="version"
        >
        </multiselect>
      </div>

    </div>
  </main-modal>
</template>
