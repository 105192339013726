/* eslint-disable */
import session from './session';

export default {
    getCellSizeList(filter) {
        return session.get(`/api/v1/cell_size/${filter}`);
    },
    getCellSize(id) {
        return session.get(`/api/v1/cell_size/${id}/`);
    },
    createCellSize(data) {
        return session.post(`/api/v1/cell_size/`, data);
    },
    updateCellSize(id, data) {
        return session.patch(`/api/v1/cell_size/${id}/`, data );
    },
    deleteCellSize(id) {
        return session.delete(`/api/v1/cell_size/${id}/`);
    },
};