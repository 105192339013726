import parcel from '@/api/parcel';

import {
    GET_STORAGE_PARCEL_BEGIN,
    GET_STORAGE_PARCEL_FAILURE,
    GET_STORAGE_PARCEL_SUCCESS,
    FINISH_STORAGE_PARCEL_BEGIN,
    FINISH_STORAGE_PARCEL_FAILURE,
    FINISH_STORAGE_PARCEL_SUCCESS,
    GET_STORAGE_PARCEL_LIST_BEGIN,
    GET_STORAGE_PARCEL_LIST_FAILURE,
    GET_STORAGE_PARCEL_LIST_SUCCESS,
    GET_EXPECTED_PARCEL_BEGIN,
    GET_EXPECTED_PARCEL_FAILURE,
    GET_EXPECTED_PARCEL_SUCCESS,
    GET_EXPECTED_PARCEL_LIST_BEGIN,
    GET_EXPECTED_PARCEL_LIST_FAILURE,
    GET_EXPECTED_PARCEL_LIST_SUCCESS,
} from './types';


const initialState = {
    storageParcelList: false,
    storageParcelFinishError: false,
    storageParcelListError: false,
    storageParcelData: false,
    storageParcelDataError: false,
    storageParcelDeleteError: false,
    expectedParcelList: false,
    expectedParcelListError: false,
    expectedParcelData: false,
    expectedParcelDataError: false,
    expectedParcelDeleteError: false,
};

const getters = {
    storageParcelList:(state) => state.storageParcelList,
    storageParcelListError:(state) => state.storageParcelListError,
    storageParcelFinishError:(state) => state.storageParcelFinishError,
    storageParcelData:(state) => state.storageParcelData,
    storageParcelDataError:(state) => state.storageParcelDataError,
    storageParcelDeleteError:(state) => state.storageParcelDeleteError,
    expectedParcelList:(state) => state.expectedParcelList,
    expectedParcelListError:(state) => state.expectedParcelListError,
    expectedParcelData:(state) => state.expectedParcelData,
    expectedParcelDataError:(state) => state.expectedParcelDataError,
    expectedParcelDeleteError:(state) => state.expectedParcelDeleteError,
};

const actions = {
    getStorageParcel({ commit }, parcelId) {
        commit(GET_STORAGE_PARCEL_BEGIN);
        return parcel.getStorageParcel(parcelId)
            .then((response) => commit(GET_STORAGE_PARCEL_SUCCESS, response.data))
            .catch((error) => commit(GET_STORAGE_PARCEL_FAILURE, error.response.data));
    },
    finishStorageParcel({ commit }, parcelId) {
        commit(FINISH_STORAGE_PARCEL_BEGIN);
        return parcel.finishStorageParcel(parcelId)
            .then(() => commit(FINISH_STORAGE_PARCEL_SUCCESS))
            .catch(() => commit(FINISH_STORAGE_PARCEL_FAILURE));
    },
    getStorageParcelList({ commit }, filter) {
        commit(GET_STORAGE_PARCEL_LIST_BEGIN);
        return parcel.getStorageParcelList(filter)
            .then((response) => commit(GET_STORAGE_PARCEL_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_STORAGE_PARCEL_LIST_FAILURE, error.response.data));
    },
    getExpectedParcel({ commit }, parcelId) {
        commit(GET_EXPECTED_PARCEL_BEGIN);
        return parcel.getExpectedParcel(parcelId)
            .then((response) => commit(GET_EXPECTED_PARCEL_SUCCESS, response.data))
            .catch((error) => commit(GET_EXPECTED_PARCEL_FAILURE, error.response.data));
    },
    getExpectedParcelList({ commit }, filter) {
        commit(GET_EXPECTED_PARCEL_LIST_BEGIN);
        return parcel.getExpectedParcelList(filter)
            .then((response) => commit(GET_EXPECTED_PARCEL_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_EXPECTED_PARCEL_LIST_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_STORAGE_PARCEL_BEGIN](state) {
        state.storageParcelDataError = false;
    },
    [GET_STORAGE_PARCEL_FAILURE](state, error) {
        state.storageParcelData = false;
        state.storageParcelDataError = error;
    },
    [GET_STORAGE_PARCEL_SUCCESS](state, data) {
        state.storageParcelData = data;
        state.storageParcelDataError = false;
    },
    [FINISH_STORAGE_PARCEL_BEGIN](state) {
        state.storageParcelFinishError = false;
    },
    [FINISH_STORAGE_PARCEL_FAILURE](state) {
        state.storageParcelFinishError = true;
    },
    [FINISH_STORAGE_PARCEL_SUCCESS](state) {
        state.storageParcelFinishError = false;
    },
    [GET_STORAGE_PARCEL_LIST_BEGIN](state) {
        state.storageParcelListError = false;
    },
    [GET_STORAGE_PARCEL_LIST_FAILURE](state, error) {
        state.storageParcelList = false;
        state.storageParcelListError = error;
    },
    [GET_STORAGE_PARCEL_LIST_SUCCESS](state, data) {
        state.storageParcelList = data;
        state.storageParcelListError = false;
    },
    [GET_EXPECTED_PARCEL_BEGIN](state) {
        state.expectedParcelDataError = false;
    },
    [GET_EXPECTED_PARCEL_FAILURE](state, error) {
        state.expectedParcelData = false;
        state.expectedParcelDataError = error;
    },
    [GET_EXPECTED_PARCEL_SUCCESS](state, data) {
        state.expectedParcelData = data;
        state.expectedParcelDataError = false;
    },
    [GET_EXPECTED_PARCEL_LIST_BEGIN](state) {
        state.expectedParcelListError = false;
    },
    [GET_EXPECTED_PARCEL_LIST_FAILURE](state, error) {
        state.expectedParcelList = false;
        state.expectedParcelListError = error;
    },
    [GET_EXPECTED_PARCEL_LIST_SUCCESS](state, data) {
        state.expectedParcelList = data;
        state.expectedParcelListError = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
