/* eslint-disable */
import session from './session';

export default {
    getControlUnitList(filter) {
        return session.get(`/api/v1/control_unit/${filter}`);
    },
    getControlUnit(id) {
        return session.get(`/api/v1/control_unit/${id}/`);
    },
    deleteControlUnit(id) {
        return session.delete(`/api/v1/control_unit/${id}/`);
    },
    createControlUnit(data) {
        return session.post(`/api/v1/control_unit/`, data);
    },
    updateControlUnit(id, data) {
        return session.patch(`/api/v1/control_unit/${id}/`, data );
    },
};