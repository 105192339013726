<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted, ref} from "vue"
import { successNotify, errorNotify } from '@/mixins.js'
import MainModal from "@/components/layouts/MainModal";

const store = useStore()
const createInstanceError = computed(() => store.getters['firmware/createFirmwareError'])
const updateInstanceError = computed(() => store.getters['firmware/updateFirmwareError'])
const createInstance = (val) => store.dispatch('firmware/createFirmware', val)
const updateInstance = (val) => store.dispatch('firmware/updateFirmware', val)

const emit = defineEmits(['update', 'close'])
const props = defineProps({instance: Object, firmwareType: {required: true}})

const instanceData = ref({
  version: '',
  description: '',
  file: ''
})

function instanceCreate() {
  createInstance({firmwareType: props.firmwareType, data: instanceData.value})
      .then(() => {
        if (!createInstanceError.value) { successNotify("Firmware added successfully!") }
        else { errorNotify(createInstanceError.value) }
      })
      .finally(() => { emit('close') })
}
function instanceUpdate() {
  updateInstance({firmwareType: props.firmwareType, data: instanceData.value, id: props.instance.id})
      .then(() => {
        if (!updateInstanceError.value) { successNotify("Firmware updated successfully!") }
        else {errorNotify(updateInstanceError.value)}
      })
      .finally(() => { emit('close') })
}
function confirm() {
  if (props.instance) { instanceUpdate() } else { instanceCreate() }
}
function appendFile (name, files) {
    instanceData.value.file = files[0]
}
onMounted(() => {
  if (props.instance) {instanceData.value = props.instance}
})
</script>

<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')" >
    <template v-if="instance" v-slot:title>Edit Firmware</template>
    <template v-else v-slot:title>Add {{ firmwareType }} Firmware</template>
    <div class="modal__content">

      <div class="pb-3 form-floating">
        <input :disabled="instance" class="form-control" type="text" placeholder="Name" v-model="instanceData.version">
        <label>Version</label>
      </div>

      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="Name" v-model="instanceData.description">
        <label>Description</label>
      </div>

        <div>
          <input class="pb-3 form-floating"
            type="file"
            @change="appendFile($event.target.name, $event.target.files)"
          />
        </div>

    </div>

  </main-modal>
</template>

<style scoped>
  img {
    height: 24px;
    padding: 0 8px 0 8px;
  }
  button, button:hover {
    color: #004BE0;
    background: #DBE7FF;
  }
</style>
