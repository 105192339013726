/* eslint-disable */
import session from './session';

export default {
    getLocationsStatistic() {
        return session.get(`/api/v1/statistic/locations`);
    },
    getEmployeesStatistic() {
        return session.get(`/api/v1/statistic/employees`);
    },
    getPrepaidAccountsStatistic() {
        return session.get(`/api/v1/statistic/prepaid_accounts`);
    },
    getAppCustomerStatistic() {
        return session.get(`/api/v1/statistic/customers`);
    },
    getLockersStatistic() {
        return session.get(`/api/v1/statistic/lockers`);
    },
    getStorageParcelsStatistic() {
        return session.get(`/api/v1/statistic/storage`);
    },
    getExpectedParcelsStatistic() {
        return session.get(`/api/v1/statistic/expected`);
    },
    getOccupancyStatistic() {
        return session.get(`/api/v1/statistic/occupancy`);
    },
};
