<script setup>
import {useStore} from "vuex";
import {useLoading} from 'vue-loading-overlay'
import {computed, onMounted, onUnmounted, ref} from "vue";
import StorageInfoPanel from "@/components/panels/StorageInfoPanel.vue";
import ParcelsStorageTable from "@/components/tables/parcels/ParcelsStorageTable.vue";
import {errorNotify} from "@/mixins";
import PagePaginator from "@/components/layouts/PagePaginator.vue";
import Multiselect from "vue-multiselect";

const store = useStore()
const parcelList = computed(() => store.getters['parcel/expectedParcelList'])
const getListError = computed(() => store.getters['parcel/expectedParcelListError'])
const organizationList = computed(() => store.getters['organization/organizationList'])
const lockerList = computed(() => store.getters['locker/lockerList'])
const receiveStatistic = computed(() => store.getters['statistic/expectedParcelsStatistic'])
const getParcelList = (val) => store.dispatch('parcel/getExpectedParcelList', val)
const getOrganizationList = (val) => store.dispatch('organization/getOrganizationList', val)
const getLockerList = (val) => store.dispatch('locker/getLockerList', val)
const getExpectedParcelsStatistic = (val) => store.dispatch('statistic/getExpectedParcelsStatistic', val)

const fullFilter = ref('')
const organization = ref(null)
const device = ref(null)
const $loading = useLoading({})
const status = ref(null)
const statusList = ref([
    { filter: 'new', name: 'new' },
    { filter: 'locked', name: 'locked' },
    { filter: 'finished', name: 'finished' },
    { filter: 'expired', name: 'expired' },
    { filter: 'canceled', name: 'canceled' },
])
const timer = setInterval(update, 60000)

function getFilteredParcelList() {
  fullFilter.value = `?page=${page.value}`
  if (organization.value) {
    fullFilter.value += `&organization=${organization.value.id}`
  }
  if (device.value) {
    fullFilter.value += `&device=${device.value.sn}`
  }
  if (status.value) {
    fullFilter.value += `&status=${status.value.filter}`
  }
  getParcelList(fullFilter.value)
      .then(() => {if (getListError.value) {errorNotify(getListError.value)}})
  }

const page = ref(1)
function clickCallback(pageNum) {
  page.value = pageNum;
  getFilteredParcelList()
}

function orgFilter() {
  if (organization.value) {
    getLockerList(`?organization=${organization.value.id}`)
    device.value = null
  }
  getFilteredParcelList()
}

function update() {
  getExpectedParcelsStatistic()
  getOrganizationList('')
  getLockerList('')
  getFilteredParcelList()
}

onMounted(() => {
  const loader = $loading.show({})
  update()
  loader.hide()
})
onUnmounted(() => {
  clearInterval(timer)
})
</script>


<template>
  <section class="parcel-storage-page">
    <div class="row pb-3">
      <div class="col"><h1>Receive</h1></div>
      <div class="col text-end">
        <button type="button" disabled class="btn btn-primary">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8337 6.83166H6.83366V11.8317H5.16699V6.83166H0.166992V5.16499H5.16699V0.164993H6.83366V5.16499H11.8337V6.83166Z" fill="white"/>
          </svg>
          Add parcel
        </button>
      </div>
    </div>

    <StorageInfoPanel :data="receiveStatistic"/>
    <div class="row pt-5">
      <div class="col-4">
        <div class="row">
          <div class="col">
            <h4>List of parcels to receive</h4>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="row">
          <div class="col-4">
            <div class="pb-3 form-floating select-wrapper" v-if="statusList">
              <multiselect
                  placeholder="Status"
                  v-model="status"
                  :options="statusList"
                  label="name"
                  @close="getFilteredParcelList()"
              >
              </multiselect>
            </div>
          </div>
          <div class="col-4">
            <div class="pb-3 form-floating select-wrapper" v-if="organizationList">
              <multiselect
                  placeholder="Organization"
                  v-model="organization"
                  :options="organizationList.results"
                  label="name"
                  @close="orgFilter()"
              >
              </multiselect>
            </div>
          </div>
          <div class="col-4">
            <div class="pb-3 form-floating select-wrapper" v-if="lockerList">
              <multiselect
                  placeholder="Device"
                  v-model="device"
                  :options="lockerList.results"
                  label="name"
                  @close="getFilteredParcelList()"
              >
              </multiselect>
          </div>
        </div>
      </div>
      </div>
    </div>

    <ParcelsStorageTable :items-list="parcelList"/>
    <PagePaginator @change="clickCallback" :page-count="parcelList.total_pages" />
  </section>
</template>

