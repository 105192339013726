import account from '@/api/app_customer';

import {
    GET_APP_CUSTOMER_BEGIN,
    GET_APP_CUSTOMER_FAILURE,
    GET_APP_CUSTOMER_SUCCESS,
    GET_APP_CUSTOMER_LIST_BEGIN,
    GET_APP_CUSTOMER_LIST_FAILURE,
    GET_APP_CUSTOMER_LIST_SUCCESS,
    UPDATE_APP_CUSTOMER_BEGIN,
    UPDATE_APP_CUSTOMER_SUCCESS,
    UPDATE_APP_CUSTOMER_FAILURE,
    DELETE_APP_CUSTOMER_BEGIN,
    DELETE_APP_CUSTOMER_FAILURE,
    DELETE_APP_CUSTOMER_SUCCESS,
    CREATE_APP_CUSTOMER_BEGIN,
    CREATE_APP_CUSTOMER_SUCCESS,
    CREATE_APP_CUSTOMER_FAILURE
} from './types';


const initialState = {
    appCustomerList: false,
    appCustomerListError: false,
    appCustomerData: false,
    appCustomerDataError: false,
    appCustomerCreateError: false,
    appCustomerUpdateError: false,
    appCustomerDeleteError: false,
};

const getters = {
    appCustomerList:(state) => state.appCustomerList,
    appCustomerData:(state) => state.appCustomerData,
    appCustomerListError:(state) => state.appCustomerListError,
    appCustomerDataError:(state) => state.appCustomerDataError,
    appCustomerCreateError:(state) => state.appCustomerCreateError,
    appCustomerUpdateError:(state) => state.appCustomerUpdateError,
    appCustomerDeleteError:(state) => state.appCustomerDeleteError,
};

const actions = {
    getAppCustomer({ commit }, appCustomerId) {
        commit(GET_APP_CUSTOMER_BEGIN);
        return account.getAppCustomer(appCustomerId)
            .then((response) => commit(GET_APP_CUSTOMER_SUCCESS, response.data))
            .catch((error) => commit(GET_APP_CUSTOMER_FAILURE, error.response.data));
    },
    getAppCustomerList({ commit }, filter) {
        commit(GET_APP_CUSTOMER_LIST_BEGIN);
        return account.getAppCustomerList(filter)
            .then((response) => commit(GET_APP_CUSTOMER_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_APP_CUSTOMER_LIST_FAILURE, error.response.data));
    },
    createAppCustomer({ commit }, data) {
        commit(CREATE_APP_CUSTOMER_BEGIN);
        return account.createAppCustomer(data)
            .then(() => commit(CREATE_APP_CUSTOMER_SUCCESS))
            .catch((error) => commit(CREATE_APP_CUSTOMER_FAILURE, error.response.data));
    },
    updateAppCustomer({ commit }, { id, data }) {
        commit(UPDATE_APP_CUSTOMER_BEGIN);
        return account.updateAppCustomer(id, data)
            .then(() => commit(UPDATE_APP_CUSTOMER_SUCCESS))
            .catch((error) => commit(UPDATE_APP_CUSTOMER_FAILURE, error.response.data));
    },
    deleteAppCustomer({ commit }, logId) {
        commit(DELETE_APP_CUSTOMER_BEGIN);
        return account.deleteAppCustomer(logId)
            .then(() => commit(DELETE_APP_CUSTOMER_SUCCESS))
            .catch((error) => commit(DELETE_APP_CUSTOMER_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_APP_CUSTOMER_BEGIN](state) {
        state.appCustomerDataError = false;
    },
    [GET_APP_CUSTOMER_FAILURE](state, error) {
        state.appCustomerData = false;
        state.appCustomerDataError = error;
    },
    [GET_APP_CUSTOMER_SUCCESS](state, data) {
        state.appCustomerData = data;
        state.appCustomerDataError = false;
    },
    [GET_APP_CUSTOMER_LIST_BEGIN](state) {
        state.appCustomerListError = false;
    },
    [GET_APP_CUSTOMER_LIST_FAILURE](state, error) {
        state.appCustomerList = false;
        state.appCustomerListError = error;
    },
    [GET_APP_CUSTOMER_LIST_SUCCESS](state, data) {
        state.appCustomerList = data;
        state.appCustomerListError = false;
    },
    [CREATE_APP_CUSTOMER_BEGIN](state) {
        state.appCustomerCreateError = false;
    },
    [CREATE_APP_CUSTOMER_SUCCESS](state) {
        state.appCustomerCreateError = false;
    },
    [CREATE_APP_CUSTOMER_FAILURE](state, error) {
        state.appCustomerCreateError = error;
    },
    [UPDATE_APP_CUSTOMER_BEGIN](state) {
        state.appCustomerUpdateError = false;
    },
    [UPDATE_APP_CUSTOMER_FAILURE](state, error) {
        state.appCustomerUpdateError = error;
    },
    [UPDATE_APP_CUSTOMER_SUCCESS](state) {
        state.appCustomerUpdateError = false;
    },
    [DELETE_APP_CUSTOMER_BEGIN](state) {
        state.appCustomerDataError = false;
    },
    [DELETE_APP_CUSTOMER_FAILURE](state, error) {
        state.appCustomerDataError = error;
    },
    [DELETE_APP_CUSTOMER_SUCCESS](state) {
        state.appCustomerDataError = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
