import user from '../api/user';

import {
    GET_USER_LIST_BEGIN,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAILURE,
    GET_USER_BEGIN,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    GET_CURRENT_USER_BEGIN,
    GET_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_FAILURE,
    CREATE_USER_BEGIN,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    UPDATE_USER_DATA_BEGIN,
    UPDATE_USER_DATA_SUCCESS,
    UPDATE_USER_DATA_FAILURE,
    DELETE_USER_BEGIN,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
} from './types';

const initialState = {
    getUserError: false,
    getCurrentUserError: false,
    createUserError: false,
    updateUserError: false,
    deleteUserError: false,
    listUserError: false,
    currentUserData: {},
    userData: {},
    userList: {},
};

const getters = {
    currentUserData:(state) => state.currentUserData,
    userData:(state) => state.userData,
    userList:(state) => state.userList,
    getUserError:(state) => state.getUserError,
    getCurrentUserError:(state) => state.getCurrentUserError,
    createUserError:(state) => state.createUserError,
    updateUserError:(state) => state.updateUserError,
    deleteUserError:(state) => state.deleteUserError,
    listUserError:(state) => state.listUserError,
};

const actions = {
    getUser({ commit }, id) {
        commit(GET_USER_BEGIN);
        return user.getUser(id)
            .then((response) => commit(GET_USER_SUCCESS, response.data))
            .catch((error) => commit(GET_USER_FAILURE, error.response.data));
    },
    getUserList({ commit }, filter) {
        commit(GET_USER_LIST_BEGIN);
        return user.getUserList(filter)
            .then((response) => commit(GET_USER_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_USER_LIST_FAILURE, error.response.data));
    },
    getCurrentUser({ commit }) {
        commit(GET_CURRENT_USER_BEGIN);
        return user.getCurrentUser()
            .then((response) => commit(GET_CURRENT_USER_SUCCESS, response.data))
            .catch((error) => commit(GET_CURRENT_USER_FAILURE, error.response.data));
    },
    createUser({ commit }, data) {
        commit(CREATE_USER_BEGIN);
        return user.createUser(data)
            .then(() => commit(CREATE_USER_SUCCESS))
            .catch((error) => commit(CREATE_USER_FAILURE, error.response.data));
    },
    updateUserData({ commit }, { id, data }) {
        commit(UPDATE_USER_DATA_BEGIN);
        return user.updateUserData(id, data)
            .then(() => commit(UPDATE_USER_DATA_SUCCESS))
            .catch((error) => commit(UPDATE_USER_DATA_FAILURE, error.response.data));
    },
    deleteUser({ commit }, userId) {
        commit(DELETE_USER_BEGIN);
        return user.deleteUser(userId)
            .then(() => commit(DELETE_USER_SUCCESS))
            .catch((error) => commit(DELETE_USER_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_USER_LIST_BEGIN](state) {
        state.listUserError = false;
    },
    [GET_USER_LIST_SUCCESS](state, data) {
        state.userList = data;
        state.listUserError = false;
    },
    [GET_USER_LIST_FAILURE](state, data) {
        state.listUserError = data;
    },
    [GET_USER_BEGIN](state) {
        state.getUserError = false;
    },
    [GET_USER_SUCCESS](state, data) {
        state.userData = data;
        state.getUserError = false;
    },
    [GET_USER_FAILURE](state, data) {
        state.getUserError = data;
    },
    [GET_CURRENT_USER_BEGIN](state) {
        state.getCurrentUserError = false;
    },
    [GET_CURRENT_USER_SUCCESS](state, data) {
        state.currentUserData = data;
        state.getCurrentUserError = false;
    },
    [GET_CURRENT_USER_FAILURE](state, data) {
        state.getCurrentUserError = data;
    },
    [CREATE_USER_BEGIN](state) {
        state.createUserError = false;
    },
    [CREATE_USER_SUCCESS](state) {
        state.createUserError = false;
    },
    [CREATE_USER_FAILURE](state, data) {
        state.createUserError = data;
    },
    [UPDATE_USER_DATA_BEGIN](state) {
        state.updateUserError = false;
    },
    [UPDATE_USER_DATA_SUCCESS](state) {
        state.updateUserError = false;
    },
    [UPDATE_USER_DATA_FAILURE](state, data) {
        state.updateUserError = data;
    },
    [DELETE_USER_BEGIN](state) {
        state.deleteUserError = false;
    },
    [DELETE_USER_SUCCESS](state) {
        state.deleteUserError = false;
    },
    [DELETE_USER_FAILURE](state, data) {
        state.deleteUserError = data;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
