import { notify } from "@kyvg/vue3-notification"
import moment from "moment";

export function successNotify(text) {
    notify({
        title: 'Success:',
        type: 'success',
        text: text,
    });
}

export function errorNotify(errorsList) {
    for (const [key, value] of Object.entries(errorsList)) {
        notify({
            title: key,
            type: 'error',
            text: value,
        });
    }
}

export function prettifyDateTime(time) {
    return moment(String(time)).format('DD MMMM YYYY hh:mm')
}

export function prettifyDate(time) {
    return moment(String(time)).format('DD MMMM YYYY')
}

export function prettifyDateTimeShort(time) {
    if (moment().diff(time, 'days') === 0) {
        return moment(String(time)).format('LT')
    }
    return moment(String(time)).format('ll || LT')
}

export function momentFromNow(time) {
    return moment(time).fromNow();
}


// TODO: refactoring needed!
export function getFullUrl(url) {
    if (process.env.VUE_APP_API_BASE_URL) {
        return process.env.VUE_APP_API_BASE_URL;
    } else if (window.origin.includes('dev')) {
        return 'https://dev.api-lockerbot.com' + url
    } else {
        return 'https://api-lockerbot.com' + url
    }
}

export function copyToClipBoard(textToCopy) {
    navigator.clipboard.writeText(textToCopy)
}