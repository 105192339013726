import { createApp } from 'vue'
import App from './App.vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import router from "@/router";
import store from '@/store';
import '@/assets/style/app.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { vfmPlugin } from 'vue-final-modal'
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Notifications from '@kyvg/vue3-notification'
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

const app = createApp(App)
app.use(router)
app.use(store)
app.use(LoadingPlugin)
app.use(BootstrapVue3)
app.use(vfmPlugin)
app.use(Notifications)
app.use(VCalendar, {})
app.mount('#app')



