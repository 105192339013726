<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted, ref} from "vue"
import { successNotify, errorNotify } from '@/mixins.js'
import MainModal from "@/components/layouts/MainModal"
import Multiselect from 'vue-multiselect'

const store = useStore()
const createInstanceError = computed(() => store.getters['city/createCityError'])
const updateInstanceError = computed(() => store.getters['city/updateCityError'])
const countryList = computed(() => store.getters['country/countryList'])
const createInstance = (val) => store.dispatch('city/createCity', val)
const updateInstance = (val) => store.dispatch('city/updateCity', val)
const getCountryList = (val) => store.dispatch('country/getCountryList', val)

const emit = defineEmits(['update', 'close'])
const props = defineProps({instance: Object})

const country = ref('')
const instanceData = {
  name: '',
  code: '',
}

function instanceCreate() {
  instanceData.country = country.value.id
  createInstance(instanceData)
      .then(() => {
        if (!createInstanceError.value) { successNotify("City added successfully!") }
        else { errorNotify(createInstanceError.value) }
      })
      .finally(() => { emit('close') })
}

function instanceUpdate() {
  let newData = {data: instanceData, id: props.instance.id }
  newData.data.country = country.value.id
  updateInstance(newData)
      .then(() => {
        if (!updateInstanceError.value) { successNotify("City updated successfully!") }
        else {errorNotify(updateInstanceError.value)}
      })
      .finally(() => { emit('close') })
}

function confirm() {
  if (props.instance) { instanceUpdate() } else { instanceCreate() }
}

onMounted(() => {
  getCountryList('')
  if (props.instance) {
    country.value = props.instance.country
    for (const [key, value] of Object.entries(props.instance)) {
        instanceData[`${key}`] = value;
      }
  }})
</script>

<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')" >
    <template v-if="instance" v-slot:title>Edit City</template>
    <template v-else v-slot:title>Add City</template>
    <div class="modal__content">

      <div class="pb-3 form-floating select-wrapper" v-if="countryList">
        <multiselect
            selectLabel=""
            deselectLabel=""
            placeholder="Change the country"
            v-model="country"
            :options="countryList.results"
            label="name"
        >
        </multiselect>
      </div>
      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="City name" v-model="instanceData.name">
        <label>Name</label>
      </div>
      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="City code" v-model="instanceData.code">
        <label>Code</label>
      </div>
    </div>
  </main-modal>
</template>

