// ------------------------------- USER ----------------------------- //
// GET USER LIST
export const GET_USER_LIST_BEGIN = 'GET_USER_LIST_BEGIN';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';

// GET USER
export const GET_USER_BEGIN = 'GET_USER_BEGIN';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

// GET USER DETAIL
export const GET_CURRENT_USER_BEGIN = 'GET_CURRENT_USER_BEGIN';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';

// UPDATE USER
export const UPDATE_USER_DATA_BEGIN = 'UPDATE_USER_DATA_BEGIN';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const UPDATE_USER_DATA_FAILURE = 'UPDATE_USER_DATA_FAILURE';

// CREATE USER
export const CREATE_USER_BEGIN = 'CREATE_USER_BEGIN';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

// DELETE USER
export const DELETE_USER_BEGIN = 'DELETE_USER_BEGIN';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

// USER AUTH
export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const LOGOUT = 'LOGOUT';

// USER CHANGE PASSWORD
export const PASSWORD_EMAIL_BEGIN = 'PASSWORD_EMAIL_BEGIN';
export const PASSWORD_EMAIL_CLEAR = 'PASSWORD_EMAIL_CLEAR';
export const PASSWORD_EMAIL_FAILURE = 'PASSWORD_EMAIL_FAILURE';
export const PASSWORD_EMAIL_SUCCESS = 'PASSWORD_EMAIL_SUCCESS';
export const PASSWORD_RESET_BEGIN = 'PASSWORD_RESET_BEGIN';
export const PASSWORD_RESET_CLEAR = 'PASSWORD_RESET_CLEAR';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_CHANGE_BEGIN = 'PASSWORD_CHANGE_BEGIN';
export const PASSWORD_CHANGE_CLEAR = 'PASSWORD_CHANGE_CLEAR';
export const PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';


// ------------------------------- CONTROL_UNIT ----------------------------- //
// GET CONTROL_UNIT LIST
export const GET_CONTROL_UNIT_LIST_BEGIN = 'GET_CONTROL_UNIT_LIST_BEGIN';
export const GET_CONTROL_UNIT_LIST_SUCCESS = 'GET_CONTROL_UNIT_LIST_SUCCESS';
export const GET_CONTROL_UNIT_LIST_FAILURE = 'GET_CONTROL_UNIT_LIST_FAILURE';

// GET CONTROL_UNIT
export const GET_CONTROL_UNIT_BEGIN = 'GET_CONTROL_UNIT_BEGIN';
export const GET_CONTROL_UNIT_SUCCESS = 'GET_CONTROL_UNIT_SUCCESS';
export const GET_CONTROL_UNIT_FAILURE = 'GET_CONTROL_UNIT_FAILURE';

// CREATE CONTROL_UNIT
export const CREATE_CONTROL_UNIT_BEGIN = 'CREATE_CONTROL_UNIT_BEGIN';
export const CREATE_CONTROL_UNIT_SUCCESS = 'CREATE_CONTROL_UNIT_SUCCESS';
export const CREATE_CONTROL_UNIT_FAILURE = 'CREATE_CONTROL_UNIT_FAILURE';

// UPDATE CONTROL_UNIT
export const UPDATE_CONTROL_UNIT_DATA_BEGIN = 'UPDATE_CONTROL_UNIT_DATA_BEGIN';
export const UPDATE_CONTROL_UNIT_DATA_SUCCESS = 'UPDATE_CONTROL_UNIT_DATA_SUCCESS';
export const UPDATE_CONTROL_UNIT_DATA_FAILURE = 'UPDATE_CONTROL_UNIT_DATA_FAILURE';

// DELETE CONTROL_UNIT
export const DELETE_CONTROL_UNIT_BEGIN = 'DELETE_CONTROL_UNIT_BEGIN';
export const DELETE_CONTROL_UNIT_SUCCESS = 'DELETE_CONTROL_UNIT_SUCCESS';
export const DELETE_CONTROL_UNIT_FAILURE = 'DELETE_CONTROL_UNIT_FAILURE';


// ------------------------------- LOCKER ----------------------------- //
// GET LOCKER
export const GET_LOCKER_BEGIN = 'GET_LOCKER_BEGIN';
export const GET_LOCKER_FAILURE = 'GET_LOCKER_FAILURE';
export const GET_LOCKER_SUCCESS = 'GET_LOCKER_SUCCESS';

// GET LOCKER LIST
export const GET_LOCKER_LIST_BEGIN = 'GET_LOCKER_LIST_BEGIN';
export const GET_LOCKER_LIST_FAILURE = 'GET_LOCKER_LIST_FAILURE';
export const GET_LOCKER_LIST_SUCCESS = 'GET_LOCKER_LIST_SUCCESS';

// CREATE NEW_KEY
export const CREATE_NEW_KEY_BEGIN = 'CREATE_NEW_KEY_BEGIN';
export const CREATE_NEW_KEY_SUCCESS = 'CREATE_NEW_KEY_SUCCESS';
export const CREATE_NEW_KEY_FAILURE = 'CREATE_NEW_KEY_FAILURE';

// CREATE LOCKER
export const CREATE_LOCKER_BEGIN = 'CREATE_LOCKER_BEGIN';
export const CREATE_LOCKER_SUCCESS = 'CREATE_LOCKER_SUCCESS';
export const CREATE_LOCKER_FAILURE = 'CREATE_LOCKER_FAILURE';

// UPDATE LOCKER
export const UPDATE_LOCKER_BEGIN = 'UPDATE_LOCKER_BEGIN';
export const UPDATE_LOCKER_SUCCESS = 'UPDATE_LOCKER_SUCCESS';
export const UPDATE_LOCKER_FAILURE = 'UPDATE_LOCKER_FAILURE';

// DELETE LOCKER
export const DELETE_LOCKER_BEGIN = 'DELETE_LOCKER_BEGIN';
export const DELETE_LOCKER_FAILURE = 'DELETE_LOCKER_FAILURE';
export const DELETE_LOCKER_SUCCESS = 'DELETE_LOCKER_SUCCESS';


// ------------------------------- MODEL ----------------------------- //
// GET MODEL
export const GET_MODEL_BEGIN = 'GET_MODEL_BEGIN';
export const GET_MODEL_FAILURE = 'GET_MODEL_FAILURE';
export const GET_MODEL_SUCCESS = 'GET_MODEL_SUCCESS';

// GET MODEL LIST
export const GET_MODEL_LIST_BEGIN = 'GET_MODEL_LIST_BEGIN';
export const GET_MODEL_LIST_FAILURE = 'GET_MODEL_LIST_FAILURE';
export const GET_MODEL_LIST_SUCCESS = 'GET_MODEL_LIST_SUCCESS';

// CREATE MODEL
export const CREATE_MODEL_BEGIN = 'CREATE_MODEL_BEGIN';
export const CREATE_MODEL_SUCCESS = 'CREATE_MODEL_SUCCESS';
export const CREATE_MODEL_FAILURE = 'CREATE_MODEL_FAILURE';

// UPDATE MODEL
export const UPDATE_MODEL_BEGIN = 'UPDATE_MODEL_BEGIN';
export const UPDATE_MODEL_SUCCESS = 'UPDATE_MODEL_SUCCESS';
export const UPDATE_MODEL_FAILURE = 'UPDATE_MODEL_FAILURE';

// DELETE MODEL
export const DELETE_MODEL_BEGIN = 'DELETE_MODEL_BEGIN';
export const DELETE_MODEL_FAILURE = 'DELETE_MODEL_FAILURE';
export const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS';


// ------------------------------- CELL ----------------------------- //
// GET CELL
export const GET_CELL_BEGIN = 'GET_CELL_BEGIN';
export const GET_CELL_FAILURE = 'GET_CELL_FAILURE';
export const GET_CELL_SUCCESS = 'GET_CELL_SUCCESS';

// GET CELL LIST
export const GET_CELL_LIST_BEGIN = 'GET_CELL_LIST_BEGIN';
export const GET_CELL_LIST_FAILURE = 'GET_CELL_LIST_FAILURE';
export const GET_CELL_LIST_SUCCESS = 'GET_CELL_LIST_SUCCESS';

// CREATE CELL
export const CREATE_CELL_BEGIN = 'CREATE_CELL_BEGIN';
export const CREATE_CELL_SUCCESS = 'CREATE_CELL_SUCCESS';
export const CREATE_CELL_FAILURE = 'CREATE_CELL_FAILURE';

// UPDATE CELL
export const UPDATE_CELL_BEGIN = 'UPDATE_CELL_BEGIN';
export const UPDATE_CELL_SUCCESS = 'UPDATE_CELL_SUCCESS';
export const UPDATE_CELL_FAILURE = 'UPDATE_CELL_FAILURE';

// DELETE CELL
export const DELETE_CELL_BEGIN = 'DELETE_CELL_BEGIN';
export const DELETE_CELL_FAILURE = 'DELETE_CELL_FAILURE';
export const DELETE_CELL_SUCCESS = 'DELETE_CELL_SUCCESS';


// ------------------------------- CELL_SIZE----------------------------- //
// GET CELL_SIZE
export const GET_CELL_SIZE_BEGIN = 'GET_CELL_SIZE_BEGIN';
export const GET_CELL_SIZE_FAILURE = 'GET_CELL_SIZE_FAILURE';
export const GET_CELL_SIZE_SUCCESS = 'GET_CELL_SIZE_SUCCESS';

// GET CELL_SIZE LIST
export const GET_CELL_SIZE_LIST_BEGIN = 'GET_CELL_SIZE_LIST_BEGIN';
export const GET_CELL_SIZE_LIST_FAILURE = 'GET_CELL_SIZE_LIST_FAILURE';
export const GET_CELL_SIZE_LIST_SUCCESS = 'GET_CELL_SIZE_LIST_SUCCESS';

// CREATE CELL_SIZE
export const CREATE_CELL_SIZE_BEGIN = 'CREATE_CELL_SIZE_BEGIN';
export const CREATE_CELL_SIZE_SUCCESS = 'CREATE_CELL_SIZE_SUCCESS';
export const CREATE_CELL_SIZE_FAILURE = 'CREATE_CELL_SIZE_FAILURE';

// UPDATE CELL_SIZE
export const UPDATE_CELL_SIZE_BEGIN = 'UPDATE_CELL_SIZE_BEGIN';
export const UPDATE_CELL_SIZE_SUCCESS = 'UPDATE_CELL_SIZE_SUCCESS';
export const UPDATE_CELL_SIZE_FAILURE = 'UPDATE_CELL_SIZE_FAILURE';

// DELETE CELL_SIZE
export const DELETE_CELL_SIZE_BEGIN = 'DELETE_CELL_SIZE_BEGIN';
export const DELETE_CELL_SIZE_FAILURE = 'DELETE_CELL_SIZE_FAILURE';
export const DELETE_CELL_SIZE_SUCCESS = 'DELETE_CELL_SIZE_SUCCESS';


// ------------------------------- LOG ----------------------------- //
// GET LOG
export const GET_LOG_BEGIN = 'GET_LOG_BEGIN';
export const GET_LOG_FAILURE = 'GET_LOG_FAILURE';
export const GET_LOG_SUCCESS = 'GET_LOG_SUCCESS';

// GET LOG LIST
export const GET_LOG_LIST_BEGIN = 'GET_LOG_LIST_BEGIN';
export const GET_LOG_LIST_FAILURE = 'GET_LOG_LIST_FAILURE';
export const GET_LOG_LIST_SUCCESS = 'GET_LOG_LIST_SUCCESS';

// DELETE LOG
export const DELETE_LOG_BEGIN = 'DELETE_LOG_BEGIN';
export const DELETE_LOG_FAILURE = 'DELETE_LOG_FAILURE';
export const DELETE_LOG_SUCCESS = 'DELETE_LOG_SUCCESS';


// ------------------------------- REGION ----------------------------- //
// GET REGION LIST
export const GET_REGION_LIST_BEGIN = 'GET_REGION_LIST_BEGIN';
export const GET_REGION_LIST_SUCCESS = 'GET_REGION_LIST_SUCCESS';
export const GET_REGION_LIST_FAILURE = 'GET_REGION_LIST_FAILURE';

// GET REGION
export const GET_REGION_BEGIN = 'GET_REGION_BEGIN';
export const GET_REGION_SUCCESS = 'GET_REGION_SUCCESS';
export const GET_REGION_FAILURE = 'GET_REGION_FAILURE';

// CREATE REGION
export const CREATE_REGION_BEGIN = 'CREATE_REGION_BEGIN';
export const CREATE_REGION_SUCCESS = 'CREATE_REGION_SUCCESS';
export const CREATE_REGION_FAILURE = 'CREATE_REGION_FAILURE';

// UPDATE REGION
export const UPDATE_REGION_DATA_BEGIN = 'UPDATE_REGION_DATA_BEGIN';
export const UPDATE_REGION_DATA_SUCCESS = 'UPDATE_REGION_DATA_SUCCESS';
export const UPDATE_REGION_DATA_FAILURE = 'UPDATE_REGION_DATA_FAILURE';

// DELETE REGION
export const DELETE_REGION_BEGIN = 'DELETE_REGION_BEGIN';
export const DELETE_REGION_SUCCESS = 'DELETE_REGION_SUCCESS';
export const DELETE_REGION_FAILURE = 'DELETE_REGION_FAILURE';


// ------------------------------- COUNTRY ----------------------------- //
// GET COUNTRY LIST
export const GET_COUNTRY_LIST_BEGIN = 'GET_COUNTRY_LIST_BEGIN';
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_FAILURE = 'GET_COUNTRY_LIST_FAILURE';

// GET COUNTRY
export const GET_COUNTRY_BEGIN = 'GET_COUNTRY_BEGIN';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE';

// CREATE COUNTRY
export const CREATE_COUNTRY_BEGIN = 'CREATE_COUNTRY_BEGIN';
export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS';
export const CREATE_COUNTRY_FAILURE = 'CREATE_COUNTRY_FAILURE';

// UPDATE COUNTRY
export const UPDATE_COUNTRY_DATA_BEGIN = 'UPDATE_COUNTRY_DATA_BEGIN';
export const UPDATE_COUNTRY_DATA_SUCCESS = 'UPDATE_COUNTRY_DATA_SUCCESS';
export const UPDATE_COUNTRY_DATA_FAILURE = 'UPDATE_COUNTRY_DATA_FAILURE';

// DELETE COUNTRY
export const DELETE_COUNTRY_BEGIN = 'DELETE_COUNTRY_BEGIN';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_FAILURE = 'DELETE_COUNTRY_FAILURE';


// ------------------------------- CITY ----------------------------- //
// GET CITY LIST
export const GET_CITY_LIST_BEGIN = 'GET_CITY_LIST_BEGIN';
export const GET_CITY_LIST_SUCCESS = 'GET_CITY_LIST_SUCCESS';
export const GET_CITY_LIST_FAILURE = 'GET_CITY_LIST_FAILURE';

// GET CITY
export const GET_CITY_BEGIN = 'GET_CITY_BEGIN';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_FAILURE = 'GET_CITY_FAILURE';

// CREATE CITY
export const CREATE_CITY_BEGIN = 'CREATE_CITY_BEGIN';
export const CREATE_CITY_SUCCESS = 'CREATE_CITY_SUCCESS';
export const CREATE_CITY_FAILURE = 'CREATE_CITY_FAILURE';

// UPDATE CITY
export const UPDATE_CITY_DATA_BEGIN = 'UPDATE_CITY_DATA_BEGIN';
export const UPDATE_CITY_DATA_SUCCESS = 'UPDATE_CITY_DATA_SUCCESS';
export const UPDATE_CITY_DATA_FAILURE = 'UPDATE_CITY_DATA_FAILURE';

// DELETE CITY
export const DELETE_CITY_BEGIN = 'DELETE_CITY_BEGIN';
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const DELETE_CITY_FAILURE = 'DELETE_CITY_FAILURE';


// ------------------------------- ADDRESS ----------------------------- //
// GET ADDRESS LIST
export const GET_ADDRESS_LIST_BEGIN = 'GET_ADDRESS_LIST_BEGIN';
export const GET_ADDRESS_LIST_SUCCESS = 'GET_ADDRESS_LIST_SUCCESS';
export const GET_ADDRESS_LIST_FAILURE = 'GET_ADDRESS_LIST_FAILURE';

// GET ADDRESS
export const GET_ADDRESS_BEGIN = 'GET_ADDRESS_BEGIN';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAILURE = 'GET_ADDRESS_FAILURE';

// CREATE ADDRESS
export const CREATE_ADDRESS_BEGIN = 'CREATE_ADDRESS_BEGIN';
export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_FAILURE = 'CREATE_ADDRESS_FAILURE';

// UPDATE ADDRESS
export const UPDATE_ADDRESS_DATA_BEGIN = 'UPDATE_ADDRESS_DATA_BEGIN';
export const UPDATE_ADDRESS_DATA_SUCCESS = 'UPDATE_ADDRESS_DATA_SUCCESS';
export const UPDATE_ADDRESS_DATA_FAILURE = 'UPDATE_ADDRESS_DATA_FAILURE';

// DELETE ADDRESS
export const DELETE_ADDRESS_BEGIN = 'DELETE_ADDRESS_BEGIN';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAILURE = 'DELETE_ADDRESS_FAILURE';


// ------------------------------- ORGANIZATION ----------------------------- //
// GET ORGANIZATION LIST
export const GET_ORGANIZATION_LIST_BEGIN = 'GET_ORGANIZATION_LIST_BEGIN';
export const GET_ORGANIZATION_LIST_SUCCESS = 'GET_ORGANIZATION_LIST_SUCCESS';
export const GET_ORGANIZATION_LIST_FAILURE = 'GET_ORGANIZATION_LIST_FAILURE';

// GET ORGANIZATION
export const GET_ORGANIZATION_BEGIN = 'GET_ORGANIZATION_BEGIN';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE';

// CREATE ORGANIZATION
export const CREATE_ORGANIZATION_BEGIN = 'CREATE_ORGANIZATION_BEGIN';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';

// UPDATE ORGANIZATION
export const UPDATE_ORGANIZATION_DATA_BEGIN = 'UPDATE_ORGANIZATION_DATA_BEGIN';
export const UPDATE_ORGANIZATION_DATA_SUCCESS = 'UPDATE_ORGANIZATION_DATA_SUCCESS';
export const UPDATE_ORGANIZATION_DATA_FAILURE = 'UPDATE_ORGANIZATION_DATA_FAILURE';

// DELETE ORGANIZATION
export const DELETE_ORGANIZATION_BEGIN = 'DELETE_ORGANIZATION_BEGIN';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_FAILURE = 'DELETE_ORGANIZATION_FAILURE';


// ------------------------------- EXPECTED PARCEL ----------------------------- //
// GET EXPECTED PARCEL
export const GET_EXPECTED_PARCEL_BEGIN = 'GET_EXPECTED_PARCEL_BEGIN';
export const GET_EXPECTED_PARCEL_FAILURE = 'GET_EXPECTED_PARCEL_FAILURE';
export const GET_EXPECTED_PARCEL_SUCCESS = 'GET_EXPECTED_PARCEL_SUCCESS';

// GET EXPECTED PARCEL LIST
export const GET_EXPECTED_PARCEL_LIST_BEGIN = 'GET_EXPECTED_PARCEL_LIST_BEGIN';
export const GET_EXPECTED_PARCEL_LIST_FAILURE = 'GET_EXPECTED_PARCEL_LIST_FAILURE';
export const GET_EXPECTED_PARCEL_LIST_SUCCESS = 'GET_EXPECTED_PARCEL_LIST_SUCCESS';


// ------------------------------- STORAGE PARCEL ----------------------------- //
// GET STORAGE PARCEL
export const GET_STORAGE_PARCEL_BEGIN = 'GET_STORAGE_PARCEL_BEGIN';
export const GET_STORAGE_PARCEL_FAILURE = 'GET_STORAGE_PARCEL_FAILURE';
export const GET_STORAGE_PARCEL_SUCCESS = 'GET_STORAGE_PARCEL_SUCCESS';

// GET STORAGE PARCEL
export const FINISH_STORAGE_PARCEL_BEGIN = 'FINISH_STORAGE_PARCEL_BEGIN';
export const FINISH_STORAGE_PARCEL_FAILURE = 'FINISH_STORAGE_PARCEL_FAILURE';
export const FINISH_STORAGE_PARCEL_SUCCESS = 'FINISH_STORAGE_PARCEL_SUCCESS';

// GET STORAGE PARCEL LIST
export const GET_STORAGE_PARCEL_LIST_BEGIN = 'GET_STORAGE_PARCEL_LIST_BEGIN';
export const GET_STORAGE_PARCEL_LIST_FAILURE = 'GET_STORAGE_PARCEL_LIST_FAILURE';
export const GET_STORAGE_PARCEL_LIST_SUCCESS = 'GET_STORAGE_PARCEL_LIST_SUCCESS';


// ------------------------------- PREPAID_ACCOUNT ----------------------------- //
// GET PREPAID_ACCOUNT LIST
export const GET_PREPAID_ACCOUNT_LIST_BEGIN = 'GET_PREPAID_ACCOUNT_LIST_BEGIN';
export const GET_PREPAID_ACCOUNT_LIST_SUCCESS = 'GET_PREPAID_ACCOUNT_LIST_SUCCESS';
export const GET_PREPAID_ACCOUNT_LIST_FAILURE = 'GET_PREPAID_ACCOUNT_LIST_FAILURE';

// GET PREPAID_ACCOUNT
export const GET_PREPAID_ACCOUNT_BEGIN = 'GET_PREPAID_ACCOUNT_BEGIN';
export const GET_PREPAID_ACCOUNT_SUCCESS = 'GET_PREPAID_ACCOUNT_SUCCESS';
export const GET_PREPAID_ACCOUNT_FAILURE = 'GET_PREPAID_ACCOUNT_FAILURE';

// CREATE PREPAID_ACCOUNT
export const CREATE_PREPAID_ACCOUNT_BEGIN = 'CREATE_PREPAID_ACCOUNT_BEGIN';
export const CREATE_PREPAID_ACCOUNT_SUCCESS = 'CREATE_PREPAID_ACCOUNT_SUCCESS';
export const CREATE_PREPAID_ACCOUNT_FAILURE = 'CREATE_PREPAID_ACCOUNT_FAILURE';

// CREATE PREPAID_ACCOUNT CODES
export const CREATE_PREPAID_ACCOUNT_CODES_BEGIN = 'CREATE_PREPAID_ACCOUNT_CODES_BEGIN';
export const CREATE_PREPAID_ACCOUNT_CODES_SUCCESS = 'CREATE_PREPAID_ACCOUNT_CODES_SUCCESS';
export const CREATE_PREPAID_ACCOUNT_CODES_FAILURE = 'CREATE_PREPAID_ACCOUNT_CODES_FAILURE';

// UPDATE PREPAID_ACCOUNT
export const UPDATE_PREPAID_ACCOUNT_BEGIN = 'UPDATE_PREPAID_ACCOUNT_BEGIN';
export const UPDATE_PREPAID_ACCOUNT_SUCCESS = 'UPDATE_PREPAID_ACCOUNT_SUCCESS';
export const UPDATE_PREPAID_ACCOUNT_FAILURE = 'UPDATE_PREPAID_ACCOUNT_FAILURE';

// DELETE PREPAID_ACCOUNT
export const DELETE_PREPAID_ACCOUNT_BEGIN = 'DELETE_PREPAID_ACCOUNT_BEGIN';
export const DELETE_PREPAID_ACCOUNT_SUCCESS = 'DELETE_PREPAID_ACCOUNT_SUCCESS';
export const DELETE_PREPAID_ACCOUNT_FAILURE = 'DELETE_PREPAID_ACCOUNT_FAILURE';


// ------------------------------- APP_CUSTOMER ----------------------------- //
// GET APP_CUSTOMER LIST
export const GET_APP_CUSTOMER_LIST_BEGIN = 'GET_APP_CUSTOMER_LIST_BEGIN';
export const GET_APP_CUSTOMER_LIST_SUCCESS = 'GET_APP_CUSTOMER_LIST_SUCCESS';
export const GET_APP_CUSTOMER_LIST_FAILURE = 'GET_APP_CUSTOMER_LIST_FAILURE';

// GET APP_CUSTOMER
export const GET_APP_CUSTOMER_BEGIN = 'GET_APP_CUSTOMER_BEGIN';
export const GET_APP_CUSTOMER_SUCCESS = 'GET_APP_CUSTOMER_SUCCESS';
export const GET_APP_CUSTOMER_FAILURE = 'GET_APP_CUSTOMER_FAILURE';

// CREATE APP_CUSTOMER
export const CREATE_APP_CUSTOMER_BEGIN = 'CREATE_APP_CUSTOMER_BEGIN';
export const CREATE_APP_CUSTOMER_SUCCESS = 'CREATE_APP_CUSTOMER_SUCCESS';
export const CREATE_APP_CUSTOMER_FAILURE = 'CREATE_APP_CUSTOMER_FAILURE';

// UPDATE APP_CUSTOMER
export const UPDATE_APP_CUSTOMER_BEGIN = 'UPDATE_APP_CUSTOMER_BEGIN';
export const UPDATE_APP_CUSTOMER_SUCCESS = 'UPDATE_APP_CUSTOMER_SUCCESS';
export const UPDATE_APP_CUSTOMER_FAILURE = 'UPDATE_APP_CUSTOMER_FAILURE';

// DELETE APP_CUSTOMER
export const DELETE_APP_CUSTOMER_BEGIN = 'DELETE_APP_CUSTOMER_BEGIN';
export const DELETE_APP_CUSTOMER_SUCCESS = 'DELETE_APP_CUSTOMER_SUCCESS';
export const DELETE_APP_CUSTOMER_FAILURE = 'DELETE_APP_CUSTOMER_FAILURE';


// ------------------------------- STATISTIC ----------------------------- //
// GET LOCATIONS STATISTIC
export const GET_LOCATIONS_STATISTIC_BEGIN = 'GET_LOCATIONS_STATISTIC_BEGIN';
export const GET_LOCATIONS_STATISTIC_SUCCESS = 'GET_LOCATIONS_STATISTIC_SUCCESS';
export const GET_LOCATIONS_STATISTIC_FAILURE = 'GET_LOCATIONS_STATISTIC_FAILURE';

// GET EMPLOYEES STATISTIC
export const GET_EMPLOYEES_STATISTIC_BEGIN = 'GET_EMPLOYEES_STATISTIC_BEGIN';
export const GET_EMPLOYEES_STATISTIC_SUCCESS = 'GET_EMPLOYEES_STATISTIC_SUCCESS';
export const GET_EMPLOYEES_STATISTIC_FAILURE = 'GET_EMPLOYEES_STATISTIC_FAILURE';

// GET APP_CUSTOMER STATISTIC
export const GET_APP_CUSTOMER_STATISTIC_BEGIN = 'GET_APP_CUSTOMER_STATISTIC_BEGIN';
export const GET_APP_CUSTOMER_STATISTIC_SUCCESS = 'GET_APP_CUSTOMER_STATISTIC_SUCCESS';
export const GET_APP_CUSTOMER_STATISTIC_FAILURE = 'GET_APP_CUSTOMER_STATISTIC_FAILURE';

// GET PREPAID_ACCOUNTS STATISTIC
export const GET_PREPAID_ACCOUNTS_STATISTIC_BEGIN = 'GET_PREPAID_ACCOUNTS_STATISTIC_BEGIN';
export const GET_PREPAID_ACCOUNTS_STATISTIC_SUCCESS = 'GET_PREPAID_ACCOUNTS_STATISTIC_SUCCESS';
export const GET_PREPAID_ACCOUNTS_STATISTIC_FAILURE = 'GET_PREPAID_ACCOUNTS_STATISTIC_FAILURE';

// GET LOCKERS STATISTIC
export const GET_LOCKERS_STATISTIC_BEGIN = 'GET_LOCKERS_STATISTIC_BEGIN';
export const GET_LOCKERS_STATISTIC_SUCCESS = 'GET_LOCKERS_STATISTIC_SUCCESS';
export const GET_LOCKERS_STATISTIC_FAILURE = 'GET_LOCKERS_STATISTIC_FAILURE';

// GET STORAGE PARCELS STATISTIC
export const GET_STORAGE_PARCELS_STATISTIC_BEGIN = 'GET_STORAGE_PARCELS_STATISTIC_BEGIN';
export const GET_STORAGE_PARCELS_STATISTIC_SUCCESS = 'GET_STORAGE_PARCELS_STATISTIC_SUCCESS';
export const GET_STORAGE_PARCELS_STATISTIC_FAILURE = 'GET_STORAGE_PARCELS_STATISTIC_FAILURE';

// GET EXPECTED PARCELS STATISTIC
export const GET_EXPECTED_PARCELS_STATISTIC_BEGIN = 'GET_EXPECTED_PARCELS_STATISTIC_BEGIN';
export const GET_EXPECTED_PARCELS_STATISTIC_SUCCESS = 'GET_EXPECTED_PARCELS_STATISTIC_SUCCESS';
export const GET_EXPECTED_PARCELS_STATISTIC_FAILURE = 'GET_EXPECTED_PARCELS_STATISTIC_FAILURE';

// GET OCCUPANCY STATISTIC
export const GET_OCCUPANCY_STATISTIC_BEGIN = 'GET_OCCUPANCY_STATISTIC_BEGIN';
export const GET_OCCUPANCY_STATISTIC_SUCCESS = 'GET_OCCUPANCY_STATISTIC_SUCCESS';
export const GET_OCCUPANCY_STATISTIC_FAILURE = 'GET_OCCUPANCY_STATISTIC_FAILURE';

// ------------------------------- FIRMWARE ----------------------------- //
// GET FIRMWARE LIST
export const GET_HEAD_FIRMWARE_LIST_BEGIN = 'GET_HEAD_FIRMWARE_LIST_BEGIN';
export const GET_HEAD_FIRMWARE_LIST_SUCCESS = 'GET_HEAD_FIRMWARE_LIST_SUCCESS';
export const GET_HEAD_FIRMWARE_LIST_FAILURE = 'GET_HEAD_FIRMWARE_LIST_FAILURE';

// GET FIRMWARE LIST
export const GET_CELL_FIRMWARE_LIST_BEGIN = 'GET_CELL_FIRMWARE_LIST_BEGIN';
export const GET_CELL_FIRMWARE_LIST_SUCCESS = 'GET_CELL_FIRMWARE_LIST_SUCCESS';
export const GET_CELL_FIRMWARE_LIST_FAILURE = 'GET_CELL_FIRMWARE_LIST_FAILURE';

// GET FIRMWARE
export const GET_FIRMWARE_BEGIN = 'GET_FIRMWARE_BEGIN';
export const GET_FIRMWARE_SUCCESS = 'GET_FIRMWARE_SUCCESS';
export const GET_FIRMWARE_FAILURE = 'GET_FIRMWARE_FAILURE';

// CREATE FIRMWARE
export const CREATE_FIRMWARE_BEGIN = 'CREATE_FIRMWARE_BEGIN';
export const CREATE_FIRMWARE_SUCCESS = 'CREATE_FIRMWARE_SUCCESS';
export const CREATE_FIRMWARE_FAILURE = 'CREATE_FIRMWARE_FAILURE';

// UPDATE FIRMWARE
export const UPDATE_FIRMWARE_BEGIN = 'UPDATE_FIRMWARE_BEGIN';
export const UPDATE_FIRMWARE_SUCCESS = 'UPDATE_FIRMWARE_SUCCESS';
export const UPDATE_FIRMWARE_FAILURE = 'UPDATE_FIRMWARE_FAILURE';

// DELETE FIRMWARE
export const DELETE_FIRMWARE_BEGIN = 'DELETE_FIRMWARE_BEGIN';
export const DELETE_FIRMWARE_SUCCESS = 'DELETE_FIRMWARE_SUCCESS';
export const DELETE_FIRMWARE_FAILURE = 'DELETE_FIRMWARE_FAILURE';