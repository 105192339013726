<script setup>
import {defineEmits, defineProps, ref} from "vue"
import MainModal from "@/components/layouts/MainModal"


const emit = defineEmits(['setCell', 'close'])
const props = defineProps({cell: Object})

const instanceData = ref({
  number: props.cell.number,
  address: props.cell.address,
  cleaning_required: props.cell.cleaning_required,
  is_broken: props.cell.is_broken,
  is_active: props.cell.is_active,
})

function confirm() {
  emit('setCell', instanceData.value)
  emit('close')
}

</script>


<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')">
    <template v-slot:title>Cell {{instanceData.number}}</template>
    <div class="modal__content">

      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="Serial Number" v-model="instanceData.address">
        <label>Board s/n(address)</label>
      </div>

      <div class="row py-3">
        <div class="col-6">
          <input v-model="instanceData.cleaning_required" class="form-check-input" type="checkbox" value="" id="cleaningRequired">
          <label class="form-check-label px-2" for="cleaningRequired">
            Cleaning required
          </label>
        </div>
        <div class="col-3">
          <input v-model="instanceData.is_broken" class="form-check-input" type="checkbox" value="" id="isBroken">
          <label class="form-check-label px-2" for="isBroken">
            Broken
          </label>
        </div>
        <div class="col-3">
          <input v-model="instanceData.is_active" class="form-check-input" type="checkbox" value="" id="isActive">
          <label class="form-check-label px-2" for="isActive">
            Active
          </label>
        </div>
      </div>

    </div>
  </main-modal>
</template>
