<script setup>
import {useStore} from "vuex";
import {useLoading} from 'vue-loading-overlay'
import {computed, onMounted, onUnmounted, ref} from "vue";
import DevicesTable from "@/components/tables/device/DevicesTable.vue";
import PagePaginator from "@/components/layouts/PagePaginator.vue";
import LockersInfoPanel from "@/components/panels/LockersInfoPanel.vue";
import EmptyPage from "@/components/layouts/EmptyPage.vue";
import Multiselect from "vue-multiselect";

const fullFilter = ref('')
const timer = setInterval(update, 60000)

const store = useStore()
const lockerList = computed(() => store.getters['locker/lockerList'])
const modelList = computed(() => store.getters['model/modelList'])
const organizationList = computed(() => store.getters['organization/organizationList'])
const lockersStatistic = computed(() => store.getters['statistic/lockersStatistic'])
const getLockerList = (val) => store.dispatch('locker/getLockerList', val)
const getLockersStatistic = (val) => store.dispatch('statistic/getLockersStatistic', val)
const getOrganizationList = (val) => store.dispatch('organization/getOrganizationList', val)
const getModelList = (val) => store.dispatch('model/getModelList', val)

const $loading = useLoading({})
const organization = ref(null)
const model = ref(null)
const searchName = ref(null)

function getFilteredLockerList() {
  fullFilter.value = `?page=${page.value}`
  if (organization.value) {
    fullFilter.value += `&organization=${organization.value.id}`
  }
  if (model.value) {
    fullFilter.value += `&model=${model.value.code}`
  }
  if (searchName.value) {
    fullFilter.value += `&name=${searchName.value}`
  }
  getLockerList(fullFilter.value)
  }

function update() {
  getLockersStatistic()
  getOrganizationList('')
  getModelList('')
  getFilteredLockerList()
}

const page = ref(1)
function clickCallback(pageNum) {
  page.value = pageNum;
  getFilteredLockerList()
}

onMounted(() => {
  const loader = $loading.show({})
  update()
  loader.hide()
})
onUnmounted(() => {
  clearInterval(timer)
})
</script>


<template>
  <section class="organizations-page">
    <div class="row pb-3">
      <div class="col"><h1>Lockers</h1></div>
      <div class="col text-end">
        <router-link to="/lockers/new">
          <button type="button" class="btn btn-primary">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8337 6.83166H6.83366V11.8317H5.16699V6.83166H0.166992V5.16499H5.16699V0.164993H6.83366V5.16499H11.8337V6.83166Z" fill="white"/>
            </svg>
            Add Locker
          </button>
        </router-link>
      </div>
    </div>
    <LockersInfoPanel :data="lockersStatistic"/>
    <div class="row pt-5">
        <div class="col-6">
          <div class="row">
            <div class="col">
              <h4>Lockers list</h4>
            </div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Search by name or s/n"
                     v-model="searchName"  @input="getFilteredLockerList()">
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <div class="pb-3 form-floating select-wrapper" v-if="organizationList">
                <multiselect
                    placeholder="Organization"
                    v-model="organization"
                    :options="organizationList.results"
                    label="name"
                    @close="getFilteredLockerList()"
                >
                </multiselect>
            </div>
            </div>
            <div class="col-6">
              <div class="pb-3 form-floating select-wrapper" v-if="modelList">
                <multiselect
                    placeholder="Model"
                    v-model="model"
                    :options="modelList.results"
                    label="name"
                    @close="getFilteredLockerList()"
                >
                </multiselect>
            </div>
            </div>
          </div>

        </div>
      </div>
    <div v-if="lockerList.count">
      <DevicesTable :locker-list="lockerList" @update="update"/>
      <PagePaginator @change="clickCallback" :page-count="lockerList.total_pages" />
    </div>
    <div v-else><EmptyPage/></div>

  </section>
</template>
