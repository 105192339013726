/* eslint-disable */
import session from './session';

export default {
    getOrganizationList(filter) {
        return session.get(`/api/v1/organization/${filter}`);
    },
    getOrganization(id) {
        return session.get(`/api/v1/organization/${id}/`);
    },
    deleteOrganization(id) {
        return session.delete(`/api/v1/organization/${id}/`);
    },
    createOrganization(data) {
        return session.post(`/api/v1/organization/`, data);
    },
    updateOrganization(id, data) {
        return session.patch(`/api/v1/organization/${id}/`, data );
    },
};