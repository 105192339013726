<script setup>
import {useStore} from "vuex";
import {computed, defineEmits, defineProps, ref} from "vue";
import {errorNotify, prettifyDateTimeShort, successNotify} from "@/mixins";
import FirmwareCreateUpdateModal from "@/components/modals/device/FirmwareCreateUpdateModal.vue";
import TableMenu from "@/components/layouts/TableMenu.vue";

const emit = defineEmits(['update'])
const props = defineProps({firmwaresList: Object, firmwareType: {required: true}})

const store = useStore()
const showModal = ref(false)
const instanceDetail = computed(() => store.getters['firmware/firmwareDetail'])
const deleteError = computed(() => store.getters['firmware/deleteFirmwareError'])
const getError = computed(() => store.getters['firmware/getFirmwareError'])
const deleteInstance = (val) => store.dispatch('firmware/deleteFirmware', val)
const getInstance = (val) => store.dispatch('firmware/getFirmware', val)

function openModal(id) {
  getInstance({firmwareType: props.firmwareType,  id: id})
      .then(() => {if (getError.value) {errorNotify(getError.value)}})
      .finally(() => { showModal.value = true })
}

function closeModal() {
  showModal.value = false
  emit('update')
}

function del(id) {
  if(confirm("Do you really want to delete this firmware?")) {
    deleteInstance({firmwareType: props.firmwareType, id: id})
      .then(() => {
        if (!deleteError.value) { successNotify("Firmware deleted successfully!")}
        else {errorNotify(deleteError.value)}
      })
      .finally(() => { emit('update'); })
  }
}
</script>


<template>
  <FirmwareCreateUpdateModal v-if="showModal" v-model="showModal" :firmware-type=firmwareType @close="closeModal()"
                             :instance="instanceDetail"/>

  <div class="filters-bar row py-3">
    <div class="col"><h4>{{ firmwareType }} firmwares list</h4></div>
  </div>

  <table class="table">
    <thead>
      <th class="first-el">Version</th>
      <th>Description</th>
      <th>Created at</th>
      <th></th>
      </thead>
    <tbody>
      <tr v-for="item in firmwaresList.results" :key="item.id">
        <th class="first-el">{{ item.version }}</th>
        <th>{{ item.description }}</th>
        <th>{{ prettifyDateTimeShort(item.created_at) }}</th>
        <th>
          <TableMenu @open-modal="openModal(item.id)" @del="del(item.id)"/>
        </th>
      </tr>
    </tbody>
  </table>


</template>

<style scoped>
  .row {
    --bs-gutter-x: 0;
  }
</style>
