import model from '@/api/model';

import {
    GET_MODEL_BEGIN,
    GET_MODEL_FAILURE,
    GET_MODEL_SUCCESS,
    GET_MODEL_LIST_BEGIN,
    GET_MODEL_LIST_FAILURE,
    GET_MODEL_LIST_SUCCESS,
    UPDATE_MODEL_BEGIN,
    UPDATE_MODEL_SUCCESS,
    UPDATE_MODEL_FAILURE,
    DELETE_MODEL_BEGIN,
    DELETE_MODEL_FAILURE,
    DELETE_MODEL_SUCCESS,
    CREATE_MODEL_BEGIN,
    CREATE_MODEL_SUCCESS,
    CREATE_MODEL_FAILURE
} from './types';


const initialState = {
    modelList: false,
    modelData: false,
    modelDataError: false,
    modelListError: false,
    modelUpdateError: false,
    modelDeleteError: false,
    modelCreateError: false,
};

const getters = {
    modelList:(state) => state.modelList,
    modelData:(state) => state.modelData,
    modelDataError:(state) => state.modelDataError,
    modelListError:(state) => state.modelListError,
    modelUpdateError:(state) => state.modelUpdateError,
    modelDeleteError:(state) => state.modelDeleteError,
    modelCreateError:(state) => state.modelCreateError,
};

const actions = {
    getModel({ commit }, modelId) {
        commit(GET_MODEL_BEGIN);
        return model.getModel(modelId)
            .then((response) => commit(GET_MODEL_SUCCESS, response.data))
            .catch((error) => commit(GET_MODEL_FAILURE, error.response.data));
    },
    getModelList({ commit }, filter) {
        commit(GET_MODEL_LIST_BEGIN);
        return model.getModelList(filter)
            .then((response) => commit(GET_MODEL_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_MODEL_LIST_FAILURE, error.response.data));
    },
    createModel({ commit }, data) {
        commit(CREATE_MODEL_BEGIN);
        return model.createModel(data)
            .then(() => commit(CREATE_MODEL_SUCCESS))
            .catch((error) => commit(CREATE_MODEL_FAILURE, error.response.data));
    },
    updateModel({ commit }, { id, data }) {
        commit(UPDATE_MODEL_BEGIN);
        return model.updateModel(id, data)
            .then(() => commit(UPDATE_MODEL_SUCCESS))
            .catch((error) => commit(UPDATE_MODEL_FAILURE, error.response.data));
    },
    deleteModel({ commit }, logId) {
        commit(DELETE_MODEL_BEGIN);
        return model.deleteModel(logId)
            .then(() => commit(DELETE_MODEL_SUCCESS))
            .catch((error) => commit(DELETE_MODEL_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_MODEL_BEGIN](state) {
        state.modelDataError = false;
    },
    [GET_MODEL_FAILURE](state, error) {
        state.modelData = false;
        state.modelDataError = error;
    },
    [GET_MODEL_SUCCESS](state, data) {
        state.modelData = data;
        state.modelDataError = false;
    },
    [GET_MODEL_LIST_BEGIN](state) {
        state.modelListError = false;
    },
    [GET_MODEL_LIST_FAILURE](state, error) {
        state.modelList = false;
        state.modelListError = error;
    },
    [GET_MODEL_LIST_SUCCESS](state, data) {
        state.modelList = data;
        state.modelListError = false;
    },
    [CREATE_MODEL_BEGIN](state) {
        state.modelCreateError = false;
    },
    [CREATE_MODEL_SUCCESS](state) {
        state.modelCreateError = false;
    },
    [CREATE_MODEL_FAILURE](state, error) {
        state.modelCreateError = error;
    },
    [UPDATE_MODEL_BEGIN](state) {
        state.modelUpdateError = false;
    },
    [UPDATE_MODEL_FAILURE](state, error) {
        state.modelUpdateError = error;
    },
    [UPDATE_MODEL_SUCCESS](state) {
        state.modelUpdateError = false;
    },
    [DELETE_MODEL_BEGIN](state) {
        state.modelDataError = false;
    },
    [DELETE_MODEL_FAILURE](state, error) {
        state.modelDataError = error;
    },
    [DELETE_MODEL_SUCCESS](state) {
        state.modelDataError = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
