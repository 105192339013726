import {
    CREATE_FIRMWARE_BEGIN,
    CREATE_FIRMWARE_FAILURE,
    CREATE_FIRMWARE_SUCCESS,
    DELETE_FIRMWARE_BEGIN,
    DELETE_FIRMWARE_FAILURE,
    DELETE_FIRMWARE_SUCCESS,
    GET_FIRMWARE_BEGIN,
    GET_FIRMWARE_FAILURE,
    GET_HEAD_FIRMWARE_LIST_BEGIN,
    GET_HEAD_FIRMWARE_LIST_FAILURE,
    GET_HEAD_FIRMWARE_LIST_SUCCESS,
    GET_CELL_FIRMWARE_LIST_BEGIN,
    GET_CELL_FIRMWARE_LIST_FAILURE,
    GET_CELL_FIRMWARE_LIST_SUCCESS,
    GET_FIRMWARE_SUCCESS,
    UPDATE_FIRMWARE_BEGIN,
    UPDATE_FIRMWARE_FAILURE,
    UPDATE_FIRMWARE_SUCCESS
} from "@/store/types";
import firmware from "@/api/firmware";

const initialState = {
    cellFirmwareList: false,
    headFirmwareList: false,
    getFirmwareError: false,
    createFirmwareError: false,
    updateFirmwareError: false,
    deleteFirmwareError: false,
    firmwareDetail: false,
    firmwareList: false,
};

const getters = {
    cellFirmwareList:(state) => state.cellFirmwareList,
    headFirmwareList:(state) => state.headFirmwareList,
    firmwareDetail:(state) => state.firmwareDetail,
    createFirmwareError:(state) => state.createFirmwareError,
    updateFirmwareError:(state) => state.updateFirmwareError,
    deleteFirmwareError:(state) => state.deleteFirmwareError,
    getFirmwareError:(state) => state.getFirmwareError,
};

const actions = {
    getFirmware({ commit }, {firmwareType, id }) {
        commit(GET_FIRMWARE_BEGIN);
        return firmware.getFirmware(firmwareType, id)
            .then((response) => commit(GET_FIRMWARE_SUCCESS, response.data))
            .catch((error) => commit(GET_FIRMWARE_FAILURE, error.response.data));
    },
    getCellFirmwareList({ commit }, filter) {
        commit(GET_CELL_FIRMWARE_LIST_BEGIN);
        return firmware.getCellFirmwareList(filter)
            .then((response) => commit(GET_CELL_FIRMWARE_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_CELL_FIRMWARE_LIST_FAILURE, error.response.data));
    },
    getHeadFirmwareList({ commit }, filter) {
        commit(GET_HEAD_FIRMWARE_LIST_BEGIN);
        return firmware.getHeadFirmwareList(filter)
            .then((response) => commit(GET_HEAD_FIRMWARE_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_HEAD_FIRMWARE_LIST_FAILURE, error.response.data));
    },
    createFirmware({ commit }, {firmwareType, data}) {
        commit(CREATE_FIRMWARE_BEGIN);
        return firmware.createFirmware(firmwareType, data)
            .then(() => commit(CREATE_FIRMWARE_SUCCESS))
            .catch((error) => commit(CREATE_FIRMWARE_FAILURE, error.response.data));
    },
    updateFirmware({ commit }, {firmwareType, id, data }) {
        commit(UPDATE_FIRMWARE_BEGIN);
        return firmware.updateFirmware(firmwareType, id, data)
            .then(() => commit(UPDATE_FIRMWARE_SUCCESS))
            .catch((error) => commit(UPDATE_FIRMWARE_FAILURE, error.response.data));
    },
    deleteFirmware({ commit }, {firmwareType, id }) {
        commit(DELETE_FIRMWARE_BEGIN);
        return firmware.deleteFirmware(firmwareType, id)
            .then(() => commit(DELETE_FIRMWARE_SUCCESS))
            .catch((error) => commit(DELETE_FIRMWARE_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_CELL_FIRMWARE_LIST_BEGIN](state) {
        state.cellListFirmwareError = false;
    },
    [GET_CELL_FIRMWARE_LIST_SUCCESS](state, data) {
        state.cellFirmwareList = data;
        state.cellListFirmwareError = false;
    },
    [GET_CELL_FIRMWARE_LIST_FAILURE](state, error) {
        state.cellListFirmwareError = error;
    },
    [GET_HEAD_FIRMWARE_LIST_BEGIN](state) {
        state.headListFirmwareError = false;
    },
    [GET_HEAD_FIRMWARE_LIST_SUCCESS](state, data) {
        state.headFirmwareList = data;
        state.headListFirmwareError = false;
    },
    [GET_HEAD_FIRMWARE_LIST_FAILURE](state, error) {
        state.headListFirmwareError = error;
    },
    [GET_FIRMWARE_BEGIN](state) {
        state.getFirmwareError = false;
    },
    [GET_FIRMWARE_SUCCESS](state, data) {
        state.firmwareDetail = data;
        state.getFirmwareError = false;
    },
    [GET_FIRMWARE_FAILURE](state, error) {
        state.getFirmwareError = error;
    },
    [CREATE_FIRMWARE_BEGIN](state) {
        state.createFirmwareError = false;
    },
    [CREATE_FIRMWARE_SUCCESS](state) {
        state.createFirmwareError = false;
    },
    [CREATE_FIRMWARE_FAILURE](state, error) {
        state.createFirmwareError = error;
    },
    [UPDATE_FIRMWARE_BEGIN](state) {
        state.updateFirmwareError = false;
    },
    [UPDATE_FIRMWARE_SUCCESS](state) {
        state.updateFirmwareError = false;
    },
    [UPDATE_FIRMWARE_FAILURE](state, error) {
        state.updateFirmwareError = error;
    },
    [DELETE_FIRMWARE_BEGIN](state) {
        state.deleteFirmwareError = false;
    },
    [DELETE_FIRMWARE_SUCCESS](state) {
        state.deleteFirmwareError = false;
    },
    [DELETE_FIRMWARE_FAILURE](state, error) {
        state.deleteFirmwareError = error;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};