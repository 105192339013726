import Vuex from 'vuex';
import { createLogger } from 'vuex';

import auth from './auth';
import locker from './locker';
import log from './log';
import organization from "./organization";
import parcel from './parcel';
import password from './password';
import region from './region';
import user from './user';
import model from './model';
import cell from './cell';
import cell_size from './cell_size';
import city from './city';
import country from './country';
import control_unit from './control_unit';
import address from './address';
import prepaid_account from "./prepaid_account";
import statistic from "./statistic";
import app_customer from "./app_customer";
import firmware from "./firmware";


const debug = process.env.NODE_ENV !== 'production';


export default new Vuex.Store({
    modules: {
        address,
        auth,
        locker,
        log,
        organization,
        parcel,
        password,
        user,
        model,
        cell,
        cell_size,
        city,
        country,
        control_unit,
        region,
        statistic,
        prepaid_account,
        app_customer,
        firmware,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
});
