<script setup>
import {useStore} from "vuex";
import {useLoading} from 'vue-loading-overlay'
import {computed, onMounted, ref} from "vue";
import DevicesLogsTable from "@/components/tables/device/DevicesLogsTable.vue";
import {errorNotify} from "@/mixins";
import PagePaginator from "@/components/layouts/PagePaginator.vue";
import Multiselect from "vue-multiselect";

const fullFilter = ref('')

const store = useStore()
const logList = computed(() => store.getters['log/logList'])
const getListError = computed(() => store.getters['log/logListError'])
const organizationList = computed(() => store.getters['organization/organizationList'])
const lockerList = computed(() => store.getters['locker/lockerList'])
const userList = computed(() => store.getters['user/userList'])
const getLogList = (val) => store.dispatch('log/getLogList', val)
const getOrganizationList = (val) => store.dispatch('organization/getOrganizationList', val)
const getLockerList = (val) => store.dispatch('locker/getLockerList', val)
const getUserList = (val) => store.dispatch('user/getUserList', val)


const organization = ref(null)
const device = ref(null)
const employee = ref(null)
const $loading = useLoading({})
const logType = ref(null)
const logTypesList = ref([
    { filter: 'status_changed', name: 'status was changed' },
    { filter: 'key_changed', name: 'key was changed' },
    { filter: 'organization_changed', name: 'organization was changed' },
    { filter: 'address_changed', name: 'address was changed' },
    { filter: 'name_changed', name: 'name was changed' },
    { filter: 'model_changed', name: 'model was changed' },
    { filter: 'control_unit_changed', name: 'control unit was changed' },
    { filter: 'cell_board_changed', name: 'cell board was changed' },
    { filter: 'cell_is_broken_changed', name: 'cell is_broken was changed' },
    { filter: 'cell_is_active_changed', name: 'cell is_active was changed' },
    { filter: 'cell_cleaning_changed', name: 'cell cleaning was changed' },
    { filter: 'cell_firmware_changed', name: 'cell firmware changed' },
    { filter: 'head_firmware_changed', name: 'head firmware changed' },
])

function getFilteredLogList() {
  const loader = $loading.show({});
  fullFilter.value = `?page=${page.value}`
  if (organization.value) {
    fullFilter.value += `&organization=${organization.value.id}`
  }
  if (device.value) {
    fullFilter.value += `&device=${device.value.sn}`
  }
  if (logType.value) {
    fullFilter.value += `&type=${logType.value.filter}`
  }
  if (employee.value) {
    fullFilter.value += `&user=${employee.value.id}`
  }
  getLogList(fullFilter.value)
      .then(() => {if (getListError.value) {errorNotify(getListError.value)}})
      .finally(() => { loader.hide()})
  }

const page = ref(1)
function clickCallback(pageNum) {
  page.value = pageNum;
  getFilteredLogList()
}

function orgFilter() {
  if (organization.value) {
    getLockerList(`?organization=${organization.value.id}`)
    device.value = null
  }
  getFilteredLogList()
}

onMounted(() => {
  getUserList('')
  getOrganizationList('')
  getLockerList('')
  getFilteredLogList()
})
</script>

<template>
  <section class="parcel-storage-page">
    <div class="row pt-3">
      <div class="col"><h1>Logs</h1></div>
      <div class="col text-end">
      </div>
    </div>


    <div class="row pt-5">
      <div class="col-4">
        <div class="row">
          <div class="col">
            <h4>Devices logs list</h4>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="row">
          <div class="col-3">
            <div class="pb-3 form-floating select-wrapper" v-if="userList.results">
              <multiselect
                  placeholder="Employee"
                  v-model="employee"
                  :options="userList.results"
                  label="first_name"
                  @close="getFilteredLogList()"
              >
              </multiselect>
            </div>
          </div>
          <div class="col-3">
            <div class="pb-3 form-floating select-wrapper" v-if="logTypesList">
              <multiselect
                  placeholder="Action Type"
                  v-model="logType"
                  :options="logTypesList"
                  label="name"
                  @close="getFilteredLogList()"
              >
              </multiselect>
            </div>
          </div>
          <div class="col-3">
            <div class="pb-3 form-floating select-wrapper" v-if="organizationList">
              <multiselect
                  placeholder="Organization"
                  v-model="organization"
                  :options="organizationList.results"
                  label="name"
                  @close="orgFilter()"
              >
              </multiselect>
            </div>
          </div>
          <div class="col-3">
            <div class="pb-3 form-floating select-wrapper" v-if="lockerList">
              <multiselect
                  placeholder="Device"
                  v-model="device"
                  :options="lockerList.results"
                  label="name"
                  @close="getFilteredLogList()"
              >
              </multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
      <DevicesLogsTable :items-list="logList"/>
      <PagePaginator @change="clickCallback" :page-count="logList.total_pages" />
  </section>
</template>
