import address from '@/api/address';

import {
    GET_ADDRESS_LIST_BEGIN,
    GET_ADDRESS_LIST_SUCCESS,
    GET_ADDRESS_LIST_FAILURE,
    GET_ADDRESS_BEGIN,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_FAILURE,
    UPDATE_ADDRESS_DATA_BEGIN,
    UPDATE_ADDRESS_DATA_SUCCESS,
    UPDATE_ADDRESS_DATA_FAILURE,
    CREATE_ADDRESS_BEGIN,
    CREATE_ADDRESS_SUCCESS,
    CREATE_ADDRESS_FAILURE,
    DELETE_ADDRESS_BEGIN,
    DELETE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_FAILURE,
} from './types';

const initialState = {
    listAddressError: false,
    getAddressError: false,
    createAddressError: false,
    updateAddressError: false,
    deleteAddressError: false,
    addressDetail: false,
    addressList: false,
};

const getters = {
    addressList:(state) => state.addressList,
    listAddressError:(state) => state.listAddressError,
    createAddressError:(state) => state.createAddressError,
    updateAddressError:(state) => state.updateAddressError,
    addressDetail:(state) => state.addressDetail,
    getAddressError:(state) => state.getAddressError,
    deleteAddressError:(state) => state.deleteAddressError
};

const actions = {
    getAddress({ commit }, id) {
        commit(GET_ADDRESS_BEGIN);
        return address.getAddress(id)
            .then((response) => commit(GET_ADDRESS_SUCCESS, response.data))
            .catch((error) => commit(GET_ADDRESS_FAILURE, error.response.data));
    },
    getAddressList({ commit }, filter) {
        commit(GET_ADDRESS_LIST_BEGIN);
        return address.getAddressList(filter)
            .then((response) => commit(GET_ADDRESS_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_ADDRESS_LIST_FAILURE, error.response.data));
    },
    createAddress({ commit }, data) {
        commit(CREATE_ADDRESS_BEGIN);
        return address.createAddress(data)
            .then(() => commit(CREATE_ADDRESS_SUCCESS))
            .catch((error) => commit(CREATE_ADDRESS_FAILURE, error.response.data));
    },
    updateAddress({ commit }, { id, data }) {
        commit(UPDATE_ADDRESS_DATA_BEGIN);
        return address.updateAddress(id, data)
            .then(() => commit(UPDATE_ADDRESS_DATA_SUCCESS))
            .catch((error) => commit(UPDATE_ADDRESS_DATA_FAILURE, error.response.data));
    },
    deleteAddress({ commit }, id) {
        commit(DELETE_ADDRESS_BEGIN);
        return address.deleteAddress(id)
            .then(() => commit(DELETE_ADDRESS_SUCCESS))
            .catch((error) => commit(DELETE_ADDRESS_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_ADDRESS_LIST_BEGIN](state) {
        state.listAddressError = false;
    },
    [GET_ADDRESS_LIST_SUCCESS](state, data) {
        state.addressList = data;
        state.listAddressError = false;
    },
    [GET_ADDRESS_LIST_FAILURE](state, error) {
        state.listAddressError = error;
    },
    [GET_ADDRESS_BEGIN](state) {
        state.getAddressError = false;
    },
    [GET_ADDRESS_SUCCESS](state, data) {
        state.addressDetail = data;
        state.getAddressError = false;
    },
    [GET_ADDRESS_FAILURE](state, error) {
        state.getAddressError = error;
    },
    [CREATE_ADDRESS_BEGIN](state) {
        state.createAddressError = false;
    },
    [CREATE_ADDRESS_SUCCESS](state) {
        state.createAddressError = false;
    },
    [CREATE_ADDRESS_FAILURE](state, error) {
        state.createAddressError = error;
    },
    [UPDATE_ADDRESS_DATA_BEGIN](state) {
        state.updateAddressError = false;
    },
    [UPDATE_ADDRESS_DATA_SUCCESS](state) {
        state.updateAddressError = false;
    },
    [UPDATE_ADDRESS_DATA_FAILURE](state, error) {
        state.updateAddressError = error;
    },
    [DELETE_ADDRESS_BEGIN](state) {
        state.deleteAddressError = false;
    },
    [DELETE_ADDRESS_SUCCESS](state) {
        state.deleteAddressError = false;
    },
    [DELETE_ADDRESS_FAILURE](state, error) {
        state.deleteAddressError = error;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};