/* eslint-disable */
import session from './session';

export default {
    getRegionList(filter) {
        return session.get(`/api/v1/region/${filter}`);
    },
    getRegion(id) {
        return session.get(`/api/v1/region/${id}/`);
    },
    deleteRegion(id) {
        return session.delete(`/api/v1/region/${id}/`);
    },
    createRegion(data) {
        return session.post(`/api/v1/region/`, data);
    },
    updateRegion(id, data) {
        return session.patch(`/api/v1/region/${id}/`, data );
    },
};