<script setup>
import LocationsInfoPanel from "@/components/panels/LocationsInfoPanel";
import { useStore } from 'vuex'
import { ref, computed, onMounted } from "vue"
import CountryTable from "@/components/tables/location/CountryTable.vue";
import CityTable from "@/components/tables/location/CityTable.vue";
import AddressTable from "@/components/tables/location/AddressTable.vue";
import CountryCreateUpdateModal from "@/components/modals/location/CountryCreateUpdateModal.vue";
import CityCreateUpdateModal from "@/components/modals/location/CityCreateUpdateModal.vue";
import AddressCreateUpdateModal from "@/components/modals/location/AddressCreateUpdateModal.vue";
import {useLoading} from "vue-loading-overlay";

const countryFullFilter = ref('')
const cityFullFilter = ref('')
const addressFullFilter = ref('')
const countryPage = ref(1)
const cityPage = ref(1)
const addressPage = ref(1)
const $loading = useLoading({});

const store = useStore()
const countryList = computed(() => store.getters['country/countryList'])
const cityList = computed(() => store.getters['city/cityList'])
const addressList = computed(() => store.getters['address/addressList'])
const locationsStatistic = computed(() => store.getters['statistic/locationsStatistic'])
const getCountryList = (val) => store.dispatch('country/getCountryList', val)
const getCityList = (val) => store.dispatch('city/getCityList', val)
const getAddressList = (val) => store.dispatch('address/getAddressList', val)
const getLocationsStatistic = (val) => store.dispatch('statistic/getLocationsStatistic', val)

const getFilteredCountryList = () => {
  countryFullFilter.value = `?page=${countryPage.value}`
  getCountryList(countryFullFilter.value)
}
const getFilteredCityList = () => {
  cityFullFilter.value = `?page=${cityPage.value}`
  getCityList(cityFullFilter.value)
}
const getFilteredAddressList = () => {
  addressFullFilter.value = `?page=${addressPage.value}`
  getAddressList(addressFullFilter.value)
}

// modals
const showCountryModal = ref(false)
const showCityModal = ref(false)
const showAddressModal = ref(false)
function closeCountryModal() {
  showCountryModal.value = false
  getFilteredCountryList()
  getLocationsStatistic()
}
function closeCityModal() {
  showCityModal.value = false
  getFilteredCityList()
  getLocationsStatistic()
}
function closeAddressModal() {
  showAddressModal.value = false
  getFilteredAddressList()
  getLocationsStatistic()
}

function getInitialData() {
  const loader = $loading.show({});
  getFilteredCountryList()
  getFilteredCityList()
  getFilteredAddressList()
  getLocationsStatistic()
    .finally(() => {loader.hide()});
}

onMounted(() => {
  getInitialData()
})
</script>

<template>
  <CountryCreateUpdateModal @close="closeCountryModal" v-model="showCountryModal" v-if="showCountryModal"/>
  <CityCreateUpdateModal @close="closeCityModal" v-model="showCityModal" v-if="showCityModal"/>
  <AddressCreateUpdateModal @close="closeAddressModal" v-model="showAddressModal" v-if="showAddressModal"/>
  <section class="locations-page">
    <div class="row pb-3">
      <div class="col"><h1>Locations</h1></div>
      <div class="col text-end">
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8337 6.83166H6.83366V11.8317H5.16699V6.83166H0.166992V5.16499H5.16699V0.164993H6.83366V5.16499H11.8337V6.83166Z" fill="white"/>
            </svg>
          </button>
          <ul class="dropdown-menu">
            <li><a @click="showCountryModal=true" class="dropdown-item" href="#">Country</a></li>
            <li><a @click="showCityModal=true" class="dropdown-item" href="#">City</a></li>
            <li><a @click="showAddressModal=true" class="dropdown-item" href="#">Address</a></li>
          </ul>
        </div>
      </div>
    </div>
    <LocationsInfoPanel :data="locationsStatistic" />
    <AddressTable :address-list="addressList"  @update="getFilteredAddressList"/>
    <div class="row">
      <div class="col-8">
        <CityTable :city-list="cityList"  @update="getFilteredCityList"/>
      </div>
      <div class="col-4">
        <CountryTable :country-list="countryList" @update="getFilteredCountryList" />
      </div>
    </div>
  </section>
</template>
