<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted, ref} from "vue"
import MainModal from "@/components/layouts/MainModal"
import Multiselect from 'vue-multiselect'

const store = useStore()
const controlUnitList = computed(() => store.getters['control_unit/controlUnitList'])
const headFirmwareList = computed(() => store.getters['firmware/headFirmwareList'])

const getControlUnitList = (val) => store.dispatch('control_unit/getControlUnitList', val)
const getHeadFirmwareList = (val) => store.dispatch('firmware/getHeadFirmwareList', val)

const emit = defineEmits(['setControlUnit', 'close'])
const props = defineProps({instance: Object})

const instanceData = ref({})

function confirm() {
  emit('setControlUnit', instanceData.value)
  emit('close')
}

onMounted(() => {
  getControlUnitList('')
  getHeadFirmwareList('')
  if (props.instance) {instanceData.value = props.instance}
})
</script>


<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')">
    <template v-slot:title>Control unit</template>
    <div class="modal__content">

      <div class="pb-3 form-floating select-wrapper" v-if="controlUnitList">
        <multiselect
            selectLabel=""
            deselectLabel=""
            placeholder="Change the control unit"
            v-model="instanceData.unit"
            :options="controlUnitList.results"
            label="sn"
        >
        </multiselect>
      </div>

      <div class="pb-3 form-floating select-wrapper" v-if="headFirmwareList">
        <multiselect
            selectLabel=""
            deselectLabel=""
            placeholder="Change the control unit firmware version"
            v-model="instanceData.head_firmware_version"
            :options="headFirmwareList.results"
            label="version"
        >
        </multiselect>
      </div>

    </div>
  </main-modal>
</template>
