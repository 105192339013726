import log from '@/api/log';

import {
    GET_LOG_BEGIN,
    GET_LOG_FAILURE,
    GET_LOG_SUCCESS,
    GET_LOG_LIST_BEGIN,
    GET_LOG_LIST_FAILURE,
    GET_LOG_LIST_SUCCESS,
    DELETE_LOG_BEGIN,
    DELETE_LOG_FAILURE,
    DELETE_LOG_SUCCESS,
} from './types';


const initialState = {
    logList: false,
    logListError: false,
    logData: false,
    logDataError: false,
    logDeleteError: false,
};

const getters = {
    logList:(state) => state.logList,
    logListError:(state) => state.logListError,
    logData:(state) => state.logData,
    logDataError:(state) => state.logDataError,
    logDeleteError:(state) => state.logDeleteError,
};

const actions = {
    getLog({ commit }, logId) {
        commit(GET_LOG_BEGIN);
        return log.getLog(logId)
            .then((response) => commit(GET_LOG_SUCCESS, response.data))
            .catch((error) => commit(GET_LOG_FAILURE, error.response.data));
    },
    getLogList({ commit }, filter) {
        commit(GET_LOG_LIST_BEGIN);
        return log.getLogList(filter)
            .then((response) => commit(GET_LOG_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_LOG_LIST_FAILURE, error.response.data));
    },
    deleteLog({ commit }, logId) {
        commit(DELETE_LOG_BEGIN);
        return log.deleteLog(logId)
            .then(() => commit(DELETE_LOG_SUCCESS))
            .catch((error) => commit(DELETE_LOG_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_LOG_BEGIN](state) {
        state.logDataError = false;
    },
    [GET_LOG_FAILURE](state, error) {
        state.logData = false;
        state.logDataError = error;
    },
    [GET_LOG_SUCCESS](state, data) {
        state.logData = data;
        state.logDataError = false;
    },
    [GET_LOG_LIST_BEGIN](state) {
        state.logListError = false;
    },
    [GET_LOG_LIST_FAILURE](state, error) {
        state.logList = false;
        state.logListError = error;
    },
    [GET_LOG_LIST_SUCCESS](state, data) {
        state.logList = data;
        state.logListError = false;
    },
    [DELETE_LOG_BEGIN](state) {
        state.logDeleteError = false;
    },
    [DELETE_LOG_FAILURE](state, error) {
        state.logDeleteError = error;
    },
    [DELETE_LOG_SUCCESS](state) {
        state.logDeleteError = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
