<script setup>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'
import {  defineProps } from "vue";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const props = defineProps({
  data: { required: true },
  title: { required: true }
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  animations: {
        radius: {
          duration: 400,
          easing: 'linear',
          loop: (context) => context.active
        }
      },
  hoverRadius: 12,
    hoverBackgroundColor: 'red',
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x'
    },
  plugins: {
      legend: {
        align: 'end',
      },
      title: {
        display: true,
        text: props.title
      }
    }
}
</script>

<template>
  <Line :data="props.data" :options="options" />
</template>