import region from '@/api/region';

import {
    GET_REGION_LIST_BEGIN,
    GET_REGION_LIST_SUCCESS,
    GET_REGION_LIST_FAILURE,
    GET_REGION_BEGIN,
    GET_REGION_SUCCESS,
    GET_REGION_FAILURE,
    CREATE_REGION_BEGIN,
    CREATE_REGION_SUCCESS,
    CREATE_REGION_FAILURE,
    UPDATE_REGION_DATA_BEGIN,
    UPDATE_REGION_DATA_SUCCESS,
    UPDATE_REGION_DATA_FAILURE,
    DELETE_REGION_BEGIN,
    DELETE_REGION_SUCCESS,
    DELETE_REGION_FAILURE,
} from './types';

const initialState = {
    createRegionError: false,
    updateRegionError: false,
    deleteRegionError: false,
    listRegionError: false,
    getRegionError: false,
    regionDetail: false,
    regionList: false,
};

const getters = {
    regionList:(state) => state.regionList,
    regionDetail:(state) => state.regionDetail
};

const actions = {
    getRegion({ commit }, id) {
        commit(GET_REGION_BEGIN);
        return region.getRegion(id)
            .then((response) => commit(GET_REGION_SUCCESS, response.data))
            .catch((error) => commit(GET_REGION_FAILURE, error.response.data));
    },
    getRegionList({ commit }, filter) {
        commit(GET_REGION_LIST_BEGIN);
        return region.getRegionList(filter)
            .then((response) => commit(GET_REGION_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_REGION_LIST_FAILURE, error.response.data));
    },
    createRegion({ commit }, data) {
        commit(CREATE_REGION_BEGIN);
        return region.createRegion(data)
            .then(() => commit(CREATE_REGION_SUCCESS))
            .catch((error) => commit(CREATE_REGION_FAILURE, error.response.data));
    },
    updateRegion({ commit }, { id, data }) {
        commit(UPDATE_REGION_DATA_BEGIN);
        return region.updateRegion(id, data)
            .then(() => commit(UPDATE_REGION_DATA_SUCCESS))
            .catch((error) => commit(UPDATE_REGION_DATA_FAILURE, error.response.data));
    },
    deleteRegion({ commit }, id) {
        commit(DELETE_REGION_BEGIN);
        return region.deleteRegion(id)
            .then(() => commit(DELETE_REGION_SUCCESS))
            .catch((error) => commit(DELETE_REGION_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_REGION_LIST_BEGIN](state) {
        state.listRegionError = false;
    },
    [GET_REGION_LIST_SUCCESS](state, data) {
        state.regionList = data;
        state.listRegionError = false;
    },
    [GET_REGION_LIST_FAILURE](state, error) {
        state.listRegionError = error;
    },
    [GET_REGION_BEGIN](state) {
        state.getRegionError = false;
    },
    [GET_REGION_SUCCESS](state, data) {
        state.regionDetail = data;
        state.getRegionError = false;
    },
    [GET_REGION_FAILURE](state, error) {
        state.getRegionError = error;
    },
    [CREATE_REGION_BEGIN](state) {
        state.createRegionError = false;
    },
    [CREATE_REGION_SUCCESS](state) {
        state.createRegionError = false;
    },
    [CREATE_REGION_FAILURE](state, error) {
        state.createRegionError = error;
    },
    [UPDATE_REGION_DATA_BEGIN](state) {
        state.updateRegionError = false;
    },
    [UPDATE_REGION_DATA_SUCCESS](state) {
        state.updateRegionError = false;
    },
    [UPDATE_REGION_DATA_FAILURE](state, error) {
        state.updateRegionError = error;
    },
    [DELETE_REGION_BEGIN](state) {
        state.deleteRegionError = false;
    },
    [DELETE_REGION_SUCCESS](state) {
        state.deleteRegionError = false;
    },
    [DELETE_REGION_FAILURE](state, error) {
        state.deleteRegionError = error;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};