<script setup>
import {useStore} from "vuex";
import {useLoading} from 'vue-loading-overlay'
import {computed, onMounted, ref} from "vue";
import PrepaidAccountInfoPanel from "@/components/panels/PrepaidAccountInfoPanel.vue";
import PrepaidAccountsTable from "@/components/tables/account/PrepaidAccountsTable.vue";
import PagePaginator from "@/components/layouts/PagePaginator.vue";
import PrepaidAccountCreateUpdateModal from "@/components/modals/account/PrepaidAccountCreateUpdateModal.vue";
import Multiselect from "vue-multiselect";
import {errorNotify} from "@/mixins";

const store = useStore()
const prepaidAccountList = computed(() => store.getters['prepaid_account/prepaidAccountList'])
const prepaidAccountListError = computed(() => store.getters['prepaid_account/prepaidAccountListError'])
const prepaidAccountCodesCreateError = computed(() => store.getters['prepaid_account/prepaidAccountCodesCreateError'])
const prepaidAccountStatistic = computed(() => store.getters['statistic/prepaidAccountStatistic'])
const organizationList = computed(() => store.getters['organization/organizationList'])
const lockerList = computed(() => store.getters['locker/lockerList'])
const getPrepaidAccountList = (val) => store.dispatch('prepaid_account/getPrepaidAccountList', val)
const createPrepaidAccountCodes = (val) => store.dispatch('prepaid_account/createPrepaidAccountCodes', val)
const getPrepaidAccountStatistic = (val) => store.dispatch('statistic/getPrepaidAccountsStatistic', val)
const getOrganizationList = (val) => store.dispatch('organization/getOrganizationList', val)
const getLockerList = (val) => store.dispatch('locker/getLockerList', val)

const fullFilter = ref('')
const organization = ref(null)
const device = ref(null)
const accountSn = ref(null)
const $loading = useLoading({});
function getFilteredPrepaidAccountList() {
  fullFilter.value = `?page=${page.value}`
  if (organization.value) {
    fullFilter.value += `&organization=${organization.value.id}`
  }
  if (device.value) {
    fullFilter.value += `&device=${device.value.sn}`
  }
  if (accountSn.value) {
    fullFilter.value = `?sn=${accountSn.value}`
    organization.value = null
    device.value = null
  }
  const loader = $loading.show({});
  getPrepaidAccountStatistic()
  getPrepaidAccountList(fullFilter.value)
    .then(() => {if (prepaidAccountListError.value) {errorNotify(prepaidAccountListError.value)}})
    .finally(() => {loader.hide()});
  }

function orgFilter() {
  if (organization.value) {
    getLockerList(`?organization=${organization.value.id}`)
    device.value = null
  }
  getFilteredPrepaidAccountList()
}

const showModal = ref(false)
function closeModal() {
  showModal.value = false
  getFilteredPrepaidAccountList()
}
const page = ref(1)
function clickCallback(pageNum) {
  page.value = pageNum;
  getFilteredPrepaidAccountList()
}
function generate_codes() {
  if(confirm("1k prepaid accounts will be created and sent by email.")){
    createPrepaidAccountCodes()
      .then(() => {if (prepaidAccountCodesCreateError.value) {errorNotify(prepaidAccountCodesCreateError.value)}})
}}
onMounted(() => {
  getOrganizationList('')
  getLockerList('')
  getFilteredPrepaidAccountList()
})
</script>

<template>
  <PrepaidAccountCreateUpdateModal v-model="showModal" v-if="showModal" @close="closeModal()" />

  <section class="cells-page">
    <div class="row pb-3">
      <div class="col-8"><h1>Prepaid Accounts</h1></div>
      <div class="col-2 text-end">
        <button @click="showModal=true" type="button" class="btn btn-primary">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8337 6.83166H6.83366V11.8317H5.16699V6.83166H0.166992V5.16499H5.16699V0.164993H6.83366V5.16499H11.8337V6.83166Z" fill="white"/>
          </svg>
          Add account
        </button>
      </div>
      <div class="col-2 text-end">
        <button @click="generate_codes()" type="button" class="btn btn-primary ml-5">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8337 6.83166H6.83366V11.8317H5.16699V6.83166H0.166992V5.16499H5.16699V0.164993H6.83366V5.16499H11.8337V6.83166Z" fill="white"/>
          </svg>
          Generate Accounts
        </button>
      </div>
    </div>

    <PrepaidAccountInfoPanel :data="prepaidAccountStatistic"/>

    <div class="row pt-5">
      <div class="col-6">
        <div class="row">
          <div class="col">
            <h4>Prepaid accounts list</h4>
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Search by serial number"
                   v-model="accountSn"  @input="getFilteredPrepaidAccountList()">
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="row">
          <div class="col-6">
            <div class="pb-3 form-floating select-wrapper" v-if="organizationList">
              <multiselect
                  placeholder="Organization"
                  v-model="organization"
                  :options="organizationList.results"
                  label="name"
                  @close="orgFilter()"
              >
              </multiselect>
          </div>
          </div>
          <div class="col-6">
            <div class="pb-3 form-floating select-wrapper" v-if="lockerList">
              <multiselect
                  placeholder="Device"
                  v-model="device"
                  :options="lockerList.results"
                  label="name"
                  @close="getFilteredPrepaidAccountList()"
              >
              </multiselect>
          </div>
          </div>
        </div>

      </div>
    </div>

    <PrepaidAccountsTable @update="getFilteredPrepaidAccountList" :prepaid-account-list="prepaidAccountList" />
    <PagePaginator @change="clickCallback" :page-count="prepaidAccountList.total_pages" />
  </section>
</template>
