<script setup>
import {useStore} from "vuex";
import {useLoading} from 'vue-loading-overlay'
import {computed, onMounted, ref} from "vue";
import FirmwareCreateUpdateModal from "@/components/modals/device/FirmwareCreateUpdateModal.vue";
import FirmwaresTable from "@/components/tables/device/FirmwaresTable.vue";


const fullFilter = ref('')
const showModal = ref(false)
const firmwareType = ref('')

const store = useStore()
const headFirmwareList  = computed(() => store.getters['firmware/headFirmwareList'])
const cellFirmwareList  = computed(() => store.getters['firmware/cellFirmwareList'])


const getHeadFirmwareList = (val) => store.dispatch('firmware/getHeadFirmwareList', val)
const getCellFirmwareList = (val) => store.dispatch('firmware/getCellFirmwareList', val)

const $loading = useLoading({});
function getFilteredCellsFirmwareList() {
  const loader = $loading.show({});
  getCellFirmwareList(fullFilter.value)
    .finally(() => {loader.hide()});
  }
function getFilteredHeadsFirmwareList() {
  const loader = $loading.show({});
  getHeadFirmwareList(fullFilter.value)
    .finally(() => {loader.hide()});
  }
function closeModal() {
  showModal.value = false
  getFilteredCellsFirmwareList()
  getFilteredHeadsFirmwareList()
}

onMounted(() => {
  getFilteredCellsFirmwareList()
  getFilteredHeadsFirmwareList()
})
</script>


<template>
  <FirmwareCreateUpdateModal @close="closeModal" :firmware-type=firmwareType v-model="showModal" v-if="showModal" />
  <section class="device-firmwares-page">

    <div class="row pb-3">
      <div class="col"><h1>Locations</h1></div>
      <div class="col text-end">
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8337 6.83166H6.83366V11.8317H5.16699V6.83166H0.166992V5.16499H5.16699V0.164993H6.83366V5.16499H11.8337V6.83166Z" fill="white"/>
            </svg>
          </button>
          <ul class="dropdown-menu">
            <li><a @click="showModal=true; firmwareType='head'" class="dropdown-item" href="#">Head firmware</a></li>
            <li><a @click="showModal=true; firmwareType='cell'" class="dropdown-item" href="#">Cell firmware</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <FirmwaresTable :firmwares-list="headFirmwareList" :firmware-type="'head'" @update="getFilteredHeadsFirmwareList"/>
      </div>
      <div class="col">
        <FirmwaresTable :firmwares-list="cellFirmwareList" :firmware-type="'cell'" @update="getFilteredCellsFirmwareList" />
      </div>
    </div>

  </section>
</template>
