<script setup>
import TableCard from "@/components/panels/TableCard.vue";
import {computed, ref} from "vue";
import { useRouter } from "vue-router"
import {errorNotify, successNotify} from "@/mixins";
import {useStore} from "vuex";
import OrganizationModal from "@/components/modals/new_locker/OrganizationModal.vue";
import AddressModal from "@/components/modals/new_locker/AddressModal.vue";
import ControlUnitModal from "@/components/modals/new_locker/ControlUnitModal.vue";
import InformationModal from "@/components/modals/new_locker/InformationModal.vue";

const store = useStore()
const router = useRouter()
const createInstanceError = computed(() => store.getters['locker/lockerCreateError'])
const lockerKey = computed(() => store.getters['locker/lockerKey'])
const createInstance = (val) => store.dispatch('locker/createLocker', val)

const showOrganizationModal = ref(false)
const showInformationModal = ref(false)
const showControlUnitModal = ref(false)
const showAddressModal = ref(false)
const showFinalModal = ref(true)
function setOrganization(instance) {
  locker.value.organization = instance
}
function prepareOrganizationCard(instance) {
  let data = {'Name': instance.name, 'Address': ''}
  if (instance.address) {data['Address'] = instance.address.full_address}
  return data
}
function setInformation(instance) {
  locker.value.information = instance
}
function prepareInformationCard(instance) {
  let data = {'Serial Number': instance.sn, 'Name': instance.name, 'Model': ''}
  if (instance.model) {data['Model'] = instance.model.name}
  return data
}
function setControlUnit(instance) {
  locker.value.control_unit = instance
}
function prepareControlUnitCard(instance) {
  return {
    'Serial Number': instance.unit.sn,
    'Display size': instance.unit.screen,
    'QR code reader': instance.unit.qr_reader,
    'NFC scanner': instance.unit.nft_reader,
    'Firmware version': instance.head_firmware_version.version
  }
}
function setAddress(instance) {
  locker.value.address = instance
}
function prepareAddressCard(instance) {
  let data = {'Country': instance.country, 'City': '', 'Address': instance.address}
  if (instance.city) {data['City'] = instance.city.name}
  return data
}

const locker = ref({
  key: lockerKey,
  organization: {},
  information: {
    sn: '',
    name: '',
    model: {},
    cell_firmware_version: '',
    ip_address: ''
  },

  control_unit: {
    unit: '',
    head_firmware_version: ''
  },
  address: {},
})

function copyToClipBoard(textToCopy){
    navigator.clipboard.writeText(textToCopy)
}

function finishLockerCreation() {
  showFinalModal.value = false
  router.push({ name: 'lockers' })
}

function instanceCreate() {
  let data = {
    sn: locker.value.information.sn,
    name: locker.value.information.name,
    cell_firmware_version: locker.value.information.cell_firmware_version.id,
    ip_address: locker.value.information.ip_address,
    organization: locker.value.organization.id,
    address: locker.value.address.id,
    model: locker.value.information.model.code,
    control_unit: locker.value.control_unit.unit.sn,
    head_firmware_version: locker.value.control_unit.head_firmware_version.id,
  }
  createInstance(data)
      .then(() => {
        if (!createInstanceError.value) { successNotify("Locker added successfully!") }
        else { errorNotify(createInstanceError.value) }
      })
}

</script>

<template>
  <section class="new-device">
    <vue-final-modal
        v-if="showFinalModal"
        v-model="locker.key"
        v-bind="$attrs"
        classes="modal-container"
        content-class="modal-content create-update-modal">

      <div class="modal__action pt-3">
        <p>Locker API key</p>
        {{locker.key}}
        <a class="ps-5" @click="copyToClipBoard(locker.key)" href="#">copy</a>
        <div class="pt-4">
          <button class="button w-100" @click="finishLockerCreation">OK</button>
        </div>
      </div>

    </vue-final-modal>
    <OrganizationModal
        @close="showOrganizationModal=false"
        @setOrganization="setOrganization"
        v-model="showOrganizationModal"
        :instance="locker.organization"
        v-if="showOrganizationModal"/>
    <InformationModal
        @close="showInformationModal=false"
        @setInformation="setInformation"
        :instance="locker.information"
        v-model="showInformationModal"
        v-if="showInformationModal"/>
    <ControlUnitModal
        @close="showControlUnitModal=false"
        @setControlUnit="setControlUnit"
        :instance="locker.control_unit"
        v-model="showControlUnitModal"
        v-if="showControlUnitModal"/>
    <AddressModal
        @close="showAddressModal=false"
        @setAddress="setAddress"
        :instance="locker.address"
        v-model="showAddressModal"
        v-if="showAddressModal"/>

    <div class="row pb-3">
      <div class="col">
        <h1>Add New Locker</h1>
      </div>
      <div class="col text-end">
        <div class="d-inline text-end pe-5" @click="router.push({ name: 'lockers' })"><a href="#">Cancel</a></div>
        <div class="d-inline" @click="instanceCreate"><button>Save</button></div>
      </div>
    </div>

    <div class="row">

      <div class="col-4">
        <TableCard
            @click="showOrganizationModal=true"
            :item="prepareOrganizationCard(locker.organization)"
            :icon-folder="'lockers'"
            :icon-name="'organization'"
            :name="'Organization'"/>
        <TableCard
            @click="showInformationModal=true"
            :item="prepareInformationCard(locker.information)"
            :icon-folder="'lockers'"
            :icon-name="'information'"
            :name="'Locker information'"/>
        <TableCard
            @click="showControlUnitModal=true"
            :item="prepareControlUnitCard(locker.control_unit)"
            :icon-folder="'lockers'"
            :icon-name="'control_unit'"
            :name="'Control unit'"/>
        <TableCard
            @click="showAddressModal=true"
            :item="prepareAddressCard(locker.address)"
            :icon-folder="'lockers'"
            :icon-name="'address'"
            :name="'locker address'"/>
      </div>

      <div class="col-8">
        <TableCard :item="{}" :icon-folder="'lockers'" :icon-name="'offline'" :name="'Locker and its cells state'"/>
      </div>

    </div>

  </section>
</template>

<style scoped>
.main-card {
  cursor: pointer;
}
</style>