<template>
  <div class="text-center h-100 w-100">
    <img src="@/assets/img/empty_page.png" alt="empty page">
    <p>No data to display</p>
  </div>
</template>

<script>
export default {
  name: "EmptyPage"
}
</script>

<style scoped>
  img {
    height: 257px;
  }
  div {
    background-color: white;
    border-radius: 8px;
    padding: 158px 0 158px 0;
  }
</style>