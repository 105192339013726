/* eslint-disable */
import session from './session';

export default {
    getStorageParcelList(filter) {
        return session.get(`/api/v1/parcel/storage/${filter}`);
    },
    getStorageParcel(parcelId) {
        return session.get(`/api/v1/parcel/storage/${parcelId}/`);
    },
    finishStorageParcel(parcelId) {
        return session.get(`/api/v1/parcel/storage/${parcelId}/finish/`);
    },
    getExpectedParcelList(filter) {
        return session.get(`/api/v1/parcel/expected/${filter}`);
    },
    geExpectedParcel(parcelId) {
        return session.get(`/api/v1/parcel/expected/${parcelId}/`);
    },
};