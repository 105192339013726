<script setup>
import {useStore} from "vuex";
import { useRouter } from "vue-router"
import {computed, defineEmits, defineProps} from "vue";
import TableMenu from "@/components/layouts/TableMenu.vue";
import {successNotify, errorNotify, prettifyDateTimeShort} from "@/mixins";

const emit = defineEmits(['update'])
defineProps({
  lockerList: Object
})

const store = useStore()
const router = useRouter()
const deleteInstanceError = computed(() => store.getters['locker/lockerDeleteError'])
const deleteInstance = (val) => store.dispatch('locker/deleteLocker', val)


function del(id) {
  if(confirm("Do you really want to delete this locker?")) {
    deleteInstance(id)
      .then(() => {
        if (!deleteInstanceError.value) { successNotify("Locker deleted successfully!")}
        else {errorNotify(deleteInstanceError.value)}
      })
      .finally(() => { emit('update'); })
  }
}

</script>

<template>
  <table class="table">
    <thead>
      <th class="first-el">Name</th>
      <th>S/n</th>
      <th>Model</th>
      <th>Slots</th>
<!--      <th>Profile</th>-->
      <th>Address</th>
      <th>Sync</th>
      <th>Battery</th>
      <th>Status</th>
      <th>Organization</th>
      <th></th>
      </thead>
    <tbody>
      <tr v-for="item in lockerList.results" :key="item.sn">
        <th class="first-el">{{ item.name }}</th>
        <th>{{ item.sn }}</th>
        <th>{{ item.model.code }}</th>
        <th>

          <div class="pb-3">
            <div class="d-inline pe-5">
              <img :src="require(`@/assets/icon/panels/lockers/active.png`)" alt="">
                {{ item.slots.active }}
            </div>
            <div class="d-inline">
              <img :src="require(`@/assets/icon/panels/lockers/occupied.png`)" alt="">
              {{ item.slots.occupied }}
            </div>
          </div>

          <div>
            <div class="d-inline pe-5">
              <img :src="require(`@/assets/icon/panels/lockers/broken.png`)" alt="">
              {{ item.slots.broken }}
            </div>
            <div class="d-inline">
              <img :src="require(`@/assets/icon/panels/lockers/cleaning_required.png`)" alt="">
              {{ item.slots.cleaning_required }}
            </div>
          </div>


        </th>
        <th>{{ item.address.full_address }}</th>
        <th v-if="item.last_sync">{{ prettifyDateTimeShort(item.last_sync) }}</th>
        <th v-else>N/a</th>
        <th v-if="item.battery_level">{{ item.battery_level }}</th>
        <th v-else>N/a</th>
        <th v-if="item.status === 'online'"><div class="status-badge badge-success">{{ item.status }}</div></th>
        <th v-if="item.status === 'offline'"><div class="status-badge badge-grey">{{ item.status }}</div></th>
        <th v-if="item.status === 'maintenance'"><div class="status-badge badge-warning">{{ item.status }}</div></th>
        <th v-if="item.status === 'error'"><div class="status-badge badge-error">{{ item.status }}</div></th>

        <th>{{ item.organization.name }}</th>
        <th><TableMenu @open-modal="router.push({name:'locker', params:{slug:item.sn}})" @del="del(item.sn)"/></th>
      </tr>
    </tbody>
  </table>
</template>

<style scoped>
  img {
    height: 18px;
  }
</style>
