/* eslint-disable */
import session from './session';

export default {
    getModelList(filter) {
        return session.get(`/api/v1/device_model/${filter}`);
    },
    getModel(id) {
        return session.get(`/api/v1/device_model/${id}/`);
    },
    createModel(data) {
        return session.post(`/api/v1/device_model/`, data);
    },
    updateModel(id, data) {
        return session.patch(`/api/v1/device_model/${id}/`, data);
    },
    deleteModel(id) {
        return session.delete(`/api/v1/device_model/${id}/`);
    },
};