/* eslint-disable */
import session from './session';

export default {
    getCityList(filter) {
        return session.get(`/api/v1/city/${filter}`);
    },
    getCity(id) {
        return session.get(`/api/v1/city/${id}/`);
    },
    deleteCity(id) {
        return session.delete(`/api/v1/city/${id}/`);
    },
    createCity(data) {
        return session.post(`/api/v1/city/`, data);
    },
    updateCity(id, data) {
        return session.patch(`/api/v1/city/${id}/`, data );
    },
};