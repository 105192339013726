<script setup>
import {useStore} from "vuex";
import {computed, defineEmits, defineProps, ref} from "vue";
import CountryCreateUpdateModal from "@/components/modals/location/CountryCreateUpdateModal";
import { successNotify, errorNotify } from "@/mixins";
import TableMenu from "@/components/layouts/TableMenu.vue";

const emit = defineEmits(['update'])
defineProps({countryList: Object})

const store = useStore()
const showModal = ref(false)
const instanceDetail = computed(() => store.getters['country/countryDetail'])
const error = computed(() => store.getters['country/deleteCountryError'])
const getError = computed(() => store.getters['country/getCountryError'])
const deleteInstance = (val) => store.dispatch('country/deleteCountry', val)
const getInstance = (val) => store.dispatch('country/getCountry', val)

function openModal(id) {
  getInstance(id)
      .then(() => {if (getError.value) {errorNotify(error.value)}})
      .finally(() => { showModal.value = true })
}

function closeModal() {
  showModal.value = false
  emit('update')
}

function del(id) {
  if(confirm("Do you really want to delete this country?")) {
    deleteInstance(id)
      .then(() => {
        if (!error.value) { successNotify("Country deleted successfully!")}
        else {errorNotify(error.value)}
      })
      .finally(() => { emit('update'); })
  }
}
</script>

<template>
  <CountryCreateUpdateModal @close="closeModal()" :instance="instanceDetail" v-model="showModal" v-if="showModal"/>
  <div class="py-3 row">
    <div class="col">
      <h4>Country list</h4>
    </div>
  </div>
  <table class="table">
    <thead>
      <th class="first-el">Country</th>
      <th>Phone code</th>
      <th></th>
      </thead>
    <tbody>
      <tr v-for="item in countryList.results" :key="item.id">
        <th class="first-el">{{ item.name }}</th>
        <th>{{ item.phone_code }}</th>
        <th>
          <TableMenu @open-modal="openModal(item.id)" @del="del(item.id)"/>
        </th>
      </tr>
    </tbody>
  </table>
</template>
