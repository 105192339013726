<script setup>
import {useStore} from "vuex";
import {useLoading} from 'vue-loading-overlay'
import {computed, onMounted, ref} from "vue";
import ControlUnitCreateUpdateModal from "@/components/modals/device/ControlUnitCreateUpdateModal.vue";
import ControlUnitsTable from "@/components/tables/device/ControlUnitsTable.vue";


const fullFilter = ref('')
const showModal = ref(false)

const store = useStore()
const controlUnitList  = computed(() => store.getters['control_unit/controlUnitList'])
const getControlUnitList = (val) => store.dispatch('control_unit/getControlUnitList', val)

const $loading = useLoading({});
function getFilteredControlUnitList() {
  const loader = $loading.show({});
  getControlUnitList(fullFilter.value)
    .finally(() => {loader.hide()});
  }
function closeModal() {
  showModal.value = false
  getFilteredControlUnitList()
}

onMounted(() => {
  getFilteredControlUnitList()
})
</script>


<template>
  <ControlUnitCreateUpdateModal @close="closeModal" v-model="showModal" v-if="showModal" />
  <section class="organizations-page">
    <div class="row pb-3">
      <div class="col"><h1>Control units</h1></div>
      <div class="col text-end">
        <button @click="showModal=true" type="button" class="btn btn-primary">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8337 6.83166H6.83366V11.8317H5.16699V6.83166H0.166992V5.16499H5.16699V0.164993H6.83366V5.16499H11.8337V6.83166Z" fill="white"/>
          </svg>
          New Control unit
        </button>
      </div>
    </div>
    <ControlUnitsTable :control-units-list="controlUnitList" @update="getFilteredControlUnitList"/>
  </section>
</template>
