import city from '@/api/city';

import {
    GET_CITY_LIST_BEGIN,
    GET_CITY_LIST_SUCCESS,
    GET_CITY_LIST_FAILURE,
    GET_CITY_BEGIN,
    GET_CITY_SUCCESS,
    GET_CITY_FAILURE,
    UPDATE_CITY_DATA_BEGIN,
    UPDATE_CITY_DATA_SUCCESS,
    UPDATE_CITY_DATA_FAILURE,
    CREATE_CITY_BEGIN,
    CREATE_CITY_SUCCESS,
    CREATE_CITY_FAILURE,
    DELETE_CITY_BEGIN,
    DELETE_CITY_SUCCESS,
    DELETE_CITY_FAILURE,
} from './types';

const initialState = {
    listCityError: false,
    getCityError: false,
    createCityError: false,
    updateCityError: false,
    deleteCityError: false,
    cityDetail: false,
    cityList: false,
};

const getters = {
    cityList:(state) => state.cityList,
    cityDetail:(state) => state.cityDetail
};

const actions = {
    getCity({ commit }, id) {
        commit(GET_CITY_BEGIN);
        return city.getCity(id)
            .then((response) => commit(GET_CITY_SUCCESS, response.data))
            .catch((error) => commit(GET_CITY_FAILURE, error.response.data));
    },
    getCityList({ commit }, filter) {
        commit(GET_CITY_LIST_BEGIN);
        return city.getCityList(filter)
            .then((response) => commit(GET_CITY_LIST_SUCCESS, response.data))
            .catch((error) => commit(GET_CITY_LIST_FAILURE, error.response.data));
    },
    createCity({ commit }, data) {
        commit(CREATE_CITY_BEGIN);
        return city.createCity(data)
            .then(() => commit(CREATE_CITY_SUCCESS))
            .catch((error) => commit(CREATE_CITY_FAILURE, error.response.data));
    },
    updateCity({ commit }, { id, data }) {
        commit(UPDATE_CITY_DATA_BEGIN);
        return city.updateCity(id, data)
            .then(() => commit(UPDATE_CITY_DATA_SUCCESS))
            .catch((error) => commit(UPDATE_CITY_DATA_FAILURE, error.response.data));
    },
    deleteCity({ commit }, id) {
        commit(DELETE_CITY_BEGIN);
        return city.deleteCity(id)
            .then(() => commit(DELETE_CITY_SUCCESS))
            .catch((error) => commit(DELETE_CITY_FAILURE, error.response.data));
    },
};

const mutations = {
    [GET_CITY_LIST_BEGIN](state) {
        state.listCityError = false;
    },
    [GET_CITY_LIST_SUCCESS](state, data) {
        state.cityList = data;
        state.listCityError = false;
    },
    [GET_CITY_LIST_FAILURE](state, error) {
        state.listCityError = error;
    },
    [GET_CITY_BEGIN](state) {
        state.getCityError = false;
    },
    [GET_CITY_SUCCESS](state, data) {
        state.cityDetail = data;
        state.getCityError = false;
    },
    [GET_CITY_FAILURE](state, error) {
        state.getCityError = error;
    },
    [CREATE_CITY_BEGIN](state) {
        state.createCityError = false;
    },
    [CREATE_CITY_SUCCESS](state) {
        state.createCityError = false;
    },
    [CREATE_CITY_FAILURE](state, error) {
        state.createCityError = error;
    },
    [UPDATE_CITY_DATA_BEGIN](state) {
        state.updateCityError = false;
    },
    [UPDATE_CITY_DATA_SUCCESS](state) {
        state.updateCityError = false;
    },
    [UPDATE_CITY_DATA_FAILURE](state, error) {
        state.updateCityError = error;
    },
    [DELETE_CITY_BEGIN](state) {
        state.deleteCityError = false;
    },
    [DELETE_CITY_SUCCESS](state) {
        state.deleteCityError = false;
    },
    [DELETE_CITY_FAILURE](state, error) {
        state.deleteCityError = error;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};