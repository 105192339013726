/* eslint-disable */
import session from './session';

export default {
    getCellList(filter) {
        return session.get(`/api/v1/cell/${filter}`);
    },
    getCell(id) {
        return session.get(`/api/v1/cell/${id}/`);
    },
    createCell(data) {
        return session.post(`/api/v1/cell/`, data);
    },
    updateCell(id, data) {
        return session.patch(`/api/v1/cell/${id}/`, data );
    },
    deleteCell(id) {
        return session.delete(`/api/v1/cell/${id}/`);
    },
};