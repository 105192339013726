<template>
  <section class="parcels-share pt-5">
    <h3>Parcels share</h3>
  </section>
</template>

<script>
export default {
  name: "ParcelsPage"
}
</script>
