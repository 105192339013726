<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted, ref} from "vue"
import { successNotify, errorNotify } from '@/mixins.js'
import MainModal from "@/components/layouts/MainModal"
import Multiselect from 'vue-multiselect'

const store = useStore()
const createInstanceError = computed(() => store.getters['prepaid_account/prepaidAccountCreateError'])
const updateInstanceError = computed(() => store.getters['prepaid_account/prepaidAccountUpdateError'])
const organizationList = computed(() => store.getters['organization/organizationList'])
const createInstance = (val) => store.dispatch('prepaid_account/createPrepaidAccount', val)
const updateInstance = (val) => store.dispatch('prepaid_account/updatePrepaidAccount', val)
const getOrganizationList = (val) => store.dispatch('organization/getOrganizationList', val)

const emit = defineEmits(['update', 'close'])
const props = defineProps({instance: Object})

const organization = ref('')
const instanceData = {
  sn: '',
  secure_code: '',
  balance: '',
  prepaid_hours: '',
  expiration_date: '',
}

function prepareData() {
  instanceData.organization = organization.value.id
  if (instanceData.expiration_date === '') {
      delete instanceData.expiration_date
  }
  if (instanceData.balance === '') {
      delete instanceData.balance
  }
  if (instanceData.prepaid_hours === '') {
      delete instanceData.prepaid_hours
  }
}

function instanceCreate() {
  prepareData()
  createInstance(instanceData)
      .then(() => {
        if (!createInstanceError.value) { successNotify("Prepaid account added successfully!") }
        else { errorNotify(createInstanceError.value) }
      })
      .finally(() => { emit('close') })
}

function instanceUpdate() {
  prepareData()
  let newData = {data: instanceData, id: props.instance.sn }
  updateInstance(newData)
      .then(() => {
        if (!updateInstanceError.value) { successNotify("Prepaid account updated successfully!") }
        else {errorNotify(updateInstanceError.value)}
      })
      .finally(() => { emit('close') })
}

function confirm() {
  if (props.instance) { instanceUpdate() } else { instanceCreate() }
}

onMounted(() => {
  getOrganizationList('')
  if (props.instance) {
    organization.value = props.instance.organization
    for (const [key, value] of Object.entries(props.instance)) {
        instanceData[`${key}`] = value;
      }
  }})
</script>

<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')" >
    <template v-if="instance" v-slot:title>Edit Prepaid account</template>
    <template v-else v-slot:title>Add Prepaid account</template>
    <div class="modal__content">
      <div class="pb-3 form-floating">
        <input :disabled="instance" class="form-control" type="text" placeholder="City name" v-model="instanceData.sn">
        <label>Serial number</label>
      </div>
      <div class="pb-3 form-floating select-wrapper" v-if="organizationList">
        <multiselect
            selectLabel=""
            deselectLabel=""
            placeholder="Change the organization"
            v-model="organization"
            :options="organizationList.results"
            label="name"
        >
        </multiselect>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-floating">
            <input class="form-control" type="text" placeholder="City code" v-model="instanceData.balance">
            <label>Balance</label>
          </div>
        </div>
        <div class="col">
          <div class="form-floating">
            <input class="form-control" type="text" placeholder="City code" v-model="instanceData.prepaid_hours">
            <label>Prepaid hours</label>
          </div>
        </div>
      </div>

        <div class="form-floating" v-if="!instance">
          <input class="form-control" type="text" placeholder="City code" v-model="instanceData.secure_code">
          <label>Secure code</label>
        </div>

      <div class="pb-3 form-floating">
        <p>Expiration date</p>
        <v-date-picker v-model="instanceData.expiration_date" />
      </div>
    </div>
  </main-modal>
</template>
